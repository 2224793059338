import { Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useIonModal } from '@ionic/react';
import { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../../../../../services/data/profile.service';
import { useGetWorkcardTaskInpsectionQuery } from '../../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../../store/store';
import { getDate, getTime } from '../../../../../../../utils';
import { WorkcardApprovalButton } from '../../../../WorkcardApprovalButton/WorkcardApprovalButton';
import { TaskContext } from '../../../WorkcardTasksContext';
import { WorkcardPlanning } from '../../WorkcardPlanning/WorkcardPlanning';

import { InspectionChecklistModal } from './components/WorkcardInspectionChecklistModal/WorkcardInspectionChecklistModal';

export const WorkcardInspectionChecklist: React.FC = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { taskId } = useParams<{ taskId: string }>();
  const [isInspectionPanelOpen, setIsInspectionPanelOpen] = useState<boolean>(true);
  const [isSupervisor, setIsSupervisor] = useState<boolean>(false);
  const [isWorker, setIsWorker] = useState<boolean>(false);
  const [selectedUserType, setUserType] = useState<string>('worker');

  const workerApprovalButton: any = useRef(null);
  const supervisorApprovalButton: any = useRef(null);

  const selectedWorkcard = useSelector((state: RootState) => state.workcard.selectedWorkcard);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const selectedTaskId = useContext(TaskContext) || +taskId;

  const [present, dismiss] = useIonModal(InspectionChecklistModal, {
    workcardId: +id,
    taskId: +selectedTaskId!,
    userType: selectedUserType,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const handleOpenInspectionChecklist = (userType: string) => {
    setUserType(userType);

    present({
      onWillDismiss: (ev: CustomEvent) => {
        // if (ev.detail.role === 'confirm') {
        //   setMessage(`Hello, ${ev.detail.data}!`);
        // }
      },
      cssClass: 'inspection-checklist-modal',
    });
  };

  const getAnwsersCount = (userType: string) => {
    let count = 0;

    data?.riskAssessmentQuestions.map((riskAssessmentQuestion) => {
      if (userType === 'worker') {
        count =
          riskAssessmentQuestion.workerAnswer &&
          (riskAssessmentQuestion.workerAnswer.yesOrNo !== null || riskAssessmentQuestion.workerAnswer.answer)
            ? ++count
            : count;
      } else {
        count =
          riskAssessmentQuestion.supervisorAnswer &&
          (riskAssessmentQuestion.supervisorAnswer.yesOrNo !== null || riskAssessmentQuestion.supervisorAnswer.answer)
            ? ++count
            : count;
      }
    });

    return count;
  };

  const { data: dataProfile } = useGetMyProfileQuery();

  const { data, error, isLoading, refetch } = useGetWorkcardTaskInpsectionQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    taskId: +selectedTaskId!,
    lang: lang,
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.supervisorSignature?.signedBy && data?.workerSignature?.signedBy) {
      setIsInspectionPanelOpen(false);
    } else {
      setIsInspectionPanelOpen(true);
    }
  }, [data]);

  useEffect(() => {
    const isUserWorker: boolean = isUserWorkcardOwner(dataProfile?.crews, selectedWorkcard!);
    setIsWorker(isUserWorker);

    const isSupervisor: boolean = hasEmployeeLevelSupervisorOnAnyCrew(dataProfile);
    setIsSupervisor(isSupervisor);
  }, [dataProfile]);

  return (
    <>
      <Disclosure defaultOpen={isInspectionPanelOpen} key={`panel-${isInspectionPanelOpen}`}>
        {({ open }) => (
          <>
            <Disclosure.Button className="py-0 w-full mb-[10px]">
              <dt className="text-[22px] uppercase  text-black dark:text-workcard-inspection-primary font-semibold flex justify-center">
                <div>
                  {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.INSPECTION')}
                  <ChevronRightIcon
                    className={`${
                      open ? 'rotate-90 transform' : ''
                    } font-bold  inline-block w-[30px] mt-[-4px] ml-auto`}
                  />
                </div>
              </dt>
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel
                static
                className="text-gray-500 border border-[3px] relative border-workcard-inspection-primary rounded-2xl dark:bg-dark-secondary mt-[19px] pt-[20px] "
              >
                <div className="absolute text-center mx-auto w-[53px] h-[53px] left-0 right-0 rounded-full bg-gradient-to-r from-workcard-inspection-primary to-[#c31956] top-[-28px]">
                  <span className="icon-inspection text-[34px] mt-[9px] inline-block text-white"></span>
                </div>
                <dd className="mt-4 pb-4 text-sm text-gray-900 dark:text-quartery-default justify-between">
                  <div className="overflow-hidden  shadow lg:rounded-md">
                    <ul role="list" className="divide-y divide-gray-200 dark:divide-none dark:bg-transparent dark:mx-3">
                      <li
                        className={` bg-gray-100 dark:bg-dark-primary rounded-xl cursor-pointer border-b border-gray-300 dark:border-none hover:bg-gray-200 dark:mb-4`}
                      >
                        <div
                          onClick={() => {
                            handleOpenInspectionChecklist('worker');
                          }}
                          className={`${
                            !isWorker && 'pointer-events-none'
                          } flex justify-between items-center pl-5 pr-2 py-4`}
                        >
                          <div className="flex  items-center">
                            <div className="">
                              <div>
                                <div className="truncate text-sm font-semibold text-blue-900 dark:text-quartery-default">
                                  <div className="icon-worker text-[44px] ml-[2px]"></div>
                                  {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.WORKER')}
                                </div>
                              </div>

                              {!data && (
                                <div className="mt-3.5 ">
                                  <span className="float-right w-[50px] bg-gray-500 text-white text-center rounded">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.NA')}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          {data &&
                            isWorker &&
                            !data.workerSignature &&
                            (data && data.riskAssessmentQuestions ? (
                              <div className="mt-[-25px] relative">
                                <div className="  pl-[30px] pt-[35px] text-[27px] font-extrabold text-workcard-inspection-primary flex flex-col items-center">
                                  <div>
                                    <span className="">{getAnwsersCount('worker')}</span>/
                                    <span className="">
                                      {data && data.riskAssessmentQuestions && data.riskAssessmentQuestions.length}
                                    </span>
                                  </div>
                                  <div className="text-[9px] uppercase font-normal">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.COMPLETED')}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="mt-1.5">
                                {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.NA')}
                              </div>
                            ))}

                          {data && data.workerSignature && (
                            <div>
                              <div className="text-green-600 text-center items-center flex-col pl-[20px] relative top-0.5">
                                <i className="text-[31px] mb-2 fa-regular fa-circle-check"></i>
                                <div className="uppercase text-[12px]">
                                  <span className="block leading-[10px]">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.INSPECTION')}
                                  </span>
                                  <span className="block leading-[16px]">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.COMPLETED')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          {data && !data.workerSignature && isWorker && (
                            <div className="uppercase bg-gradient-to-r from-[#c31956] to-workcard-inspection-primary rounded-xl px-1 py-2.5 border-[3px] border-workcard-inspection-title ml-auto w-[122px] text-center">
                              <span className="text-[12px] font-semibold">
                                {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.INSPECTION')}
                              </span>

                              <i className="fa-solid fa-circle-arrow-right ml-2"></i>
                            </div>
                          )}

                          {data && !isWorker && !data.workerSignature && (
                            <>
                              <div className="text-workcard-inspection-primary text-center items-center flex-col pl-[20px] relative top-0.5">
                                <i className="text-[31px] mb-2 fa-regular fa-clock"></i>
                                <div className="uppercase text-[12px]">
                                  <span className="block leading-[10px]">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.INSPECTION')}
                                  </span>
                                  <span className="block leading-[16px]">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.PENDING')}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {/* </a> */}
                      </li>

                      <li
                        className={`${
                          !isSupervisor && 'cursor-not-allowed'
                        } bg-gray-100 dark:bg-dark-primary rounded-xl cursor-pointer hover:bg-gray-200`}
                      >
                        <div
                          onClick={() => {
                            handleOpenInspectionChecklist('supervisor');
                          }}
                          className={`${
                            !isSupervisor && 'pointer-events-none'
                          } flex items-center justify-between px-2 py-4 `}
                        >
                          <div className="flex items-center">
                            <div className="">
                              <div>
                                <div className="truncate text-sm font-semibold text-blue-900 dark:text-quartery-default">
                                  <div className="icon-supervisor text-[44px] ml-[14px]"></div>
                                  {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.SUPERVISOR')}
                                </div>
                              </div>
                              <div className="">
                                {!data && (
                                  <div className="mt-3.5 ">
                                    <span className="float-right w-[50px] bg-gray-500 text-white text-center rounded">
                                      {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.NA')}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {data &&
                            isSupervisor &&
                            !data.supervisorSignature &&
                            (data && data.riskAssessmentQuestions ? (
                              <div className=" mt-[-25px] relative">
                                <div className=" px-5 pt-[35px] text-[27px] font-extrabold text-workcard-inspection-primary  flex flex-col items-center">
                                  <div>
                                    <span className="">{getAnwsersCount('supervisor')}</span>/
                                    <span className="">
                                      {data && data.riskAssessmentQuestions && data.riskAssessmentQuestions.length}
                                    </span>
                                  </div>
                                  <div className="text-[9px] uppercase font-normal">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.COMPLETED')}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="mt-1.5">{t('LABELS.SELECT')}</div>
                            ))}

                          {data && data.supervisorSignature && (
                            <>
                              <div className="text-green-600 text-center items-center flex-col pl-[20px] relative top-0.5">
                                <i className="text-[31px] mb-2 fa-regular fa-circle-check"></i>
                                <div className="uppercase text-[12px]">
                                  <span className="block leading-[10px]">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.INSPECTION')}
                                  </span>
                                  <span className="block leading-[16px]">
                                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.COMPLETED')}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}

                          {data && isSupervisor && !data.supervisorSignature && (
                            <div className="uppercase bg-gradient-to-r from-[#c31956] to-workcard-inspection-primary rounded-xl px-1 py-2.5 border-[3px] border-workcard-inspection-title ml-auto  w-[122px] text-center">
                              <span className="text-[12px] font-semibold">
                                {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.INSPECTION')}
                              </span>
                              <i className="fa-solid fa-circle-arrow-right ml-2"></i>
                            </div>
                          )}

                          {data && !isWorker && isSupervisor && data.supervisorSignature && (
                            <div className="uppercase bg-black text-workcard-inspection-primary rounded-xl px-1 py-2 border-[3px] border-workcard-inspection-primary ml-auto w-[122px] text-center">
                              <div className="text-[12px] font-semibold">
                                <span className="block leading-[10px]">
                                  {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.VIEW')}
                                </span>
                                <span className="block leading-[16px]">
                                  {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.INSPECTION')}
                                </span>
                              </div>
                            </div>
                          )}

                          {data && isWorker && !isSupervisor && !data.supervisorSignature && (
                            <div className="uppercase bg-black text-workcard-inspection-primary rounded-xl px-1 py-2  ml-auto mr-auto w-[122px] text-center">
                              <i className="text-[31px] mb-2 fa-regular fa-clock"></i>
                              <div className="uppercase text-[12px]">
                                <span className="block leading-[10px]">
                                  {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.INSPECTION')}
                                </span>
                                <span className="block leading-[16px]">
                                  {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.PENDING')}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* </a> */}
                      </li>
                    </ul>
                  </div>
                </dd>
                <WorkcardPlanning type="task" disablePanel={true} />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </>
  );
};
