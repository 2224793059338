import { IonRefresher, IonRefresherContent, useIonViewWillEnter } from '@ionic/react';
import type { RefresherEventDetail } from '@ionic/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LastCrewComments } from '../../components/LastCrewComments/LastCrewComments';
import { LastCrewCommentsLoader } from '../../components/LastCrewComments/LastCrewCommentsLoader';
import { DashboardFilterView } from '../../enums/dashboardFilterView.enum';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Content } from '../../layout/content/content';
import { DashboardHeader } from '../../layout/header/DashboardHeader/DashboardHeader';
import { DashboardFilters as DashboardFilter } from '../../layout/header/DashboardHeader/components/DashboardFilter/DashboardFilter';
import type { StatusTile } from '../../models/statusTile.model';
import { hasEmployeeLevelSupervisorOnAnyCrew } from '../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../services/data/profile.service';
import {
  useGetWorkcardsQuery,
  useGetRedboxQuery,
  useGetControlMeasureListQuery,
  useGetRiskListQuery,
  useGetWorkcardLocationQuery,
} from '../../services/data/workcard.service';
import type { RootState } from '../../store/store';
import { setLastUpdateinfo, toggleDashboardComment } from '../Settings/SettingsSlice';

import { FilterToggleContext } from './DashboardContext';
import { DashboardCardList } from './components/DashboardCardList/DashboardCardList';
import { DashboardCardListLoader } from './components/DashboardCardList/DashboardCardListLoader';
import { DashboardFilters } from './components/DashboardFilters/DashboardFilters';

import './Dashboard.scss';

export const Dashboard: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedFilterToggle, setFilterToggle] = useState<boolean>(false);
  const [selectedStatusTile, setSelectedStatusTile] = useState<StatusTile>();
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const filters = useSelector((state: RootState) => state.dashboardFilters.filters);
  const filterView = useSelector((state: RootState) => state.dashboardFilters.filterView);
  const isCommentCollapsed = useSelector((state: RootState) => state.settings.isDashboardCommentCollapsed);
  const refreshData = useSelector((state: RootState) => state.settings.refreshData);
  const lang = useSelector((state: RootState) => state.settings.lang);
  const isSidebarExpanded = useAppSelector((state) => state.settings.isSidebarExpanded);

  const [lastUpdate, setLastUpdate] = useState('');
  const [skip, setSkip] = React.useState(false);
  const [skipWorkcardAssignToMe, setSkipWorkcardAssignToMe] = React.useState(true);
  const [selectedDateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [selectedDateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
  const [selectedShift, setShift] = useState('');
  const [selectedUser, setUser] = useState('');
  const [selectedAllCrew, setAllCrew] = useState(false);
  const [selectedIsCurrentUserAssignedSupervisor, setIsCurrentUserAssignedSupervisor] = useState(true);

  const {
    data: dataredbox,
    isLoading: isLoadingRedbox,
    isFetching: isFetchingRedbox,
    refetch: refetchRedbox,
  } = useGetRedboxQuery({
    siteId: selectedSiteId!,
    pageSize: 0,
    pageIndex: 0,
  });

  // We cache workcards list for the filter "Assigned to me for offline mode"
  useGetWorkcardsQuery(
    {
      siteId: selectedSiteId!,
      fromDate: selectedDateFrom,
      toDate: selectedDateTo,
      shift: selectedShift,
      userObjectId: selectedUser,
      isAllCrew: true,
      language: lang,
      isCurrentUserAssignedSupervisor: true,
    },
    { skip: skip || skipWorkcardAssignToMe }
  );

  const { data, error, isLoading, isFetching, refetch } = useGetWorkcardsQuery(
    {
      siteId: selectedSiteId!,
      fromDate: selectedDateFrom,
      toDate: selectedDateTo,
      shift: selectedShift,
      userObjectId: selectedUser,
      isAllCrew: selectedAllCrew,
      language: lang,
      isCurrentUserAssignedSupervisor: selectedIsCurrentUserAssignedSupervisor,
    },
    { skip }
  );

  const { data: dataProfile } = useGetMyProfileQuery();

  useGetWorkcardLocationQuery({
    siteId: selectedSiteId!,
  });

  useGetControlMeasureListQuery({ siteId: selectedSiteId!, language: 'fr' });
  useGetControlMeasureListQuery({ siteId: selectedSiteId!, language: 'en' });
  useGetControlMeasureListQuery({ siteId: selectedSiteId!, language: 'fi' });
  useGetRiskListQuery({ siteId: selectedSiteId!, language: 'fr' });
  useGetRiskListQuery({ siteId: selectedSiteId!, language: 'en' });
  useGetRiskListQuery({ siteId: selectedSiteId!, language: 'fi' });

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      refetchRedbox();
      refetch();

      event.detail.complete();
    }, 500);
  };

  useIonViewWillEnter(() => {
    refetchRedbox();
    refetch();
  });

  const toggleComment = () => {
    dispatch(toggleDashboardComment(!isCommentCollapsed));
  };

  useEffect(() => {
    //
  }, [selectedFilterToggle]);

  useEffect(() => {
    refetchRedbox();
    refetch();
  }, []);

  useEffect(() => {
    refetchRedbox();
    refetch();
  }, [refreshData]);

  useEffect(() => {
    if (data && data.length > 0) {
      setLastUpdate(data[0].lastReadingDate!);
      dispatch(setLastUpdateinfo(data[0].lastReadingDate!));
    }
  }, [data]);

  useEffect(() => {
    if (filters && ((filters.fromDate && filters.toDate) || filters.userObjectId)) {
      setSkip(true);
      setDateFrom(filters.fromDate || '');
      setDateTo(filters.toDate || '');
      setShift(filters.shift || '');
      setUser(filters.userObjectId || '');
      setSkip(false);
    }
  }, [filters]);

  useEffect(() => {
    if (selectedIsCurrentUserAssignedSupervisor === true && selectedAllCrew === true) {
      refetch();
    }
  }, [selectedIsCurrentUserAssignedSupervisor, selectedAllCrew]);

  useEffect(() => {
    setSkip(true);

    switch (filterView) {
      case DashboardFilterView.AllCrews:
        setAllCrew(true);
        setIsCurrentUserAssignedSupervisor(false);
        break;
      case DashboardFilterView.myCrews:
        setAllCrew(false);
        setIsCurrentUserAssignedSupervisor(false);
        break;
      case DashboardFilterView.assignToMe:
        setAllCrew(true);
        setIsCurrentUserAssignedSupervisor(true);
        break;
      default:
        break;
    }

    setSkip(false);
  }, [filterView]);

  useEffect(() => {
    const isSupervisor: boolean = hasEmployeeLevelSupervisorOnAnyCrew(dataProfile);

    if (isSupervisor) {
      setSkipWorkcardAssignToMe(false);
    } else {
      setAllCrew(false);
      setIsCurrentUserAssignedSupervisor(false);
      setSkipWorkcardAssignToMe(true);
    }
  }, [dataProfile]);

  return (
    <FilterToggleContext.Provider value={{ selectedFilterToggle, setFilterToggle }}>
      <Content isDashboard={true} header={<DashboardHeader />}>
        <IonRefresher
          className={`ion-refresher-dashboard ${isSidebarExpanded && 'margin-sidebar-open'}`}
          slot="fixed"
          onIonRefresh={handleRefresh}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className="flex justify-center lg:mt-[-25px] ">
          <div className="ml-0 lg:ml-10 w-[700px] mt-1.5">
            <div className="px-4 lg:px-0">
              <DashboardFilter />
            </div>

            {selectedFilterToggle && <DashboardFilters />}
          </div>
        </div>

        <div className="ion-content-scroll-host bg-gradient-to-r from-black to-dark-tertiary  sm:mt-[-31px] sm:pt-[30px] lg:h-[calc(100vh-70px)]">
          {isCommentCollapsed && (
            <div
              onClick={() => {
                toggleComment();
              }}
              className="hidden lg:block fixed z-10 cursor-pointer shadow top-0  right-[30px] mt-[80px] h-[70px] w-[70px] dark:bg-dark-primary bg-gray-300 pt-1.5 rounded-full text-center"
            >
              <div className="icon-RedBox text-[33px] text-center text-workcard-report-primary font-bold shadow-md"></div>
              <div className="leading-[1] text-[10px] block dark:text-white text-center  relative top-[0px] left-[1px]">
                {t('FEATURES.DASHBOARD.WORKER_COMMENT')}
              </div>
            </div>
          )}
          <div className="mx-auto max-w-3xl px-0 sm:px-6 lg:max-w-full lg:px-4 pt-[0px] pb-[50px] lg:pb-0">
            {/* Main 3 column grid */}

            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-5 lg:gap-3 lg:-ml-4">
              {/* Left column */}
              <div className={`grid grid-cols-1 ${isCommentCollapsed ? 'lg:col-span-5' : 'lg:col-span-3'}`}>
                {(isLoading || isFetching) && <DashboardCardListLoader />}
                <div className={`${(isLoading || isFetching) && 'invisible'}`}>
                  <DashboardCardList
                    isFilterEnabled={selectedFilterToggle}
                    statusTileId={selectedStatusTile?.id || 1}
                  />
                </div>
              </div>

              {/* Right column */}
              {
                <div className="grid grid-cols-1 lg:col-span-2 gap-4 px-4 sm:px-0 lg:mt-10 relative">
                  {isLoadingRedbox || isFetchingRedbox ? (
                    <LastCrewCommentsLoader></LastCrewCommentsLoader>
                  ) : (
                    <LastCrewComments isFilterEnabled={selectedFilterToggle} displayCollapseBtn={isCommentCollapsed} />
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </Content>
    </FilterToggleContext.Provider>
  );
};
