import { Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon, CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useIonViewWillEnter } from '@ionic/react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { FocusEvent } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { LastUpdateInfo } from '../../../../components/LastUpdateInfo/LastUpdateInfo';
import { Loader } from '../../../../components/Loader/Loader';
import { OfflineBanner } from '../../../../components/OfflineBanner/OfflineBanner';
import { SaveIndicator } from '../../../../components/SaveIndicator/SaveIndicator';
import { SpeechToText } from '../../../../components/SpeechToText/SpeechToText';
import { WarningMessageModifiedSig } from '../../../../components/WarningMessageModifiedSig/WarningMessageModifiedSig';
import { ExecutionConsistent } from '../../../../enums/executionConsistent.enum';
import { WorkcardSectionType } from '../../../../enums/workcardSectionType.enum';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import type { Execution } from '../../../../models';
import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../services/common/profile.utils';
import { resizeTextarea, scrollToErrorField } from '../../../../services/common/workcard.utils';
import { useGetDecisionQuery } from '../../../../services/data/decision.service';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import {
  useGetWorkcardExecutionQuery,
  useGetWorkcardStatusByIdQuery,
  usePostWorkcardExecutionSignatureMutation,
  useUpdateWorkcardExecutionMutation,
} from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';
import { setLastUpdateinfo } from '../../../Settings/SettingsSlice';
import { MenuContext } from '../../WorkcardContext';
import { setSelectedWorkcard } from '../../WorkcardSlice';
import { WorkcardApprovalButton } from '../WorkcardApprovalButton/WorkcardApprovalButton';
import { WorkcardHeader } from '../WorkcardHeader/WorkcardHeader';

import './WorkcardExecution.scss';

export const WorkcardExecution: React.FC<unknown> = () => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);
  const { t } = useTranslation();

  const { selectedMenu, setselectedMenu } = useContext(MenuContext);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);
  const mountedRef = useRef(true);

  const { id } = useParams<{ id: string }>();

  const handleOpenModal: any = React.useRef(null);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
  } = useForm();

  const workerApprovalButton: any = React.useRef(null);
  const supervisorApprovalButton: any = React.useRef(null);
  const [isSupervisorApproval, setIsSuperviorApproval] = useState(false);
  const [isConsistWithDecisionSaved, setisConsistWithDecisionSaved] = useState(false);
  const [isConsistWithDecisionCommentSaved, setConsistWithDecisionCommentSaved] = useState(false);
  const [isDiscussionOnTaskPerformedSaved, setDiscussionOnTaskPerformedSaved] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [lastUpdate, setLastUpdate] = useState('');
  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);
  const [isWarningModifiedSig, setIsWarningModifiedSig] = useState<boolean>(false);
  const [isUserOwner, setIsUserOwner] = useState(false);

  const [listeningValues, setListeningValues]: any = useState({
    consistWithDecisionComment: 0,
    discussionOnTaskPerformed: 0,
  });

  const dispatch = useAppDispatch();
  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);

  const { data: myProfileData } = useGetMyProfileQuery();

  const { data: dataDecision } = useGetDecisionQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const { refetch: refetchWorkcardStatus } = useGetWorkcardStatusByIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const { data, error, isLoading, refetch } = useGetWorkcardExecutionQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const [updateExecution, response] = useUpdateWorkcardExecutionMutation();

  const [PostSignature, { error: errorSig, data: res }] = usePostWorkcardExecutionSignatureMutation();

  const onSubmit = (data: any) => {
    console.log(data);

    if (!isSupervisorApproval && workerApprovalButton && workerApprovalButton.current) {
      workerApprovalButton.current.openModal();
    }

    if (isSupervisorApproval && supervisorApprovalButton && supervisorApprovalButton.current) {
      supervisorApprovalButton.current.openModal();
    }
  };

  const scrollToFirstError = () => {
    const firstErrorKey = Object.keys(errors).find((key) => errors[key]);

    scrollToErrorField(firstErrorKey);
  };

  const handleResizeTextarea = (field: string) => {
    resizeTextarea(field);

    const textareaVal: any = document.querySelector(`[data-question=${field}]`);
    setValue(field, textareaVal && textareaVal.value);

    setTimeout(() => {
      trigger(field);
    }, 300);
  };

  const handleListeningEnd = (e: any) => {
    let payload: any = {};

    payload = {
      [e.questionId]: e.transcript,
    };

    if (data && data[e.questionId as keyof Execution]) {
      payload[e.questionId] = data[e.questionId as keyof Execution] + ' ' + payload[e.questionId];
    }

    try {
      updateExecution({ siteId: selectedSiteId, workcardId: +id, payload })
        .unwrap()
        .then(() => {
          if (e.questionId === 'consistWithDecisionComment') {
            setConsistWithDecisionCommentSaved(true);
            setTimeout(() => {
              setConsistWithDecisionCommentSaved(false);
            }, 2000);
          } else if (e.questionId === 'discussionOnTaskPerformed') {
            setDiscussionOnTaskPerformedSaved(true);
            setTimeout(() => {
              setDiscussionOnTaskPerformedSaved(false);
            }, 2000);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleListeningStart = (e: any) => {
    const newListeningValues: any = {};
    for (const key in listeningValues) {
      if (key === e) {
        newListeningValues[key] = 0;
      } else {
        newListeningValues[key] = ++listeningValues[key];
      }
    }
    setListeningValues(newListeningValues);
  };

  const handleTranscriptEnd = (e: any) => {
    let value = e.transcript;

    if (data && data[e.questionId as keyof Execution]) {
      value = data[e.questionId as keyof Execution] + ' ' + e.transcript;
    }

    setValue(e.questionId, value, { shouldValidate: false });
  };

  const handleRadioChange = (ev: any, name: string, value: number) => {
    try {
      trigger(name);

      const payload = { [name]: value };

      updateExecution({ siteId: selectedSiteId, workcardId: +id, payload });

      setisConsistWithDecisionSaved(true);

      console.log('name', name);

      if (name === 'isConsistWithDecision' && value !== ExecutionConsistent.no) {
        const payloadDecisionComment = {
          consistWithDecisionComment: '',
        };

        updateExecution({ siteId: selectedSiteId, workcardId: +id, payload: payloadDecisionComment });
      }

      setTimeout(() => {
        setisConsistWithDecisionSaved(false);
      }, 2000);
    } catch (error) {
      //
    }
  };

  const handleInputOnBlur = async (e: any) => {
    const inputVal: string = e.target.value;
    const questionProp: any = e?.target?.getAttribute('data-question');
    const questionVal: any = data?.[questionProp as keyof Execution] || '';
    let payload: any = {};

    if (inputVal.trim() !== questionVal.trim()) {
      payload = {
        [questionProp]: inputVal.trim(),
      };

      try {
        await updateExecution({ siteId: selectedSiteId, workcardId: +id, payload }).unwrap();
        if (questionProp === 'consistWithDecisionComment') {
          setConsistWithDecisionCommentSaved(true);

          setTimeout(() => {
            setConsistWithDecisionCommentSaved(false);
          }, 2000);
        } else if (questionProp === 'discussionOnTaskPerformed') {
          setDiscussionOnTaskPerformedSaved(true);

          setTimeout(() => {
            setDiscussionOnTaskPerformedSaved(false);
          }, 2000);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const handleSubmitButton = (isSupervisor: boolean) => {
    trigger();
    setIsSuperviorApproval(isSupervisor);
    handleSubmit(onSubmit);
    scrollToFirstError();
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data) {
      setLastUpdate(data.lastReadingDate!);
      dispatch(setLastUpdateinfo(data.lastReadingDate!));

      setValue('consistWithDecisionComment', data.consistWithDecisionComment, { shouldValidate: false });
      setValue('discussionOnTaskPerformed', data.discussionOnTaskPerformed, { shouldValidate: false });

      resizeTextarea('consistWithDecisionComment');
      resizeTextarea('discussionOnTaskPerformed');
    }

    if (
      data &&
      data.postSignatureSectionModifiedOn &&
      ((isSupervisor && !data.supervisorSignature) || (isUserOwner && !data.workerSignature))
    ) {
      setIsWarningModifiedSig(true);
    } else {
      setIsWarningModifiedSig(false);
    }

    if (data?.workerSignature && data?.supervisorSignature && hasSigned && selectedWorkcard) {
      setTimeout(() => {
        refetchWorkcardStatus();
      }, 4000);
    }
  }, [data]);

  const handleApproval = () => {
    const payload = {
      employeeLevel: isSupervisorApproval ? 1 : 0,
      signatureCert: '',
    };

    if (isValid) {
      PostSignature({ siteId: selectedSiteId, workcardId: +id, payload });
    }
  };

  useEffect(() => {
    if (res) {
      if (isSupervisorApproval) {
        supervisorApprovalButton.current.approvalConfirmed();
      } else {
        workerApprovalButton.current.approvalConfirmed();
      }

      setHasSigned(true);
    }
  }, [res]);

  useEffect(() => {
    if (isSupervisorApproval) {
      supervisorApprovalButton?.current?.closeModal();
    } else {
      workerApprovalButton?.current?.closeModal();
    }
  }, [errorSig]);

  useEffect(() => {
    if (myProfileData) {
      setIsSupervisor(hasEmployeeLevelSupervisorOnAnyCrew(myProfileData));
      setIsUserOwner(isUserWorkcardOwner(myProfileData?.crews, selectedWorkcard!));
    }
  }, [myProfileData]);

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (dataDecision && dataDecision.postSignatureSectionModifiedOn) {
      setIsWarningModifiedSig(true);
    } else {
      setIsWarningModifiedSig(false);
    }
  }, [dataDecision]);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  useEffect(() => {
    if (errors) {
      scrollToFirstError();
    }
  }, [errors]);

  return (
    <>
      {isLoading && <Loader></Loader>}
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectedMenu === 'Execution' && (
          <div className="grid grid-cols-6 gap-0 pt-[7px] lg:pt-0">
            <div className="ml-0 col-span-6 relative w-full z-10">
              {isSupervisor && <WorkcardHeader></WorkcardHeader>}

              {isWarningModifiedSig && (
                <WarningMessageModifiedSig
                  workcardSectionType={WorkcardSectionType.Execution}
                ></WarningMessageModifiedSig>
              )}

              <div className="border-b border-t border-gray-400 dark:border-none bg-gray-100 dark:bg-dark-tertiary px-4 pt-3 lg:px-6 pl-8 ">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-center lg:flex-nowrap">
                  <div className=" lg:ml-4 mt-0.5 flex flex-col ">
                    <h3 className="text-[17px] font-semibold uppercase leading-6 text-gray-900 dark:text-workcard-execution-primary mt-[7px] text-center">
                      {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.EXECUTION')}
                    </h3>
                    <p className="text-[20px] text-gray-900 dark:text-workcard-decision-primary text-center mt-2"></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-6 relative grid grid-cols-6 border-t-[7px] bg-workcard-execution-tertiary  border-workcard-execution-primary my-4  mt-[16px] md:mt-[18px] pt-[24px] z-20 md:mb-[100px]">
              <div className="absolute text-center mx-auto w-[53px] h-[53px] left-0 right-0 rounded-full bg-gradient-to-r from-workcard-execution-primary to-workcard-execution-secondary top-[-27px]">
                <span className="icon-execution text-[32px] mt-[10px] inline-block"></span>
              </div>

              <div className="hidden lg:col-span-6 ml-0 relative ">
                <div className=" border-b border-t border-gray-400 bg-gray-100 dark:bg-secondary-default px-4 py-3 lg:px-6 pl-8 h-[50px]">
                  <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between lg:flex-nowrap">
                    <div className="ml-4 mt-2"></div>
                    <div className="ml-4 mt-2 flex-shrink-0"></div>
                  </div>
                </div>
              </div>

              <div
                className={`mx-4 lg:ml-4 lg:px-8 lg:pr-2 mb-6 mt-2 rounded-2xl col-span-6 bg-dark-secondary ${isWarningModifiedSig ? 'lg:h-[calc(100vh-345px)]' : 'lg:h-[calc(100vh-305px)]'
                  } grid grid-cols-6`}
              >
                <div className=" col-span-6 lg:col-span-3 ml-0 lg:!ml-4 dark:lg:ml-0 relative   dark:my-4 dark:mx-4 dark:mb-0 ">
                  <div className="mx-[-24px] lg:ml-[-24px] lg:mx-[-5px] lg:px-2 mb-6 mt-0 rounded-md border-[2px] border-workcard-decision-primary bg-dark-secondary">
                    <Disclosure defaultOpen={!selectedIsMobileScreen} key={`panel-${selectedIsMobileScreen}`}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="pt-0 w-full mb-[0px] lg:pb-0">
                            <dt className="text-lg  text-black dark:text-quartery-default font-semibold flex justify-center">
                              <div className="flex justify-between w-full text-[15px] pl-5 pr-2 font-bold py-[1px]">
                                <span className="uppercase text-workcard-decision-primary font-bold text-[15px]">
                                  {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.DECISION_SUMMARY')}
                                </span>

                                <div>
                                  <span className="text-[13px]">{t('FEATURES.WORKCARD.WORKCARD_EXECUTION.VIEW')}</span>
                                  <ChevronRightIcon
                                    className={`${open ? 'rotate-90 transform' : ''
                                      }  inline-block w-[22px] mt-[-2px] ml-auto text-white text-lg font-bold`}
                                  />
                                </div>
                              </div>
                            </dt>
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel static className="text-gray-500">
                              <div className="py-2 px-6">
                                <dt className="leading-[1.1] text-[14px] text-white font-bold">
                                  {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.DISCUSSION_TASK_PERFORM_FIRST_VISIT')}
                                </dt>
                                <dd className="mt-1 pb-0 text-[14px] text-gray-900 lg:col-span-2 lg:mt-0">
                                  <span className="text-[#646464] font-medium comment block w-full lg:text-lg min-h-[40px] mt-2 pl-0">
                                    {dataDecision?.decisionDiscussionOnTaskToPerform || 'N/A'}
                                  </span>
                                </dd>
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>

                <div
                  className={`col-span-6 lg:col-span-3 ml-0 relative dark:my-6 lg:dark:mt-3 border-gray-700 ${isWarningModifiedSig ? 'lg:h-[calc(100vh-365px)]' : 'lg:h-[calc(100vh-320px)]'
                    } overflow-y-auto`}
                >
                  <div
                    className={`${isSupervisor ? 'pb-5' : 'pb-2'
                      } pt-1 px-4 grid grid-cols-6 lg:gap-4 lg:px-6 mb-2 relative`}
                  >
                    {isSupervisor && errors[`isConsistWithDecision`]?.type === 'required' && (
                      <div className="absolute bottom-0 left-4 text-[13px] text-red-600" role="alert">
                        <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                        <span className="font-semibold ">
                          {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                        </span>
                      </div>
                    )}

                    <dt className="leading-[1.1] relative col-span-6 lg:col-span-6 text-[14px]  text-gray-700 dark:text-quartery-default font-bold mb-2">
                      {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.IS_WORK_CONSISTENT')}
                    </dt>
                    <dd className={`col-span-6 mt-1 text-lg text-gray-900  lg:mt-0 relative mb-1`}>
                      <div className="absolute lg:relative  w-[100px] bottom-[5px]">
                        <SaveIndicator isSaved={isConsistWithDecisionSaved}></SaveIndicator>
                      </div>
                      <ul
                        data-question="isConsistWithDecision"
                        className={` ${!isSupervisor && 'invisible absolute'} flex lg:justify-end w-full mb-2`}
                      >
                        <li className=" w-[50%] text-center md:text-right md:pr-3">
                          <input
                            {...register(`isConsistWithDecision`, { required: true })}
                            type="radio"
                            id="yes-understand"
                            name="yes-understand"
                            value="0"
                            className="hidden peer"
                            onChange={(ev) => handleRadioChange(ev, 'isConsistWithDecision', 0)}
                            checked={data?.isConsistWithDecision === ExecutionConsistent.yes}
                          />
                          <label
                            htmlFor="yes-understand"
                            className={`${!isSupervisor && 'pointer-events-none'
                              } cursor-pointer inline-flex justify-between items-center text-white bg-black rounded-2xl border border-gray-500 cursor-pointe  dark:peer-checked:bg-white dark:peer-checked:border-workcard-execution-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-execution-primary  w-[105px] h-[75px] peer-checked:arrow-down-execution relative ml-8`}
                          >
                            <span className="w-full text-[23px] font-extrabold text-center">
                              {t('FEATURES.WORKCARD.YES')}
                            </span>
                          </label>
                        </li>
                        <li className=" w-[50%]  text-center md:text-left md:pl-3">
                          <input
                            {...register(`isConsistWithDecision`, { required: true })}
                            type="radio"
                            id="no-understand"
                            name="no-understand"
                            value="1"
                            className="hidden peer"
                            onChange={(ev) => handleRadioChange(ev, 'isConsistWithDecision', 1)}
                            checked={data?.isConsistWithDecision === ExecutionConsistent.no}
                          />
                          <label
                            htmlFor="no-understand"
                            className={`${!isSupervisor && 'pointer-events-none'
                              } cursor-pointer inline-flex justify-between items-center text-white bg-black rounded-2xl border border-gray-500 cursor-pointe  dark:peer-checked:bg-white dark:peer-checked:border-workcard-execution-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-execution-primary w-[105px] h-[75px] peer-checked:arrow-down-execution relative mr-8`}
                          >
                            <span className="w-full text-[23px] font-extrabold text-center">
                              {t('FEATURES.WORKCARD.NO')}
                            </span>
                          </label>
                        </li>
                      </ul>
                      <div
                        className={` ${!isSupervisor && 'invisible absolute'
                          } block md:w-[235px] text-center mx-11 md:mx-auto pt-[4px]`}
                      >
                        <input
                          {...register(`isConsistWithDecision`, { required: true })}
                          type="radio"
                          id="not-seen-understand"
                          name="not-seen-understand"
                          value="2"
                          className="hidden peer"
                          onChange={(ev) => handleRadioChange(ev, 'isConsistWithDecision', 2)}
                          checked={data?.isConsistWithDecision === ExecutionConsistent.notSeen}
                        />
                        <label
                          htmlFor="not-seen-understand"
                          className={`${!isSupervisor && 'pointer-events-none'
                            } cursor-pointer inline-flex justify-between items-center px-5  text-white bg-black rounded-2xl border border-gray-500 cursor-pointe  dark:peer-checked:bg-white dark:peer-checked:border-workcard-execution-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-execution-primary w-full  h-[60px] peer-checked:arrow-down-execution relative`}
                        >
                          <span className="w-full text-[23px] font-extrabold text-center">N/A</span>
                        </label>
                      </div>
                      {!isSupervisor && (
                        <div
                          className={`${data?.isConsistWithDecision === null ||
                              data?.isConsistWithDecision === undefined ||
                              (data?.isConsistWithDecision === ExecutionConsistent.no &&
                                !data?.consistWithDecisionComment)
                              ? 'text-red-700'
                              : 'text-gray-400'
                            } mt-[-10px] `}
                        >
                          {data && data.isConsistWithDecision !== null && data.isConsistWithDecision !== undefined ? (
                            data?.isConsistWithDecision !== ExecutionConsistent.no ? (
                              data?.isConsistWithDecision === ExecutionConsistent.yes ? (
                                <>
                                  <span className="text-[#646464] text-[14px]">
                                    {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                  </span>{' '}
                                  <span className="text-white text-[16px] font-bold">{t('FEATURES.WORKCARD.YES')}</span>
                                </>
                              ) : (
                                <>
                                  <span className="text-[#646464] text-[14px]">
                                    {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                  </span>{' '}
                                  <span className="text-white text-[16px] font-bold">
                                    {t('FEATURES.WORKCARD.NOT_SEEN')}
                                  </span>
                                </>
                              )
                            ) : (
                              <>
                                <span className="text-[#646464] text-[14px]">
                                  {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                </span>{' '}
                                <span className="text-white text-[16px] font-bold">{t('FEATURES.WORKCARD.NO')}</span>
                                {data?.consistWithDecisionComment ? (
                                  <div className="">
                                    <div className="text-[#646464] text-[14px] font-bold leading-[14px] mb-2 mt-3">
                                      {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.WHY_NOT')}
                                    </div>
                                    <div className="text-[#646464] text-[14px] leading-[14px]">
                                      {data?.consistWithDecisionComment}
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <i
                                      className={`fa-solid fa-triangle-exclamation ${errors[`consistWithDecisionComment`]?.type === 'required'
                                          ? 'text-red-600'
                                          : 'text-[#646464]'
                                        }   pr-1  pb-[6px]`}
                                    ></i>
                                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                                    </span>
                                  </div>
                                )}
                              </>
                            )
                          ) : (
                            <div>
                              <i
                                className={`fa-solid fa-triangle-exclamation ${errors[`isConsistWithDecision`]?.type === 'required'
                                    ? 'text-red-600'
                                    : 'text-[#646464]'
                                  }   pr-1  pb-[6px]`}
                              ></i>
                              <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </dd>
                    {data?.isConsistWithDecision === ExecutionConsistent.no && (
                      <div
                        className={`${!isSupervisor && 'invisble absolute h-0 overflow-hidden'
                          } relative col-span-6 mt-2`}
                      >
                        <div className="flex rounded-md shadow-sm mt-2">
                          <textarea
                            {...register(`consistWithDecisionComment`, { required: true })}
                            placeholder={t('FEATURES.WORKCARD.WORKCARD_EXECUTION.WHY_NOT') + ''}
                            onBlur={handleInputOnBlur}
                            onChange={() => {
                              handleResizeTextarea('consistWithDecisionComment');
                            }}
                            data-question="consistWithDecisionComment"
                            className={`${!isSupervisor && 'bg-gray-300'
                              } focus:outline-none text-black bg-white dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-l-xl border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker text-sm h-[50px]`}
                            disabled={!isSupervisor}
                          ></textarea>
                          <SpeechToText
                            class="mt-[0px] h-[50px] border-l border-black"
                            stopListening={listeningValues.consistWithDecisionComment}
                            key="consistWithDecisionComment"
                            questionId="consistWithDecisionComment"
                            onListeningStart={handleListeningStart}
                            onListeningEnd={handleListeningEnd}
                            onTranscriptEnd={handleTranscriptEnd}
                          ></SpeechToText>
                        </div>
                        {errors[`consistWithDecisionComment`]?.type === 'required' && (
                          <div className="absolute mt-[4px] text-[13px] text-red-600" role="alert">
                            <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                            <span className="font-semibold ">
                              {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                            </span>
                          </div>
                        )}
                        {!isSupervisor && (
                          <div className="relative float-right mt-[4px] text-[13px] text-blue-500" role="alert">
                            Field editable only by supervisor
                          </div>
                        )}
                        <SaveIndicator isSaved={isConsistWithDecisionCommentSaved}></SaveIndicator>
                      </div>
                    )}
                  </div>
                  <div className="py-5 px-4">
                    <dt className="leading-[1.1] text-[14px]  text-gray-700 dark:text-quartery-default font-bold">
                      {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.DISCUSSION_TASK_PERFORM_SECOND_VISIT')}
                    </dt>

                    <dd
                      className={`${!isSupervisor && 'invisble absolute h-0 overflow-hidden'
                        } mt-1 text-lg text-gray-900 lg:col-span-2 lg:mt-0`}
                    >
                      <div className="mt-0 flex rounded-md shadow-sm">
                        <textarea
                          {...register(`discussionOnTaskPerformed`, { required: true })}
                          placeholder=""
                          onBlur={handleInputOnBlur}
                          onChange={() => {
                            handleResizeTextarea('discussionOnTaskPerformed');
                          }}
                          data-question="discussionOnTaskPerformed"
                          className={`${!isSupervisor && 'bg-gray-300'
                            } focus:outline-none no-scrollbar text-black bg-white dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-l-xl border border-gray-400 comment block w-full mt-2 text-sm`}
                          disabled={!isSupervisor}
                        ></textarea>
                        <SpeechToText
                          class="mt-[8px] h-auto border-l border-black"
                          stopListening={listeningValues.discussionOnTaskPerformed}
                          key="discussionOnTaskPerformed"
                          questionId="discussionOnTaskPerformed"
                          onListeningStart={handleListeningStart}
                          onListeningEnd={handleListeningEnd}
                          onTranscriptEnd={handleTranscriptEnd}
                        ></SpeechToText>
                      </div>
                      {isSupervisor && errors[`discussionOnTaskPerformed`]?.type === 'required' && (
                        <div className="absolute mt-[1px] text-[13px] text-red-600" role="alert">
                          <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                          <span className="font-semibold ">
                            {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                          </span>
                        </div>
                      )}
                      <SaveIndicator isSaved={isDiscussionOnTaskPerformedSaved}></SaveIndicator>
                    </dd>

                    {!isSupervisor && (
                      <dd
                        className={`${data?.discussionOnTaskPerformed ? 'text-[#646464]' : 'text-red-700'
                          } mt-1.5 text-[14px]  lg:col-span-2 `}
                      >
                        {data?.discussionOnTaskPerformed || (
                          <div>
                            <i
                              className={`fa-solid fa-triangle-exclamation text-[18px] ${errors[`discussionOnTaskPerformed`]?.type === 'required'
                                  ? 'text-red-600'
                                  : 'text-[#646464]'
                                }   pr-1  pb-[6px]`}
                            ></i>
                            <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                              {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                            </span>
                          </div>
                        )}
                      </dd>
                    )}
                  </div>

                  <div className="grid grid-cols-2 lg:gap-4 mt-10">
                    <div className="mx-4 col-span-2">
                      <div className="m-auto border-t-[1px] border-[#646464] w-full text-center px-4 pt-3.5 mb-4">
                        <h3 className="uppercase font-bold">{t('LABELS.SIGNATURE')}</h3>
                      </div>
                    </div>
                    <div className="relative px-4 py-4 lg:gap-4 lg:px-2 xl:px-6 text-center">
                      {data?.supervisorSignature === null && (
                        <dt className="text-[13px] truncate text-[#646464] font-bold mb-2  text-center">
                          {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.SUPERVISOR_APPROVAL')}
                        </dt>
                      )}
                      <WorkcardApprovalButton
                        colorButton="execution"
                        key="supervisor"
                        userType="Supervisor"
                        approvalStatus={data && data.supervisorSignature}
                        isApprovalButton={true}
                        ref={supervisorApprovalButton}
                        handleSubmit={() => handleSubmitButton(true)}
                        handleApproval={handleApproval}
                      />
                    </div>
                    <div className="relative px-4 py-4 lg:gap-4 lg:px-2 xl:px-6 text-center">
                      {data?.workerSignature === null && (
                        <dt className="text-[13px] truncate text-[#646464] font-bold mb-2  text-center">
                          {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.WORKER_APPROVAL')}
                        </dt>
                      )}
                      <WorkcardApprovalButton
                        colorButton="execution"
                        approvalStatus={data && data.workerSignature}
                        key="worker"
                        userType="Worker"
                        isApprovalButton={true}
                        ref={workerApprovalButton}
                        handleSubmit={() => handleSubmitButton(false)}
                        handleApproval={handleApproval}
                      />
                    </div>

                    {isWarningModifiedSig && (
                      <div className="col-span-2">
                        <WarningMessageModifiedSig
                          workcardSectionType={WorkcardSectionType.Execution}
                        ></WarningMessageModifiedSig>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
