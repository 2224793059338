import { RoleId } from "../enums/role.enum";
import type { Role } from "../models"

export const Roles: Role[] =
    [
        {
            "id": RoleId.Worker,
            "title": "Worker",
            "translationKey": "FEATURES.SETTINGS.ROLES.WORKER"
        },
        {
            "id": RoleId.Supervisor,
            "title": "Supervisor",
            "translationKey": "FEATURES.SETTINGS.ROLES.SUPERVISOR"
        },
        {
            "id": RoleId.ReliefSupervisor,
            "title": "ReplacementSupervisor",
            "translationKey": "FEATURES.SETTINGS.ROLES.REPLACEMENT_SUPERVISOR"
        }
    ];
