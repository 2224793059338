import moment from 'moment';
import React, { useEffect, useState } from 'react';
import type { FocusEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { NoCommentButton } from '../../../../../../components/NoCommentButton/NoCommentButton';
import { SaveIndicator } from '../../../../../../components/SaveIndicator/SaveIndicator';
import { SpeechToText } from '../../../../../../components/SpeechToText/SpeechToText';
import { WarningMessageModifiedSig } from '../../../../../../components/WarningMessageModifiedSig/WarningMessageModifiedSig';
import { WorkcardSectionType } from '../../../../../../enums/workcardSectionType.enum';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import type { Greetings } from '../../../../../../models';
import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../../../services/common/profile.utils';
import { resizeTextarea, scrollToErrorField } from '../../../../../../services/common/workcard.utils';
import { useGetMyProfileQuery } from '../../../../../../services/data/profile.service';
import {
  useGetWorkcardGreetingsQuery,
  useGetWorkcardStatusByIdQuery,
  usePostGreetingSignatureMutation,
  useUpdateGreetingsMutation,
} from '../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../store/store';
import { setSelectedWorkcard } from '../../../../WorkcardSlice';
import { WorkcardApprovalButton } from '../../../WorkcardApprovalButton/WorkcardApprovalButton';

import './WorkcardChecklistOverview.scss';

export const WorkcardChecklistOverview: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const selectedSiteId: any = useSelector((state: RootState) => state.auth.siteId);
  const [isSupervisorApproval, setIsSuperviorApproval] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [isWarningModifiedSig, setIsWarningModifiedSig] = useState<boolean>(false);

  const [amIFitForDutySaved, setAmIFitForDutySaved] = useState(false);
  const [isFitForDutySaved, setIsFitForDutySaved] = useState(false);
  const [isCurrentWorkPlaceSituationSaved, setCurrentWorkPlaceSituationSaved] = useState(false);
  const [isSpecialConditionSaved, setSpecialConditionSaved] = useState(false);
  const [isToolAndMaterialsSaved, setToolAndMaterials] = useState(false);
  const [isSpecialPermitNeededSaved, setSpecialPermitNeededSaved] = useState(false);
  const [isBrowserSupportsSpeechRecognition, setBrowserSupportsSpeechRecognition] = useState(true);
  const [listeningValues, setListeningValues]: any = useState({
    currentWorkPlaceSituation: 0,
    specialCondition: 0,
    toolAndMaterials: 0,
    specialPermitNeeded: 0,
    fitForDuty: 0,
  });

  const dispatch = useAppDispatch();
  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);
  const lang = useAppSelector((state) => state.settings.lang);

  const { data: dataProfile, refetch: refetchProfile } = useGetMyProfileQuery();
  const { data, error, isLoading, refetch } = useGetWorkcardGreetingsQuery({ siteId: selectedSiteId, workcardId: +id });
  const [UpdateGreetings, response] = useUpdateGreetingsMutation();
  const [PostSignature, { error: errorSig, data: res }] = usePostGreetingSignatureMutation();

  const handleOpenModal: any = React.useRef(null);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    getValues,
    clearErrors,
  } = useForm();

  const workerApprovalButton: any = React.useRef(null);
  const supervisorApprovalButton: any = React.useRef(null);

  const onSubmit = (data: any) => {
    if (!isSupervisorApproval && workerApprovalButton && workerApprovalButton.current) {
      workerApprovalButton.current.openModal();
    }

    if (isSupervisorApproval && supervisorApprovalButton && supervisorApprovalButton.current) {
      supervisorApprovalButton.current.openModal();
    }
  };

  const { refetch: refetchWorkcardStatus } = useGetWorkcardStatusByIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const scrollToFirstError = () => {
    const firstErrorKey = Object.keys(errors).find((key) => errors[key]);

    scrollToErrorField(firstErrorKey);
  };

  const handleRadioChange = async (ev: any, name: string, val: boolean) => {
    try {
      trigger(name);

      const payload = { [name]: val };

      await UpdateGreetings({ siteId: selectedSiteId, workcardId: +id, payload }).unwrap();

      if (name === 'amIFitForDuty') {
        setAmIFitForDutySaved(true);
        setTimeout(() => {
          setAmIFitForDutySaved(false);
        }, 2000);

        if (val === false) {
          await UpdateGreetings({ siteId: selectedSiteId, workcardId: +id, payload: { FitForDuty: '' } });
        }
      }
    } catch (error) {
      //
    }
  };

  const handleInputOnBlur = async (e: FocusEvent<any>) => {
    const inputVal: string = e.target.value;
    const questionProp: any = e?.target?.getAttribute('data-question');
    const questionVal: any = data?.[questionProp as keyof Greetings] || '';
    let payload: any = {};

    if (inputVal.trim() !== questionVal.trim()) {
      payload = {
        [questionProp]: inputVal.trim(),
      };

      try {
        await UpdateGreetings({ siteId: selectedSiteId, workcardId: +id, payload }).unwrap();
        if (questionProp === 'currentWorkPlaceSituation') {
          setCurrentWorkPlaceSituationSaved(true);

          setTimeout(() => {
            setCurrentWorkPlaceSituationSaved(false);
          }, 2000);
        } else if (questionProp === 'specialCondition') {
          setSpecialConditionSaved(true);

          setTimeout(() => {
            setSpecialConditionSaved(false);
          }, 2000);
        } else if (questionProp === 'toolAndMaterials') {
          setToolAndMaterials(true);

          setTimeout(() => {
            setToolAndMaterials(false);
          }, 2000);
        } else if (questionProp === 'specialPermitNeeded') {
          setSpecialPermitNeededSaved(true);

          setTimeout(() => {
            setSpecialPermitNeededSaved(false);
          }, 2000);
        } else if (questionProp === 'fitForDuty') {
          setIsFitForDutySaved(true);

          setTimeout(() => {
            setIsFitForDutySaved(false);
          }, 2000);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const handleResizeTextarea = (field: string) => {
    resizeTextarea(field);

    const textareaVal: any = document.querySelector(`[data-question=${field}]`);
    setValue(field, textareaVal && textareaVal.value);

    setTimeout(() => {
      trigger(field);
    }, 300);
  };

  const addNoComment = () => {
    let payload: any = {};

    setValue('currentWorkPlaceSituation', 'No comment', { shouldValidate: false });

    payload = {
      currentWorkPlaceSituation: 'No comment',
    };

    try {
      UpdateGreetings({ siteId: selectedSiteId, workcardId: +id, payload })
        .unwrap()
        .then(() => {
          setCurrentWorkPlaceSituationSaved(true);

          trigger('currentWorkPlaceSituation');

          setTimeout(() => {
            setCurrentWorkPlaceSituationSaved(false);
          }, 2000);
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleListeningStart = (e: any) => {
    const newListeningValues: any = {};
    for (const key in listeningValues) {
      if (key === e) {
        newListeningValues[key] = 0;
      } else {
        newListeningValues[key] = ++listeningValues[key];
      }
    }
    setListeningValues(newListeningValues);
  };

  const handleListeningEnd = (e: any) => {
    let payload: any = {};

    payload = {
      [e.questionId]: e.transcript,
    };

    if (data && data[e.questionId as keyof Greetings]) {
      payload[e.questionId] = data[e.questionId as keyof Greetings] + ' ' + payload[e.questionId];
    }

    try {
      UpdateGreetings({ siteId: selectedSiteId, workcardId: +id, payload })
        .unwrap()
        .then(() => {
          if (e.questionId === 'currentWorkPlaceSituation') {
            setCurrentWorkPlaceSituationSaved(true);
            setTimeout(() => {
              setCurrentWorkPlaceSituationSaved(false);
            }, 2000);
          } else if (e.questionId === 'specialCondition') {
            setSpecialConditionSaved(true);
            setTimeout(() => {
              setSpecialConditionSaved(false);
            }, 2000);
          } else if (e.questionId === 'toolAndMaterials') {
            setToolAndMaterials(true);
            setTimeout(() => {
              setToolAndMaterials(false);
            }, 2000);
          } else if (e.questionId === 'specialPermitNeeded') {
            setSpecialPermitNeededSaved(true);
            setTimeout(() => {
              setSpecialPermitNeededSaved(false);
            }, 2000);
          } else if (e.questionId === 'fitForDuty') {
            setIsFitForDutySaved(true);

            setTimeout(() => {
              setIsFitForDutySaved(false);
            }, 2000);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleTranscriptEnd = (e: any) => {
    let value = e.transcript;

    if (data && data[e.questionId as keyof Greetings]) {
      value = data[e.questionId as keyof Greetings] + ' ' + e.transcript;
    }

    setValue(e.questionId, value, { shouldValidate: false });
  };

  const handleApproval = () => {
    const payload = {
      employeeLevel: isSupervisorApproval ? 1 : 0,
      signatureCert: '',
    };

    if (isValid) {
      PostSignature({ siteId: selectedSiteId, workcardId: +id, payload });
    }
  };

  const handleSubmitButton = (isSupervisor: boolean) => {
    trigger();
    setIsSuperviorApproval(isSupervisor);

    handleSubmit(onSubmit);
    scrollToFirstError();
  };

  useEffect(() => {
    refetchProfile();
    // setIsUserOwner(isUserWorkcardOwner(dataProfile?.crews, selectedWorkcard!));
  }, []);

  useEffect(() => {
    refetch();
  }, [error]);

  useEffect(() => {
    if (res) {
      if (isSupervisorApproval) {
        supervisorApprovalButton.current.approvalConfirmed();
      } else {
        workerApprovalButton.current.approvalConfirmed();
      }

      setHasSigned(true);
    }
  }, [res]);

  useEffect(() => {
    if (isSupervisorApproval) {
      supervisorApprovalButton.current.closeModal();
    } else {
      workerApprovalButton.current.closeModal();
    }
  }, [errorSig]);

  useEffect(() => {
    setIsUserOwner(isUserWorkcardOwner(dataProfile?.crews, selectedWorkcard!));
    setIsSupervisor(hasEmployeeLevelSupervisorOnAnyCrew(dataProfile));
  }, [dataProfile]);

  useEffect(() => {
    if (data) {
      setValue('currentWorkPlaceSituation', data.currentWorkPlaceSituation, { shouldValidate: false });
      setValue('specialCondition', data.specialCondition, { shouldValidate: false });
      setValue('toolAndMaterials', data.toolAndMaterials, { shouldValidate: false });
      setValue('specialPermitNeeded', data.specialPermitNeeded, { shouldValidate: false });
      setValue('fitForDuty', data.fitForDuty, { shouldValidate: false });
    } else {
      setValue('currentWorkPlaceSituation', '', { shouldValidate: false });
      setValue('specialCondition', '', { shouldValidate: false });
      setValue('toolAndMaterials', '', { shouldValidate: false });
      setValue('specialPermitNeeded', '', { shouldValidate: false });
      setValue('fitForDuty', '', { shouldValidate: false });
    }

    resizeTextarea('currentWorkPlaceSituation');
    resizeTextarea('specialCondition');
    resizeTextarea('toolAndMaterials');
    resizeTextarea('specialPermitNeeded');
    resizeTextarea('fitForDuty');

    if (
      data &&
      data.postSignatureSectionModifiedOn &&
      ((isSupervisor && !data.supervisorSignature) || (isUserOwner && !data.workerSignature))
    ) {
      setIsWarningModifiedSig(true);
    } else {
      setIsWarningModifiedSig(false);
    }

    if (data?.workerSignature && data?.supervisorSignature && hasSigned && selectedWorkcard) {
      setTimeout(() => {
        refetchWorkcardStatus();
      }, 4000);
    }
  }, [data]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isBrowserSupportsSpeechRecognition && <span>Speech Recognition is not supported with this browser</span>}

        <div className="col-span-5 relative my-6 mx-3 mt-2 ">
          <div className=" px-0 py-4 lg:gap-4 lg:px-6">
            <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative">
              {t('FEATURES.WORKCARD.WORKCARD_GREETING.CURRENT_WORKPLACE_SITUATION')}
            </dt>
            <dd
              className={`${
                !isSupervisor && 'invisble absolute h-0 overflow-hidden'
              } relative mt-1 text-sm text-gray-900  lg:mt-0`}
            >
              <div className="mt-2 flex rounded-md shadow-sm">
                <div className={` relative flex flex-grow items-stretch focus-within:z-10`}>
                  <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-1"></div>
                  <textarea
                    {...register(`currentWorkPlaceSituation`, { required: true })}
                    placeholder=""
                    onBlur={handleInputOnBlur}
                    data-question="currentWorkPlaceSituation"
                    className={`focus:outline-none text-black bg-white border border-gray-400 comment block w-full dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-xl lg:text-sm h-[50px]`}
                    onChange={() => {
                      handleResizeTextarea('currentWorkPlaceSituation');
                    }}
                  ></textarea>
                  <NoCommentButton
                    val={getValues('currentWorkPlaceSituation')}
                    onNoComment={addNoComment}
                  ></NoCommentButton>
                </div>
                <SpeechToText
                  stopListening={listeningValues.currentWorkPlaceSituation}
                  key="currentWorkPlaceSituation"
                  questionId="currentWorkPlaceSituation"
                  onListeningStart={handleListeningStart}
                  onListeningEnd={handleListeningEnd}
                  onTranscriptEnd={handleTranscriptEnd}
                ></SpeechToText>
              </div>

              {errors[`currentWorkPlaceSituation`]?.type === 'required' && (
                <div className="absolute mt-[4px] text-[13px] text-red-600" role="alert">
                  <div>
                    <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                    <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                  </div>
                </div>
              )}
              <SaveIndicator isSaved={isCurrentWorkPlaceSituationSaved}></SaveIndicator>
            </dd>
            {!isSupervisor && (
              <dd
                className={`${
                  data?.currentWorkPlaceSituation ? 'text-[#646464]' : 'text-red-700'
                } mt-1.5 text-[14px]  lg:col-span-2 `}
              >
                {data?.currentWorkPlaceSituation || (
                  <div>
                    <i
                      className={`fa-solid fa-triangle-exclamation text-[18px] ${
                        errors[`currentWorkPlaceSituation`]?.type === 'required' ? 'text-red-600' : 'text-[#646464]'
                      }   pr-1  pb-[6px]`}
                    ></i>
                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                    </span>
                  </div>
                )}
              </dd>
            )}
          </div>
          <div className="px-0 py-4  lg:gap-4 lg:px-6">
            <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold">
              {t('FEATURES.WORKCARD.WORKCARD_GREETING.SPECIAL_CONDITION')}
            </dt>
            <dd
              className={`${
                !isSupervisor && 'invisble absolute h-0 overflow-hidden'
              } relative mt-0 text-sm text-gray-900 lg:col-span-2 lg:mt-0`}
            >
              <div className="mt-0 flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-1"></div>
                  <textarea
                    {...register(`specialCondition`, { required: true })}
                    placeholder=""
                    onBlur={handleInputOnBlur}
                    onChange={() => {
                      handleResizeTextarea('specialCondition');
                    }}
                    data-question="specialCondition"
                    className={`focus:outline-none text-black bg-white  border border-gray-400 comment block w-full dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-l-xl lg:text-sm h-[50px] mt-2`}
                  ></textarea>
                  <SpeechToText
                    class="mt-[8px] border-l border-black"
                    stopListening={listeningValues.specialCondition}
                    key="specialCondition"
                    questionId="specialCondition"
                    onListeningStart={handleListeningStart}
                    onListeningEnd={handleListeningEnd}
                    onTranscriptEnd={handleTranscriptEnd}
                  ></SpeechToText>
                </div>
                <div></div>
              </div>

              {errors[`specialCondition`]?.type === 'required' && (
                <div className="absolute mt-[4px] text-[13px] text-red-600 " role="alert">
                  <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                  <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                </div>
              )}
              <SaveIndicator isSaved={isSpecialConditionSaved}></SaveIndicator>
            </dd>
            {!isSupervisor && (
              <dd
                className={`${
                  data?.specialCondition ? 'text-[#646464]' : 'text-red-700'
                } mt-3 text-[14px]  lg:col-span-2 `}
              >
                {data?.specialCondition || (
                  <div>
                    <i
                      className={`fa-solid fa-triangle-exclamation text-[18px] ${
                        errors[`specialCondition`]?.type === 'required' ? 'text-red-600' : 'text-[#646464]'
                      }   pr-1  pb-[6px]`}
                    ></i>
                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                    </span>
                  </div>
                )}
              </dd>
            )}
          </div>
          <div className="px-0 py-4  lg:gap-4 lg:px-6">
            <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold">
              {t('FEATURES.WORKCARD.WORKCARD_GREETING.TOOL_MATERIAL_REQUIRED')}
            </dt>
            <dd
              className={`${
                !isSupervisor && 'invisble absolute h-0 overflow-hidden'
              } relative mt-0 text-sm text-gray-900 lg:col-span-2 lg:mt-0`}
            >
              <div className="mt-0 flex rounded-md shadow-sm">
                <textarea
                  {...register(`toolAndMaterials`, { required: true })}
                  placeholder=""
                  onBlur={handleInputOnBlur}
                  onChange={() => {
                    handleResizeTextarea('toolAndMaterials');
                  }}
                  data-question="toolAndMaterials"
                  className={`focus:outline-none text-black bg-white border border-gray-400 comment block w-full dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-l-xl lg:text-sm h-[50px] mt-2`}
                ></textarea>
                <SpeechToText
                  class="mt-[8px] h-[50px] border-l border-black"
                  stopListening={listeningValues.toolAndMaterials}
                  key="toolAndMaterials"
                  questionId="toolAndMaterials"
                  onListeningStart={handleListeningStart}
                  onListeningEnd={handleListeningEnd}
                  onTranscriptEnd={handleTranscriptEnd}
                ></SpeechToText>
              </div>
              {errors[`toolAndMaterials`]?.type === 'required' && (
                <div className="absolute mt-[4px] text-[13px] text-red-600" role="alert">
                  <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                  <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                </div>
              )}
              <SaveIndicator isSaved={isToolAndMaterialsSaved}></SaveIndicator>
            </dd>
            {!isSupervisor && (
              <dd
                className={`${
                  data?.toolAndMaterials ? 'text-[#646464]' : 'text-red-700'
                } mt-3 text-[14px]  lg:col-span-2 `}
              >
                {data?.toolAndMaterials || (
                  <div>
                    <i
                      className={`fa-solid fa-triangle-exclamation text-[18px] ${
                        errors[`toolAndMaterials`]?.type === 'required' ? 'text-red-600' : 'text-[#646464]'
                      }   pr-1  pb-[6px]`}
                    ></i>
                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                    </span>
                  </div>
                )}
              </dd>
            )}
          </div>
          <div className="px-0 py-4  lg:gap-4 lg:px-6">
            <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold">
              {t('FEATURES.WORKCARD.WORKCARD_GREETING.NEED_SPECIAL_PERMIT')}
            </dt>
            <dd
              className={`${
                !isSupervisor && 'invisble absolute h-0 overflow-hidden'
              } relative mt-0 text-sm text-gray-900 lg:col-span-2 lg:mt-0`}
            >
              <div className="mt-0 flex rounded-md shadow-sm">
                <textarea
                  {...register(`specialPermitNeeded`, { required: true })}
                  placeholder=""
                  onBlur={handleInputOnBlur}
                  onChange={() => {
                    handleResizeTextarea('specialPermitNeeded');
                  }}
                  data-question="specialPermitNeeded"
                  className={`focus:outline-none text-black bg-white border border-gray-400 comment block w-full dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-l-xl lg:text-sm h-[50px] mt-2`}
                ></textarea>
                <SpeechToText
                  class="mt-[8px] h-[50px] border-l border-black"
                  stopListening={listeningValues.specialPermitNeeded}
                  key="specialPermitNeeded"
                  questionId="specialPermitNeeded"
                  onListeningStart={handleListeningStart}
                  onListeningEnd={handleListeningEnd}
                  onTranscriptEnd={handleTranscriptEnd}
                ></SpeechToText>
              </div>
              {errors[`specialPermitNeeded`]?.type === 'required' && (
                <div className="absolute mt-[4px] text-[13px] text-red-600" role="alert">
                  <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                  <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                </div>
              )}
              <SaveIndicator isSaved={isSpecialPermitNeededSaved}></SaveIndicator>
            </dd>
            {!isSupervisor && (
              <dd
                className={`${
                  data?.specialPermitNeeded ? 'text-[#646464]' : 'text-red-700'
                } mt-3 text-[14px]  lg:col-span-2 `}
              >
                {data?.specialPermitNeeded || (
                  <div>
                    <i
                      className={`fa-solid fa-triangle-exclamation text-[18px] ${
                        errors[`specialPermitNeeded`]?.type === 'required' ? 'text-red-600' : 'text-[#646464]'
                      }   pr-1  pb-[6px]`}
                    ></i>
                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                    </span>
                  </div>
                )}
              </dd>
            )}
          </div>
          <div className="relative px-0 py-3 grid grid-cols-6 lg:gap-4 lg:px-6 mb-2">
            <div className="absolute w-[100px]  left-0 bottom-4">
              <SaveIndicator isSaved={amIFitForDutySaved}></SaveIndicator>
            </div>
            <dt className="relative col-span-6 lg:col-span-4 text-sm  text-gray-700 dark:text-gray-400 font-medium">
              <span className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold">
                {t('FEATURES.WORKCARD.WORKCARD_GREETING.AM_I_FIT_FOR_DUTY')}
              </span>

              {isUserOwner && errors[`amIFitForDuty`]?.type === 'required' && (
                <div className="text-[13px] text-red-600" role="alert">
                  <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                  <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                </div>
              )}
            </dt>
            <dd className={` relative col-span-6 mt-1 text-sm text-gray-900 `}>
              <ul
                data-question="amIFitForDuty"
                className={`${!isUserOwner && 'invisible absolute'} mt-2  flex  gap-2 w-full mb-2 lg:mt-[-15px]`}
              >
                <li className="text-center w-[50%] md:text-right md:pr-3">
                  <input
                    {...register(`amIFitForDuty`, { required: true })}
                    type="radio"
                    id="yes_1"
                    name="yes_1"
                    value="yes"
                    className="hidden peer"
                    onChange={(ev) => handleRadioChange(ev, 'amIFitForDuty', true)}
                    checked={data?.amIFitForDuty}
                  />
                  <label
                    htmlFor="yes_1"
                    className={`${
                      !isUserOwner && 'pointer-events-none'
                    } cursor-pointer inline-flex justify-between md:justify-start items-center px-5  text-white bg-black rounded-2xl border border-gray-500 cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-greeting-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-greeting-primary w-[105px] h-[75px] ml-8 md:ml-0 peer-checked:arrow-down-greeting relative`}
                  >
                    <span className="w-full text-[23px] font-bold text-center mt-2">{t('FEATURES.WORKCARD.YES')}</span>
                  </label>
                </li>
                <li className="text-center w-[50%] md:text-left md:pl-3">
                  <input
                    {...register(`amIFitForDuty`, { required: true })}
                    type="radio"
                    id="no_1"
                    name="no_1"
                    value="no"
                    className="hidden peer"
                    onChange={(ev) => handleRadioChange(ev, 'amIFitForDuty', false)}
                    checked={data?.amIFitForDuty === false}
                  />
                  <label
                    htmlFor="no_1"
                    className={`${
                      !isUserOwner && 'pointer-events-none'
                    } cursor-pointer inline-flex justify-between items-center px-5  text-white bg-black rounded-2xl border border-gray-500 cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-greeting-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-greeting-primary w-[105px] h-[75px] mr-8 peer-checked:arrow-down-greeting relative`}
                  >
                    <span className="w-full text-[23px] font-bold text-center mt-2">{t('FEATURES.WORKCARD.NO')}</span>
                  </label>
                </li>
              </ul>
              {!isUserOwner && (
                <div
                  className={`${
                    data?.amIFitForDuty === null ||
                    data?.amIFitForDuty === undefined ||
                    (data?.amIFitForDuty === false && !data?.fitForDuty)
                      ? 'text-red-600'
                      : 'text-gray-400'
                  } `}
                >
                  {data &&
                    data.amIFitForDuty !== null &&
                    data.amIFitForDuty !== undefined &&
                    selectedWorkcard?.employees &&
                    selectedWorkcard.employees.map((user: any, index: number) => (
                      <span className="truncate text-[#646464]" key={`employee-${index}`}>
                        {selectedWorkcard.employees.length > 1 ? user.name.replace(/ .*/, '') : user.name}
                        {selectedWorkcard.employees.length > 1 && index === 0 && ', '}
                      </span>
                    ))}

                  {data && data.amIFitForDuty !== null && data.amIFitForDuty !== undefined ? (
                    data?.amIFitForDuty === true ? (
                      <span className="ml-1 text-[#646464]">
                        {t('LABELS.SAID')}:{' '}
                        <span className="text-white font-bold text-[16px]">{t('FEATURES.WORKCARD.YES')}</span>{' '}
                      </span>
                    ) : (
                      <>
                        <span className="ml-1 text-[#646464]">
                          {t('LABELS.SAID')}:{' '}
                          <span className="text-white font-bold text-[16px]">{t('FEATURES.WORKCARD.NO')}</span>{' '}
                        </span>
                        {data?.fitForDuty ? (
                          <div className="text-[#646464] text-[14px] leading-[14px]">{data?.fitForDuty}</div>
                        ) : (
                          <div>
                            <i
                              className={`fa-solid fa-triangle-exclamation ${
                                errors[`fitForDuty`]?.type === 'required' ? 'text-red-600' : 'text-[#646464] '
                              }   pr-1  pb-[6px]`}
                            ></i>
                            <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                              {t('FEATURES.WORKCARD.WAITING_FOR_WORKER_ANSWER')}
                            </span>
                          </div>
                        )}
                      </>
                    )
                  ) : (
                    <div>
                      <i
                        className={`fa-solid fa-triangle-exclamation ${
                          errors[`amIFitForDuty`]?.type === 'required' ? 'text-red-600' : 'text-gray-500'
                        } pr-1  pb-[6px]`}
                      ></i>
                      <span className=" text-[13px] text-gray-500 py-1  pb-[4.5px]">
                        {t('FEATURES.WORKCARD.WAITING_FOR_WORKER_ANSWER')}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </dd>
            {data?.amIFitForDuty === false && (
              <dd
                className={`${
                  !isUserOwner && 'invisible absolute h-0 overflow-hidden'
                } relative col-span-6 text-sm text-gray-900 lg:mt-[-18px] mb-3 mt-0 `}
              >
                <div>
                  <div className="mt-0 flex rounded-md shadow-sm">
                    <textarea
                      {...register(`fitForDuty`, { required: true })}
                      placeholder=""
                      onBlur={handleInputOnBlur}
                      onChange={() => {
                        handleResizeTextarea('fitForDuty');
                      }}
                      data-question="fitForDuty"
                      className={`focus:outline-none text-black bg-white border border-gray-400 dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-l-xl comment block w-full h-[50px] mt-2`}
                      disabled={!isUserOwner}
                    ></textarea>
                    <SpeechToText
                      stopListening={listeningValues.fitForDuty}
                      class="mt-[8px] h-[50px] border-l border-black"
                      key="fitForDuty"
                      questionId="fitForDuty"
                      onListeningStart={handleListeningStart}
                      onListeningEnd={handleListeningEnd}
                      onTranscriptEnd={handleTranscriptEnd}
                    ></SpeechToText>
                  </div>
                  {isUserOwner && errors[`fitForDuty`]?.type === 'required' && (
                    <div className="absolute mt-[4px] text-[13px] text-red-600" role="alert">
                      <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                      <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                    </div>
                  )}
                  <SaveIndicator isSaved={isFitForDutySaved}></SaveIndicator>
                </div>
              </dd>
            )}
          </div>
          <div className="grid grid-cols-2 lg:gap-4 mt-10">
            <div className="col-span-2 m-auto border-t-[1px] border-[#646464] w-full text-center pt-3.5 mb-4">
              <h3 className="uppercase font-bold">{t('LABELS.SIGNATURE')}</h3>
            </div>
            <div className="relative px-0 py-4 lg:gap-4 lg:px-6 text-center ">
              {data && data.supervisorSignature === null && (
                <dt className="text-[13px] truncate  text-[#646464] font-bold mb-2">
                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.SUPERVISOR_APPROVAL')}
                </dt>
              )}
              <WorkcardApprovalButton
                isApprovalButton={true}
                approvalStatus={data && data.supervisorSignature}
                key="supervisor"
                handleSubmit={() => handleSubmitButton(true)}
                handleApproval={handleApproval}
                userType="Supervisor"
                ref={supervisorApprovalButton}
                colorButton="greeting"
              />
            </div>
            <div className="relative px-4 py-4 lg:gap-4 lg:px-6 text-center">
              {data && data.workerSignature === null && (
                <dt className="text-[13px] truncate  text-[#646464] font-bold mb-2">
                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.WORKER_APPROVAL')}
                </dt>
              )}
              <WorkcardApprovalButton
                isApprovalButton={true}
                approvalStatus={data && data.workerSignature}
                key="worker"
                userType="Worker"
                handleSubmit={() => handleSubmitButton(false)}
                handleApproval={handleApproval}
                ref={workerApprovalButton}
                colorButton="greeting"
              />
            </div>

            {isWarningModifiedSig && (
              <div className="col-span-2">
                <WarningMessageModifiedSig
                  workcardSectionType={WorkcardSectionType.Greeting}
                ></WarningMessageModifiedSig>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};
