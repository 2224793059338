import { Menu, Transition } from '@headlessui/react';
import { useIonRouter } from '@ionic/react';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { selectMenuItem } from '../../layout/Sidebar/SidebarSlice';
import { hasEmployeeLevelSupervisorOnAnyCrew } from '../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../services/data/profile.service';

import { SignOutButton } from './component/SignoutButton/SignoutButton';

export const UserProfileButton: React.FC<unknown> = () => {
  const router = useIonRouter();
  const { t, i18n } = useTranslation();
  const lang = useAppSelector((state) => state.settings.lang);

  const dispatch = useAppDispatch();

  const handleGotoSettings = () => {
    dispatch(selectMenuItem('settings'));
    router.push(`/settings`, 'forward');
  };

  const userNavigation = [
    { name: t('COMPONENTS.USER_PROFILE_BUTTON.SETTINGS'), id: 1 },
    { name: t('COMPONENTS.USER_PROFILE_BUTTON.SIGN_OUT'), id: 2 },
  ];

  const { data, error, isLoading } = useGetMyProfileQuery();

  useEffect(() => {
    if (data) {
      i18n.changeLanguage(lang);
    }
  }, [data]);

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="flex max-w-md items-start text-sm">
          <div className="mt-2 flex xl:mt-0 xl:ml-4 text-left">
            <div>
              <div>
                <span className="hidden sm:inline mt-1.5 ml-2 text-white text-[24px] font-bold">
                  {data && data.name}
                </span>
              </div>
              <div>
                <span className="flex-shrink-0 inline-block items-start text-left  px-2.5  text-sm font-medium text-gray-300">
                  {hasEmployeeLevelSupervisorOnAnyCrew(data)
                    ? t('COMPONENTS.USER_PROFILE_BUTTON.SUPERVISOR')
                    : t('COMPONENTS.USER_PROFILE_BUTTON.WORKER')}
                </span>
              </div>
            </div>
          </div>
        </Menu.Button>
      </div>
    </Menu>
  );
};
