import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { IonContent, IonPage, useIonModal } from '@ionic/react';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import type { FocusEvent } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { LastUpdateInfo } from '../../../../components/LastUpdateInfo/LastUpdateInfo';
import { Loader } from '../../../../components/Loader/Loader';
import { OfflineBanner } from '../../../../components/OfflineBanner/OfflineBanner';
import { SaveIndicator } from '../../../../components/SaveIndicator/SaveIndicator';
import { SpeechToText } from '../../../../components/SpeechToText/SpeechToText';
import { WarningMessageModifiedSig } from '../../../../components/WarningMessageModifiedSig/WarningMessageModifiedSig';
import { PlanAndQuoteWorkplaceAnswer } from '../../../../enums/planAndQuoteWorkplaceAnswer.enum';
import { WorkcardSectionType } from '../../../../enums/workcardSectionType.enum';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import type { Decision } from '../../../../models';
import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../services/common/profile.utils';
import { classNames, resizeTextarea, scrollToErrorField } from '../../../../services/common/workcard.utils';
import {
  useGetDecisionQuery,
  usePostDecisionSignatureMutation,
  useUpdateDecisionMutation,
} from '../../../../services/data/decision.service';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import {
  useGetTasksByWorkcardIdQuery,
  useGetWorkcardStatusByIdQuery,
} from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';
import { setLastUpdateinfo } from '../../../Settings/SettingsSlice';
import { MenuContext } from '../../WorkcardContext';
import { setSelectedWorkcard } from '../../WorkcardSlice';
import { WorkcardApprovalButton } from '../WorkcardApprovalButton/WorkcardApprovalButton';
import { WorkcardApprovalModal } from '../WorkcardApprovalModal/WorkcardApprovalModal';
import { WorkcardHeader } from '../WorkcardHeader/WorkcardHeader';
import { WorkcardPlanning } from '../WorkcardTasks/components/WorkcardPlanning/WorkcardPlanning';

import './WorkcardDecision.scss';

export const WorkcardDecision: React.FC<unknown> = () => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { selectedMenu, setselectedMenu } = useContext(MenuContext);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);
  const [isWarningModifiedSig, setIsWarningModifiedSig] = useState<boolean>(false);
  const [isFirstVisitDiscussionSaved, setFirstVisitDiscussionSaved] = useState(false);
  const [isCheckUnderstandSaved, setCheckUnderstandSaved] = useState(false);
  const [isCanWeWorkSafelySaved, setCanWeWorkSafelySaved] = useState(false);
  const [isPlanAndQuoteInWorkplaceSaved, setIsPlanAndQuoteInWorkplaceSaved] = useState(false);
  const [isWorkerObservedDuringTaskSaved, setIsWorkerObservedDuringTaskSaved] = useState(false);
  const [isAuthorizeContinuationActivitiesSaved, setAuthorizeContinuationActivitiesSaved] = useState(false);
  const [isTaskPerformedSecurilySaved, setIsTaskPerformedSecurilySaved] = useState(false);
  const [feedBackOnTaskNotPerformedSecurelySaved, setFeedBackOnTaskNotPerformedSecurelySaved] = useState(false);

  const [isSupervisor, setIsSupervisor] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [lastUpdate, setLastUpdate] = useState('');
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [isUserOwner, setIsUserOwner] = useState(false);

  const mountedRef = useRef(true);

  const [listeningValues, setListeningValues]: any = useState({
    feedBackOnTaskNotPerformedSecurely: 0,
    decisionDiscussionOnTaskToPerform: 0,
    specialConditions: 0,
    actionsTaken: 0,
    personContacted: 0,
  });

  const dispatch = useAppDispatch();

  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const { refetch: refetchWorkcardStatus } = useGetWorkcardStatusByIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const [present, dismiss] = useIonModal(WorkcardApprovalModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    clearErrors,
  } = useForm();

  const workerApprovalButton: any = React.useRef(null);
  const supervisorApprovalButton: any = React.useRef(null);
  const [isSupervisorApproval, setIsSuperviorApproval] = useState(false);

  const { data: dataTasks, error: errorTasks } = useGetTasksByWorkcardIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    lang: lang,
  });

  const { data, error, isLoading, refetch } = useGetDecisionQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const { data: myProfileData } = useGetMyProfileQuery();

  const [updateDecision, response] = useUpdateDecisionMutation();

  const [PostSignature, { error: errorSig, data: res }] = usePostDecisionSignatureMutation();

  const onSubmit = (data: any) => {
    console.log('data', data);

    if (!isSupervisorApproval && workerApprovalButton && workerApprovalButton.current) {
      workerApprovalButton.current.openModal();
    }

    if (isSupervisorApproval && supervisorApprovalButton && supervisorApprovalButton.current) {
      supervisorApprovalButton.current.openModal();
    }
  };

  const handleResizeTextarea = (field: string) => {
    console.log('field', field);

    resizeTextarea(field);

    const textareaVal: any = document.querySelector(`[data-question=${field}]`);
    setValue(field, textareaVal && textareaVal.value);

    setTimeout(() => {
      trigger(field);
    }, 300);
  };

  const handleListeningStart = (e: any) => {
    const newListeningValues: any = {};
    for (const key in listeningValues) {
      if (key === e) {
        newListeningValues[key] = 0;
      } else {
        newListeningValues[key] = ++listeningValues[key];
      }
    }
    setListeningValues(newListeningValues);
  };

  const handleListeningEnd = (e: any) => {
    let payload: any = {};

    payload = {
      [e.questionId]: e.transcript,
    };

    if (data && data[e.questionId as keyof Decision]) {
      payload[e.questionId] = data[e.questionId as keyof Decision] + ' ' + payload[e.questionId];
    }

    try {
      updateDecision({ siteId: selectedSiteId, workcardId: +id, payload })
        .unwrap()
        .then(() => {
          if (e.questionId === 'decisionDiscussionOnTaskToPerform') {
            setFirstVisitDiscussionSaved(true);
            setTimeout(() => {
              setFirstVisitDiscussionSaved(false);
            }, 2000);
          } else if (e.questionId === 'feedBackOnTaskNotPerformedSecurely') {
            setFeedBackOnTaskNotPerformedSecurelySaved(true);
            setTimeout(() => {
              setFeedBackOnTaskNotPerformedSecurelySaved(false);
            }, 2000);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const updateTask = async (task: any) => {
    const payload: any = {
      workcardTaskId: task?.id,
    };

    await updateDecision({ siteId: selectedSiteId, workcardId: +id, payload }).unwrap();
    setSelectedTask(task);
  };

  const scrollToFirstError = () => {
    if (selectedIsMobileScreen) {
      const firstErrorKey = Object.keys(errors).find((key) => errors[key]);
      scrollToErrorField(firstErrorKey);
    }
  };

  const handleTranscriptEnd = (e: any) => {
    let value = e.transcript;

    if (data && data[e.questionId as keyof Decision]) {
      value = data[e.questionId as keyof Decision] + ' ' + e.transcript;
    }

    setValue(e.questionId, value, { shouldValidate: false });
  };

  const handleSubmitButton = (isSupervisor: boolean) => {
    trigger();
    setIsSuperviorApproval(isSupervisor);
    handleSubmit(onSubmit);

    scrollToFirstError();
  };

  const handleRadioChange = (ev: any, name: string, val: boolean | undefined | number = true) => {
    trigger(name);

    try {
      const payload = { [name]: val };

      updateDecision({ siteId: selectedSiteId, workcardId: +id, payload });

      if (name === 'checkUnderstand') {
        setCheckUnderstandSaved(true);

        setTimeout(() => {
          setCheckUnderstandSaved(false);
        }, 2000);
      } else if (name === 'planAndQuoteInWorkplace') {
        setIsPlanAndQuoteInWorkplaceSaved(true);

        setTimeout(() => {
          setIsPlanAndQuoteInWorkplaceSaved(false);
        }, 2000);
      } else if (name === 'canWeWorkSafely') {
        setCanWeWorkSafelySaved(true);

        setTimeout(() => {
          setCanWeWorkSafelySaved(false);
        }, 2000);
      } else if (name === 'authorizeContinuationActivities') {
        setAuthorizeContinuationActivitiesSaved(true);

        setTimeout(() => {
          setAuthorizeContinuationActivitiesSaved(false);
        }, 2000);
      } else if (name === 'isWorkerObservedDuringTask') {
        if (val === false) {
          updateDecision({
            siteId: selectedSiteId,
            workcardId: +id,
            payload: { feedBackOnTaskNotPerformedSecurely: '' },
          });
        }

        setIsWorkerObservedDuringTaskSaved(true);

        setTimeout(() => {
          setIsWorkerObservedDuringTaskSaved(false);
        }, 2000);
      } else if (name === 'isTaskPerformedSecurily') {
        if (val === true) {
          updateDecision({
            siteId: selectedSiteId,
            workcardId: +id,
            payload: { feedBackOnTaskNotPerformedSecurely: '' },
          });
        }

        setIsTaskPerformedSecurilySaved(true);

        setTimeout(() => {
          setIsTaskPerformedSecurilySaved(false);
        }, 2000);
      }
    } catch (error) {
      //
    }
  };

  const handleInputOnBlur = async (e: FocusEvent<any>) => {
    const inputVal: string = e.target.value;
    const questionProp: any = e?.target?.getAttribute('data-question');
    const questionVal: any = data?.[questionProp as keyof Decision] || '';
    let payload: any = {};

    if (inputVal.trim() !== questionVal.trim()) {
      payload = {
        [questionProp]: inputVal.trim(),
      };

      try {
        if (questionProp === 'decisionDiscussionOnTaskToPerform') {
          await updateDecision({ siteId: selectedSiteId, workcardId: +id, payload });
          setFirstVisitDiscussionSaved(true);

          refetch();

          setTimeout(() => {
            setCanWeWorkSafelySaved(false);
          }, 2000);
        } else if (questionProp === 'feedBackOnTaskNotPerformedSecurely') {
          await updateDecision({ siteId: selectedSiteId, workcardId: +id, payload }).unwrap();

          setFeedBackOnTaskNotPerformedSecurelySaved(true);
          setTimeout(() => {
            setFeedBackOnTaskNotPerformedSecurelySaved(false);
          }, 2000);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const handleApproval = () => {
    const payload = {
      employeeLevel: isSupervisorApproval ? 1 : 0,
      signatureCert: '',
    };

    if (isValid) {
      PostSignature({ siteId: selectedSiteId, workcardId: +id, payload });
    }
  };

  useEffect(() => {
    if (myProfileData) {
      setIsSupervisor(hasEmployeeLevelSupervisorOnAnyCrew(myProfileData));
      setIsUserOwner(isUserWorkcardOwner(myProfileData?.crews, selectedWorkcard!));
    }
  }, [myProfileData]);

  useEffect(() => {
    if (data) {
      setLastUpdate(data.lastReadingDate!);
      dispatch(setLastUpdateinfo(data.lastReadingDate!));

      setValue('decisionDiscussionOnTaskToPerform', data.decisionDiscussionOnTaskToPerform || '', {
        shouldValidate: false,
      });
      setValue('feedBackOnTaskNotPerformedSecurely', data.feedBackOnTaskNotPerformedSecurely || '', {
        shouldValidate: false,
      });

      resizeTextarea('decisionDiscussionOnTaskToPerform');
      resizeTextarea('feedBackOnTaskNotPerformedSecurely');
    }

    if (
      data &&
      data.postSignatureSectionModifiedOn &&
      ((isSupervisor && !data.supervisorSignature) || (isUserOwner && !data.workerSignature))
    ) {
      setIsWarningModifiedSig(true);
    } else {
      setIsWarningModifiedSig(false);
    }

    if (data?.workerSignature && data?.supervisorSignature && hasSigned && selectedWorkcard) {
      setTimeout(() => {
        refetchWorkcardStatus();
      }, 4000);
    }
  }, [data]);

  useEffect(() => {
    if (res) {
      if (isSupervisorApproval) {
        supervisorApprovalButton.current.approvalConfirmed();
      } else {
        workerApprovalButton.current.approvalConfirmed();
      }

      setHasSigned(true);
    }
  }, [res]);

  useEffect(() => {
    if (dataTasks && data) {
      const selectedTask = dataTasks.find((task) => {
        return task.id === data.workcardTaskId;
      });

      if (dataTasks) {
        setSelectedTask(selectedTask);
      }
    }
  }, [dataTasks, data]);

  useEffect(() => {
    if (isSupervisorApproval) {
      supervisorApprovalButton?.current?.closeModal();
    } else {
      workerApprovalButton?.current?.closeModal();
    }
  }, [errorSig]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (errors) {
      scrollToFirstError();
    }
  }, [errors]);

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  return (
    <>
      {isLoading && <Loader></Loader>}

      <form onSubmit={handleSubmit(onSubmit)}>
        {selectedMenu === 'plan-decision' && (
          <div className="grid grid-cols-6 gap-0 pt-[7px] lg:pt-0">
            <div className="ml-0 col-span-6 relative w-full z-10">
              {isSupervisor && <WorkcardHeader></WorkcardHeader>}

              {isWarningModifiedSig && (
                <WarningMessageModifiedSig
                  workcardSectionType={WorkcardSectionType.Decision}
                ></WarningMessageModifiedSig>
              )}

              <div className="border-b border-t border-gray-400 dark:border-none bg-gray-100 dark:bg-dark-tertiary px-4 pt-3 lg:px-6 pl-8 lg:h-[62px]">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-center lg:flex-nowrap">
                  <div className=" lg:ml-4 mt-0.5 flex flex-col ">
                    <h3 className="text-[17px] font-semibold uppercase leading-6 text-gray-900 dark:text-white mt-[7px] text-center">
                      {t('FEATURES.WORKCARD.WORKCARD_DECISION.DECISION_TITLE')}
                    </h3>
                    <p className="text-[20px] text-gray-900 dark:text-workcard-decision-primary text-center mt-2"></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-top-decision-plan col-span-6 relative grid grid-cols-6 border-t-[7px]  bg-gradient-to-r from-workcard-decision-tertiary  to-workcard-planification-tertiary  border-workcard-decision-primary my-4  mt-[16px] lg:mt-[0px] pt-[24px] z-20 md:mb-[100px]">
              <div className="absolute text-center mx-auto w-[53px] h-[53px] left-0 right-0 rounded-full bg-gradient-to-r from-workcard-decision-primary to-workcard-planification-secondary top-[-27px]">
                <span className="icon-decision text-[32px] mt-[10px] inline-block"></span>
              </div>

              <div className="hidden lg:col-span-6 ml-0 relative  ">
                <div className=" border-b border-t border-gray-400 bg-gray-100 px-4 py-3 lg:px-6 pl-8 h-[50px]">
                  <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between lg:flex-nowrap">
                    <div className="ml-4 mt-2"></div>
                    <div className="ml-4 mt-2 flex-shrink-0"></div>
                  </div>
                </div>
              </div>

              <div
                className={`mx-4 lg:px-4 mb-6 mt-2 lg:rounded-2xl col-span-6 lg:bg-dark-secondary grid grid-cols-6 ${
                  isWarningModifiedSig ? 'lg:h-[calc(100vh-346px)]' : 'lg:h-[calc(100vh-306px)]'
                } `}
              >
                <div
                  className={`lg:mt-0 col-span-6 lg:col-span-3 ml-0 relative rounded-2xl bg-dark-secondary lg:rounded-none lg:bg-transparent ${
                    isWarningModifiedSig ? 'lg:h-[calc(100vh-350px)]' : 'lg:h-[calc(100vh-310px)]'
                  } lg:overflow-y-auto`}
                >
                  <h2 className="text-workcard-planification-primary font-bold text-center text-lg mt-4 uppercase">
                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.PLANNING')}
                  </h2>
                  <WorkcardPlanning type="workcard" />

                  <div className="relative px-2 py-4 lg:gap-4 lg:px-4 mt-4 mb-6">
                    <dt className="leading-[1.1] text-[14px] text-gray-900 dark:text-quartery-default font-bold">
                      {t('FEATURES.WORKCARD.WORKCARD_DECISION.DISCUSSION_TASK_PERFORM_FIRST_VISIT')}
                    </dt>
                    <dd
                      className={`${
                        !isSupervisor && 'invisible absolute'
                      } mt-1 text-sm text-gray-900 lg:col-span-2 lg:mt-0`}
                    >
                      <div className="mt-0 flex rounded-md shadow-sm">
                        <textarea
                          {...register(`decisionDiscussionOnTaskToPerform`, { required: true })}
                          onBlur={handleInputOnBlur}
                          onChange={() => {
                            handleResizeTextarea('decisionDiscussionOnTaskToPerform');
                          }}
                          data-question="decisionDiscussionOnTaskToPerform"
                          className={`focus:outline-none no-scrollbar text-black bg-white dark:bg-dark-primary dark:text-gray-200 rounded-l-xl border border-[2px] border-gray-400 dark:border-dark-tertiary comment block w-full mt-2`}
                          disabled={!isSupervisor}
                        ></textarea>
                        <SpeechToText
                          class="mt-[8px] h-auto border-l border-black"
                          stopListening={listeningValues.decisionDiscussionOnTaskToPerform}
                          key="decisionDiscussionOnTaskToPerform"
                          questionId="decisionDiscussionOnTaskToPerform"
                          onListeningStart={handleListeningStart}
                          onListeningEnd={handleListeningEnd}
                          onTranscriptEnd={handleTranscriptEnd}
                        ></SpeechToText>
                      </div>
                      {errors[`decisionDiscussionOnTaskToPerform`]?.type === 'required' && (
                        <div className="absolute mt-[4px] text-[13px] text-red-600" role="alert">
                          <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                          <span className="font-semibold ">
                            {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                          </span>
                        </div>
                      )}
                      <SaveIndicator isSaved={isFirstVisitDiscussionSaved}></SaveIndicator>
                    </dd>
                    {!isSupervisor && (
                      <dd
                        className={`${
                          data?.decisionDiscussionOnTaskToPerform ? 'text-[#646464] text-[14px]' : 'text-red-700'
                        } mt-2 lg:col-span-2 `}
                      >
                        {data?.decisionDiscussionOnTaskToPerform || (
                          <div>
                            <i
                              className={`fa-solid fa-triangle-exclamation ${
                                errors[`decisionDiscussionOnTaskToPerform`]?.type === 'required'
                                  ? 'text-red-600'
                                  : 'text-[#646464]'
                              }   pr-1  pb-[6px]`}
                            ></i>
                            <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                              {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                            </span>
                          </div>
                        )}
                      </dd>
                    )}
                  </div>

                  <div className="relative px-2 grid grid-cols-6 lg:gap-4 lg:px-4 mb-2 pt-1 mt-4">
                    <div className="absolute w-[100px] left-3 bottom-1">
                      <SaveIndicator isSaved={isPlanAndQuoteInWorkplaceSaved}></SaveIndicator>
                    </div>

                    <dt className="leading-[1.1] col-span-6 lg:col-span-6 text-[14px]  text-gray-700 dark:text-quartery-default font-bold">
                      {t('FEATURES.WORKCARD.WORKCARD_DECISION.PLAN_ESTIMATE_CORRESPOND_WORKPLACE')}
                      {isSupervisor && errors[`planAndQuoteInWorkplace`]?.type === 'required' && (
                        <div className="absolute bottom-[-13px] text-[13px] text-red-600" role="alert">
                          <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                          <span className="font-semibold ">
                            {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                          </span>
                        </div>
                      )}
                    </dt>
                    <dd className="col-span-6 mt-2 text-lg text-gray-900 lg:mt-0">
                      <ul
                        data-question="planAndQuoteInWorkplace"
                        className={`${!isSupervisor && 'invisible h-0'} flex  gap-1 w-full lg:grid-cols-6 mb-2`}
                      >
                        <li className={`${isSupervisor ? 'w-[33%] !md:text-left' : 'w-full'}  text-center`}>
                          <input
                            {...register(`planAndQuoteInWorkplace`, { required: true })}
                            type="radio"
                            id="yes-planAndQuoteInWorkplace"
                            name="yes-planAndQuoteInWorkplace"
                            value={PlanAndQuoteWorkplaceAnswer.yes}
                            className="hidden peer"
                            onChange={(ev) =>
                              handleRadioChange(ev, 'planAndQuoteInWorkplace', PlanAndQuoteWorkplaceAnswer.yes)
                            }
                            checked={data?.planAndQuoteInWorkplace === PlanAndQuoteWorkplaceAnswer.yes}
                          />
                          <label
                            htmlFor="yes-planAndQuoteInWorkplace"
                            className={`mt-2 cursor-pointer inline-flex justify-between items-center  text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative ${
                              isSupervisor && 'ml-0'
                            }`}
                          >
                            <span className="w-full text-[23px] font-extrabold text-center  mt-1.5">
                              {t('FEATURES.WORKCARD.YES')}
                            </span>
                          </label>
                        </li>

                        <li className="  w-[33%] lg:w-[26%] text-center md:text-left">
                          <input
                            {...register(`planAndQuoteInWorkplace`, { required: true })}
                            type="radio"
                            id="no-planAndQuoteInWorkplace"
                            name="no-planAndQuoteInWorkplace"
                            value={PlanAndQuoteWorkplaceAnswer.no}
                            className="hidden peer"
                            onChange={(ev) =>
                              handleRadioChange(ev, 'planAndQuoteInWorkplace', PlanAndQuoteWorkplaceAnswer.no)
                            }
                            checked={data?.planAndQuoteInWorkplace === PlanAndQuoteWorkplaceAnswer.no}
                          />
                          <label
                            htmlFor="no-planAndQuoteInWorkplace"
                            className={`mt-2 cursor-pointer inline-flex justify-between items-center  text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative mr-8`}
                          >
                            <span className="w-full text-[23px] font-extrabold text-center mt-1.5">
                              {t('FEATURES.WORKCARD.NO')}
                            </span>
                          </label>
                        </li>

                        <li className="  w-[33%] text-center md:text-left">
                          <input
                            {...register(`planAndQuoteInWorkplace`, { required: true })}
                            type="radio"
                            id="na-planAndQuoteInWorkplace"
                            name="na-planAndQuoteInWorkplace"
                            value={PlanAndQuoteWorkplaceAnswer.na}
                            className="hidden peer"
                            onChange={(ev) =>
                              handleRadioChange(ev, 'planAndQuoteInWorkplace', PlanAndQuoteWorkplaceAnswer.na)
                            }
                            checked={data?.planAndQuoteInWorkplace === PlanAndQuoteWorkplaceAnswer.na}
                          />
                          <label
                            htmlFor="na-planAndQuoteInWorkplace"
                            className={`mt-2 cursor-pointer inline-flex justify-between items-center  text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative mr-8`}
                          >
                            <span className="w-full text-[23px] font-extrabold text-center mt-1.5">
                              {t('FEATURES.WORKCARD.NA')}
                            </span>
                          </label>
                        </li>
                      </ul>

                      {!isSupervisor && (
                        <div
                          className={`${
                            data?.planAndQuoteInWorkplace === null || data?.planAndQuoteInWorkplace === undefined
                              ? 'text-red-700'
                              : 'text-gray-400'
                          } mt-[-8px] mb-2`}
                        >
                          {data &&
                          data.planAndQuoteInWorkplace !== null &&
                          data.planAndQuoteInWorkplace !== undefined ? (
                            <>
                              {data.planAndQuoteInWorkplace === PlanAndQuoteWorkplaceAnswer.yes && (
                                <>
                                  <span className="text-[#646464] text-[14px]">
                                    {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                  </span>{' '}
                                  <span className="text-white text-[16px] font-bold">{t('FEATURES.WORKCARD.YES')}</span>
                                </>
                              )}
                              {data.planAndQuoteInWorkplace === PlanAndQuoteWorkplaceAnswer.no && (
                                <>
                                  <span className="text-[#646464] text-[14px]">
                                    {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                  </span>{' '}
                                  <span className="text-white text-[16px] font-bold">{t('FEATURES.WORKCARD.NO')}</span>
                                </>
                              )}
                              {data.planAndQuoteInWorkplace === PlanAndQuoteWorkplaceAnswer.na && (
                                <>
                                  <span className="text-[#646464] text-[14px]">
                                    {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                  </span>{' '}
                                  <span className="text-white text-[16px] font-bold">{t('FEATURES.WORKCARD.NA')}</span>
                                </>
                              )}
                            </>
                          ) : (
                            <div>
                              <i
                                className={`fa-solid fa-triangle-exclamation ${
                                  errors[`checkUnderstand`]?.type === 'required' ? 'text-red-600' : 'text-[#646464]'
                                }   pr-1  pb-[6px]`}
                              ></i>
                              <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </dd>
                  </div>
                </div>

                <div className="col-span-6 lg:col-span-3 ml-0 relative rounded-2xl bg-dark-secondary lg:rounded-none lg:bg-transparent mt-8 lg:mt-0">
                  <div
                    className={`no-scrollbar  dark:mb-4 dark:mt-0  px-1 pb-3 lg:!mt-4 lg:px-0 ${
                      isWarningModifiedSig ? 'lg:h-[calc(100vh-370px)]' : 'lg:h-[calc(100vh-330px)]'
                    } dark:min-h-0 overflow-y-auto`}
                  >
                    <h2 className="text-workcard-decision-primary font-bold text-center text-lg uppercase mb-4 pt-5 lg:pt-0">
                      {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.DECISION')}
                    </h2>

                    <div className="items-center xl:px-4 mx-auto">
                      <div className=" mt-0 md:mx-4">
                        <div className="relative px-2 grid grid-cols-6 lg:gap-4 lg:px-4 mb-2 pt-1 mt-8">
                          <div className="absolute w-[100px] left-3 bottom-1">
                            <SaveIndicator isSaved={isCheckUnderstandSaved}></SaveIndicator>
                          </div>

                          <dt className="leading-[1.1] col-span-6 lg:col-span-6 text-[14px]  text-gray-700 dark:text-quartery-default font-bold">
                            {t('FEATURES.WORKCARD.WORKCARD_DECISION.CHECK_UNDERSTAND_EACH_OTHER')} :
                            {isSupervisor && errors[`checkUnderstand`]?.type === 'required' && (
                              <div className="absolute bottom-[-13px] text-[13px] text-red-600" role="alert">
                                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                <span className="font-semibold ">
                                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                                </span>
                              </div>
                            )}
                          </dt>
                          <dd className="col-span-6 mt-2 text-lg text-gray-900 lg:mt-0">
                            <ul
                              data-question="checkUnderstand"
                              className={`${!isSupervisor && 'invisible h-0'} flex  gap-4 w-full lg:grid-cols-6 mb-2`}
                            >
                              <li className={`${isSupervisor ? 'w-[50%] md:text-right' : 'w-full'}  text-center`}>
                                <input
                                  {...register(`checkUnderstand`, { required: true })}
                                  type="radio"
                                  id="yes-understand"
                                  name="yes-understand"
                                  value="yes"
                                  className="hidden peer"
                                  onChange={(ev) => handleRadioChange(ev, 'checkUnderstand', true)}
                                  checked={data?.checkUnderstand}
                                />
                                <label
                                  htmlFor="yes-understand"
                                  className={`mt-2 cursor-pointer inline-flex justify-between items-center  text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative ${
                                    isSupervisor && 'ml-8'
                                  }`}
                                >
                                  <span className="w-full text-[23px] font-extrabold text-center  mt-1.5">
                                    {t('FEATURES.WORKCARD.YES')}
                                  </span>
                                </label>
                              </li>

                              <li className="  w-[50%] text-center md:text-left">
                                <input
                                  {...register(`checkUnderstand`, { required: true })}
                                  type="radio"
                                  id="no-understand"
                                  name="no-understand"
                                  value="no"
                                  className="hidden peer"
                                  onChange={(ev) => handleRadioChange(ev, 'checkUnderstand', false)}
                                  checked={data?.checkUnderstand === false}
                                />
                                <label
                                  htmlFor="no-understand"
                                  className={`mt-2 cursor-pointer inline-flex justify-between items-center  text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative mr-8`}
                                >
                                  <span className="w-full text-[23px] font-extrabold text-center mt-1.5">
                                    {t('FEATURES.WORKCARD.NO')}
                                  </span>
                                </label>
                              </li>
                            </ul>

                            {isSupervisor && data?.checkUnderstand === false && (
                              <div className="w-full m-auto flex justify-center ">
                                <div className="w-[300px] bg-black text-white border-[2px] rounded-md border-red-600 text-left text-[12px] font-semibold leading-[14px] py-1.5 pl-[55px] pr-4 relative">
                                  <i className="fa-solid fa-exclamation-triangle absolute text-[35px] left-2 top-[-10px] text-red-600"></i>
                                  {t(
                                    'FEATURES.WORKCARD.WORKCARD_DECISION.IMPORTANT_TO_MAKE_SURE_WE_UNDERSTAND_EACH_OTHER'
                                  )}
                                </div>
                              </div>
                            )}

                            {!isSupervisor && (
                              <div
                                className={`${
                                  data?.checkUnderstand === null || data?.checkUnderstand === undefined
                                    ? 'text-red-700'
                                    : 'text-gray-400'
                                } mt-[-8px] mb-2`}
                              >
                                {data && data.checkUnderstand !== null && data.checkUnderstand !== undefined ? (
                                  data.checkUnderstand === true ? (
                                    <>
                                      <span className="text-[#646464] text-[14px]">
                                        {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                      </span>{' '}
                                      <span className="text-white text-[16px] font-bold">
                                        {t('FEATURES.WORKCARD.YES')}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="text-[#646464] text-[14px]">
                                        {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                      </span>{' '}
                                      <span className="text-white text-[16px] font-bold">
                                        {t('FEATURES.WORKCARD.NO')}
                                      </span>
                                    </>
                                  )
                                ) : (
                                  <div>
                                    <i
                                      className={`fa-solid fa-triangle-exclamation ${
                                        errors[`checkUnderstand`]?.type === 'required'
                                          ? 'text-red-600'
                                          : 'text-[#646464]'
                                      }   pr-1  pb-[6px]`}
                                    ></i>
                                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </dd>
                        </div>

                        <div className="relative  px-2 py-4 grid grid-cols-6 lg:gap-4 lg:px-4 mb-2 mt-4">
                          <div className="absolute w-[100px] left-3 bottom-5">
                            <SaveIndicator isSaved={isCanWeWorkSafelySaved}></SaveIndicator>
                          </div>

                          <dt className="leading-[1.1] col-span-6 lg:col-span-6 text-[14px]  text-gray-700 dark:text-quartery-default font-bold">
                            {t('FEATURES.WORKCARD.WORKCARD_DECISION.MAKE_SURE_WORK_SAFELY')}
                            {isSupervisor && errors[`canWeWorkSafely`]?.type === 'required' && (
                              <div className="absolute bottom-[2px] text-[13px] text-red-600" role="alert">
                                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                <span className="font-semibold ">
                                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                                </span>
                              </div>
                            )}
                          </dt>
                          <dd className="col-span-6 mt-2 text-lg text-gray-900 lg:mt-0">
                            <ul
                              data-question="canWeWorkSafely"
                              className={`${!isSupervisor && 'invisible h-0'} flex gap-4 w-full lg:grid-cols-6 mb-2`}
                            >
                              <li className={`${isSupervisor ? 'w-[50%] md:text-right' : 'w-full'} text-center`}>
                                <input
                                  {...register(`canWeWorkSafely`, { required: true })}
                                  type="radio"
                                  id="yes-safely"
                                  name="yes-safely"
                                  value="yes"
                                  className="hidden peer"
                                  onChange={(ev) => handleRadioChange(ev, 'canWeWorkSafely', true)}
                                  checked={data?.canWeWorkSafely}
                                />
                                <label
                                  htmlFor="yes-safely"
                                  className={`mt-2 cursor-pointer inline-flex justify-between items-center text-white  rounded-2xl border bg-black border-gray-500 cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative ${
                                    isSupervisor && 'ml-8'
                                  }`}
                                >
                                  <span className="w-full text-[23px] font-extrabold text-center mt-1.5">
                                    {t('FEATURES.WORKCARD.YES')}
                                  </span>
                                </label>
                              </li>
                              {
                                <li className="  w-[50%] text-center md:text-left">
                                  <input
                                    {...register(`canWeWorkSafely`, { required: true })}
                                    type="radio"
                                    id="no-safely"
                                    name="no-safely"
                                    value="no"
                                    className="hidden peer"
                                    onChange={(ev) => handleRadioChange(ev, 'canWeWorkSafely', false)}
                                    checked={data?.canWeWorkSafely === false}
                                  />
                                  <label
                                    htmlFor="no-safely"
                                    className={`mt-2 cursor-pointer inline-flex justify-between items-center text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative mr-8`}
                                  >
                                    <span className="w-full text-[23px] font-extrabold text-center mt-1.5">
                                      {t('FEATURES.WORKCARD.NO')}
                                    </span>
                                  </label>
                                </li>
                              }
                            </ul>

                            {isSupervisor && data?.canWeWorkSafely === false && (
                              <div className="w-full m-auto flex justify-center ">
                                <div className="w-[300px] bg-black text-white border-[2px] rounded-md border-red-600 text-left text-[12px] font-semibold leading-[14px] py-1.5 pl-[55px] pr-4 mt-2.5 relative">
                                  <i className="fa-solid fa-exclamation-triangle absolute text-[35px] left-2 top-[-10px] text-red-600"></i>
                                  {t('FEATURES.WORKCARD.WORKCARD_DECISION.IMPORTANT_TO_MAKE_SURE_WORK_EXECUTED_SAFELY')}
                                </div>
                              </div>
                            )}

                            {!isSupervisor && (
                              <div
                                className={`${
                                  data?.canWeWorkSafely === null || data?.canWeWorkSafely === undefined
                                    ? 'text-red-700'
                                    : 'text-gray-400'
                                } mt-[-8px] mb-2`}
                              >
                                {data && data.canWeWorkSafely !== null && data.canWeWorkSafely !== undefined ? (
                                  data.canWeWorkSafely === true ? (
                                    <>
                                      <span className="text-[#646464] text-[14px]">
                                        {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                      </span>{' '}
                                      <span className="text-white text-[16px] font-bold">
                                        {t('FEATURES.WORKCARD.YES')}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="text-[#646464] text-[14px]">
                                        {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                      </span>{' '}
                                      <span className="text-white text-[16px] font-bold">
                                        {t('FEATURES.WORKCARD.NO')}
                                      </span>
                                    </>
                                  )
                                ) : (
                                  <div>
                                    <i
                                      className={`fa-solid fa-triangle-exclamation ${
                                        errors[`canWeWorkSafely`]?.type === 'required'
                                          ? 'text-red-600'
                                          : 'text-[#646464]'
                                      }   pr-1  pb-[6px]`}
                                    ></i>
                                    <span className="text-[13px] text-[#646464] py-1 pb-[4.5px]">
                                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </dd>
                        </div>

                        <div className="relative  px-2 pb-0 grid grid-cols-6 lg:gap-4 lg:px-4 mb-2 mt-4">
                          <div className="absolute w-[100px] left-3 bottom-1">
                            <SaveIndicator isSaved={isAuthorizeContinuationActivitiesSaved}></SaveIndicator>
                          </div>
                          <dt className="leading-[1.1] col-span-6 lg:col-span-6 text-[14px]  text-gray-700 dark:text-quartery-default font-bold">
                            {t('FEATURES.WORKCARD.WORKCARD_DECISION.AUTHORIZE_CONTINUATION_ACTIVITIES')}
                            {isSupervisor && errors[`authorizeContinuationActivities`]?.type === 'required' && (
                              <div className="absolute bottom-[-12px] text-[13px] text-red-600" role="alert">
                                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                <span className="font-semibold ">
                                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                                </span>
                              </div>
                            )}
                          </dt>
                          <dd className="col-span-6 mt-2 text-lg text-gray-900 lg:mt-0">
                            <ul
                              data-question="authorizeContinuationActivities"
                              className={`${!isSupervisor && 'invisible h-0'} flex gap-4 w-full lg:grid-cols-6 mb-2`}
                            >
                              <li className={`${isSupervisor ? 'w-[50%] md:text-right' : 'w-full'} text-center `}>
                                <input
                                  {...register(`authorizeContinuationActivities`, { required: true })}
                                  type="radio"
                                  id="yes-continue"
                                  name="yes-continue"
                                  value="yes"
                                  className="hidden peer"
                                  onChange={(ev) => handleRadioChange(ev, 'authorizeContinuationActivities', true)}
                                  checked={data?.authorizeContinuationActivities}
                                />
                                <label
                                  htmlFor="yes-continue"
                                  className={`mt-2 cursor-pointer inline-flex justify-between items-center text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative ${
                                    isSupervisor && 'ml-8'
                                  }`}
                                >
                                  <span className="w-full text-[23px] font-extrabold text-center mt-1.5">
                                    {t('FEATURES.WORKCARD.YES')}
                                  </span>
                                </label>
                              </li>
                              {
                                <li className="  w-[50%] text-center md:text-left">
                                  <input
                                    {...register(`authorizeContinuationActivities`, { required: true })}
                                    type="radio"
                                    id="no-continue"
                                    name="no-continue"
                                    value="no"
                                    className="hidden peer"
                                    onChange={(ev) => handleRadioChange(ev, 'authorizeContinuationActivities', false)}
                                    checked={data?.authorizeContinuationActivities === false}
                                  />
                                  <label
                                    htmlFor="no-continue"
                                    className={`mt-2 cursor-pointer inline-flex justify-between items-center text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-decision-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-decision-primary w-[105px] h-[75px] peer-checked:arrow-down-decision relative mr-8`}
                                  >
                                    <span className="w-full text-[23px] font-extrabold text-center mt-1.5">
                                      {t('FEATURES.WORKCARD.NO')}
                                    </span>
                                  </label>
                                </li>
                              }
                            </ul>

                            {!isSupervisor && (
                              <div
                                className={`${
                                  data?.authorizeContinuationActivities === null ||
                                  data?.authorizeContinuationActivities === undefined
                                    ? 'text-red-700'
                                    : 'text-gray-400'
                                } mt-[-8px] mb-2`}
                              >
                                {data &&
                                data.authorizeContinuationActivities !== null &&
                                data.authorizeContinuationActivities !== undefined ? (
                                  data.authorizeContinuationActivities === true ? (
                                    <>
                                      <span className="text-[#646464] text-[14px]">
                                        {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                      </span>{' '}
                                      <span className="text-white text-[16px] font-bold">
                                        {t('FEATURES.WORKCARD.YES')}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="text-[#646464] text-[14px]">
                                        {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                      </span>{' '}
                                      <span className="text-white text-[16px] font-bold">
                                        {t('FEATURES.WORKCARD.NO')}
                                      </span>
                                    </>
                                  )
                                ) : (
                                  <div>
                                    <i
                                      className={`fa-solid fa-triangle-exclamation ${
                                        errors[`authorizeContinuationActivities`]?.type === 'required'
                                          ? 'text-red-600'
                                          : 'text-[#646464]'
                                      }   pr-1  pb-[6px]`}
                                    ></i>
                                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </dd>
                        </div>

                        {!selectedIsMobileScreen && (
                          <div className="grid grid-cols-2 lg:gap-4 mt-10 mx-2">
                            <div className="col-span-2 m-auto border-t-[1px] border-[#646464] w-full text-center px-4 pt-3.5 mb-4">
                              <h3 className="uppercase font-bold">{t('LABELS.SIGNATURE')}</h3>
                            </div>
                            <div className="relative px-4 py-4 lg:gap-4 lg:px-0 text-center">
                              {data?.supervisorSignature === null && (
                                <dt className="text-[13px] truncate text-[#646464] font-bold mb-2 text-center">
                                  {t('FEATURES.WORKCARD.WORKCARD_DECISION.SUPERVISOR_APPROVAL')}
                                </dt>
                              )}
                              <WorkcardApprovalButton
                                colorButton="decision"
                                key="supervisor"
                                userType="Supervisor"
                                approvalStatus={data && data.supervisorSignature}
                                isApprovalButton={true}
                                ref={supervisorApprovalButton}
                                handleSubmit={() => handleSubmitButton(true)}
                                handleApproval={handleApproval}
                              />
                            </div>
                            <div className="px-4 py-4 lg:gap-6 lg:px-0 text-center">
                              {data?.workerSignature === null && (
                                <dt className="relative text-[13px] truncate  text-[#646464]  font-bold mb-2 text-center">
                                  {t('FEATURES.WORKCARD.WORKCARD_DECISION.WORKER_APPROVAL')}
                                </dt>
                              )}
                              <WorkcardApprovalButton
                                colorButton="decision"
                                isApprovalButton={true}
                                approvalStatus={data && data.workerSignature}
                                key="worker"
                                userType="Worker"
                                ref={workerApprovalButton}
                                handleSubmit={() => handleSubmitButton(false)}
                                handleApproval={handleApproval}
                              />
                            </div>
                            {isWarningModifiedSig && (
                              <div className="col-span-2">
                                <WarningMessageModifiedSig
                                  workcardSectionType={WorkcardSectionType.Decision}
                                ></WarningMessageModifiedSig>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="ml-4 mt-2 flex-shrink-0"></div>
                      </div>
                    </div>
                  </div>
                </div>

                {selectedIsMobileScreen && (
                  <div className="grid grid-cols-2 mt-8 mx-0 col-span-6 rounded-2xl bg-dark-secondary">
                    <div className="col-span-2 m-auto w-full text-center px-4 pt-3.5 mb-4">
                      <h3 className="uppercase font-bold">{t('LABELS.SIGNATURE')}</h3>
                    </div>
                    <div className="relative px-4 py-4 lg:gap-4 lg:px-0 text-center">
                      {data?.supervisorSignature === null && (
                        <dt className="text-[13px] truncate text-[#646464] font-bold mb-2 text-center">
                          {t('FEATURES.WORKCARD.WORKCARD_DECISION.SUPERVISOR_APPROVAL')}
                        </dt>
                      )}
                      <WorkcardApprovalButton
                        colorButton="decision"
                        key="supervisor"
                        userType="Supervisor"
                        approvalStatus={data && data.supervisorSignature}
                        isApprovalButton={true}
                        ref={supervisorApprovalButton}
                        handleSubmit={() => handleSubmitButton(true)}
                        handleApproval={handleApproval}
                      />
                    </div>
                    <div className="px-4 py-4 lg:gap-6 lg:px-0 text-center">
                      {data?.workerSignature === null && (
                        <dt className="relative text-[13px] truncate  text-[#646464]  font-bold mb-2 text-center">
                          {t('FEATURES.WORKCARD.WORKCARD_DECISION.WORKER_APPROVAL')}
                        </dt>
                      )}
                      <WorkcardApprovalButton
                        colorButton="decision"
                        isApprovalButton={true}
                        approvalStatus={data && data.workerSignature}
                        key="worker"
                        userType="Worker"
                        ref={workerApprovalButton}
                        handleSubmit={() => handleSubmitButton(false)}
                        handleApproval={handleApproval}
                      />
                    </div>
                    {isWarningModifiedSig && (
                      <div className="col-span-2">
                        <WarningMessageModifiedSig
                          workcardSectionType={WorkcardSectionType.Decision}
                        ></WarningMessageModifiedSig>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
