import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import type { OverlayEventDetail } from '@ionic/core';
import { IonModal, IonDatetime, IonDatetimeButton, useIonAlert, useIonViewWillLeave } from '@ionic/react';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import UserSearchModal from '../../../../components/UserSearchModal.tsx/UserSearchModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { setFilterDate } from '../../../../layout/header/DashboardHeader/components/DashboardFilter/DashboardFilterSlice';
import type { UserProfile } from '../../../../models';
import { hasEmployeeLevelSupervisorOnAnyCrew } from '../../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import type { RootState } from '../../../../store/store';

import './DashboardFilters.scss';
import { setFilters } from './DashboardFiltersSlice';

const shifts = [
  { id: '', name: 'All', label: 'FEATURES.DASHBOARD_FILTER.ALL' },
  { id: '0', name: 'Day', label: 'FEATURES.DASHBOARD_FILTER.DAY' },
  { id: '1', name: 'Night', label: 'FEATURES.DASHBOARD_FILTER.NIGHT' },
];

export const DashboardFilters: React.FC<unknown> = () => {
  const mediaQuery = '(max-width: 768px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const { t } = useTranslation();
  const [isModalDateFromOpen, setIsModalDateFromOpen] = useState(false);
  const [isModalDateToOpen, setIsModalDateToOpen] = useState(false);

  const selectedDateFrom = useAppSelector((state) => state.dashboardFilterDate.value);

  // const [selectedDateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [selectedDateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
  const [selectedShift, setShift] = useState(shifts[0]);
  const [selectedUser, setUser] = useState<UserProfile | null>(null);
  const [noSelectedUser, setNoSelectedUser] = useState<boolean>(false);
  const [isModalUserOpen, setIsModalUser] = useState(false);
  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);

  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const dispatch = useAppDispatch();

  const modalDateFrom = useRef<HTMLIonModalElement>(null);
  const modalDateTo = useRef<HTMLIonModalElement>(null);
  const modalUser = useRef<HTMLIonModalElement>(null);
  const mountedRef = useRef(true);

  const { data: myProfileData } = useGetMyProfileQuery();

  const mobileScreenChange = (event: any) => {
    if (isModalUserOpen) {
      setIsModalUser(false);

      if (modalUser.current) {
        modalUser.current.dismiss();
      }
    }

    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  const [presentAlert, hideAlert] = useIonAlert();

  const handleDateFromChanged = (ev: any) => {
    dispatch(setFilterDate(ev.detail.value));

    if (moment(selectedDateTo).isBefore(moment(ev.detail.value), 'day')) {
      setDateTo(ev.detail.value);
    }

    setIsModalDateFromOpen(false);
  };

  const handleCloseModaleUser = () => {
    setIsModalUser(false);
  };

  const handleDateToChanged = (ev: any) => {
    setDateTo(ev.detail.value);

    if (moment(ev.detail.value).isBefore(moment(selectedDateFrom), 'day')) {
      dispatch(setFilterDate(ev.detail.value));
    }

    setIsModalDateToOpen(false);
  };

  const handleSearch = () => {
    dispatch(
      setFilters({
        fromDate: selectedDateFrom,
        toDate: selectedDateTo,
        shift: selectedShift.id,
        userObjectId: selectedUser ? selectedUser.azureObjectId : '',
      })
    );
  };

  const handleOpenModalUser = () => {
    setIsModalUser(true);
  };

  const handleShiftChanged = (ev: any) => {
    const shift = shifts.find((shift) => shift.name === ev.value);

    setShift(shift!);

    hideAlert();
  };

  const onWillDismissDateFrom = (ev: CustomEvent<OverlayEventDetail>) => {
    setIsModalDateFromOpen(false);
  };

  const onWillDismissDateTo = (ev: CustomEvent<OverlayEventDetail>) => {
    setIsModalDateToOpen(false);
  };

  const handleSelectUser = (user: any) => {
    setUser(user);
    setNoSelectedUser(false);
  };

  useEffect(() => {
    if (moment(selectedDateTo, 'YYYY-MM-DD').isBefore(moment(selectedDateFrom, 'YYYY-MM-DD'), 'day')) {
      setDateTo(selectedDateFrom);
    }
  }, [selectedDateFrom]);

  useEffect(() => {
    if (myProfileData) {
      setIsSupervisor(hasEmployeeLevelSupervisorOnAnyCrew(myProfileData));
    }
  }, [myProfileData]);

  return (
    <div className="mt-2 md:mt-0 px-5">
      <div className="mx-auto max-w-6xl ">
        <div className="grid grid-cols-6 mdl:flex gap-y-0 md:gap-y-5 gap-3 flex-row items-center justify-around w-full pt-1 flex-wrap sm:flex-nowrap">
          <section className="col-span-2 flex flex-row  items-center xl:space-x-4 gap-1 xl:gap-0 ">
            <div className="relative w-1/2 lg:w-auto xl:w-[120px] py-1.5 sm:py-2 px-0 sm:px-4">
              <div
                onClick={(e) => {
                  setIsModalDateFromOpen(true);
                }}
                className="absolute w-full h-full left-0 top-0 z-50"
              ></div>
              <span className="block rounded border border-gray-600 text-xs font-medium text-gray-400 py-1 px-2 uppercase truncate">
                {t('FEATURES.DASHBOARD_FILTER.FROM')}
              </span>
              <h2 className="text-sm  font-gray-900 h-[30px]">
                <IonDatetimeButton className="inline" datetime="date1"></IonDatetimeButton>

                <IonModal
                  ref={modalDateFrom}
                  keepContentsMounted={true}
                  isOpen={isModalDateFromOpen}
                  onWillDismiss={(ev) => onWillDismissDateFrom(ev)}
                >
                  <IonDatetime
                    value={selectedDateFrom}
                    onIonChange={handleDateFromChanged}
                    presentation="date"
                    id="date1"
                    color="#1d1d1b"
                  ></IonDatetime>
                </IonModal>
              </h2>
            </div>
            <div className="relative cursor-pointer  w-1/2 lg:w-auto xl:w-[120px] py-1.5 ">
              <div
                onClick={(e) => {
                  setIsModalDateToOpen(true);
                }}
                className="absolute w-full h-full left-0 top-0 z-50"
              ></div>
              <span className="block rounded border border-gray-600 text-xs font-medium text-gray-400 py-1 px-4 uppercase truncate">
                {t('FEATURES.DASHBOARD_FILTER.TO')}
              </span>
              <h2 className="text-sm font-gray-900  h-[30px]">
                <IonDatetimeButton className="inline" datetime="date2"></IonDatetimeButton>

                <IonModal
                  ref={modalDateTo}
                  keepContentsMounted={true}
                  isOpen={isModalDateToOpen}
                  onWillDismiss={(ev) => onWillDismissDateTo(ev)}
                >
                  <IonDatetime
                    value={selectedDateTo}
                    onIonChange={handleDateToChanged}
                    presentation="date"
                    id="date2"
                    color="#1d1d1b"
                  ></IonDatetime>
                </IonModal>
              </h2>
            </div>
          </section>

          <div
            onClick={() =>
              presentAlert({
                header: t('FEATURES.DASHBOARD_FILTER.SELECT_SHIFT'),
                buttons: [],
                inputs: [
                  {
                    label: t('FEATURES.DASHBOARD_FILTER.ALL'),
                    type: 'radio',
                    value: 'All',
                    checked: selectedShift.id === '' ? true : false,
                    handler: (e) => handleShiftChanged(e),
                  },
                  {
                    label: t('FEATURES.DASHBOARD_FILTER.DAY'),
                    type: 'radio',
                    value: 'Day',
                    checked: selectedShift.id === '0' ? true : false,
                    handler: (e) => handleShiftChanged(e),
                  },
                  {
                    label: t('FEATURES.DASHBOARD_FILTER.NIGHT'),
                    type: 'radio',
                    value: 'Night',
                    checked: selectedShift.id === '1' ? true : false,
                    handler: (e) => handleShiftChanged(e),
                  },
                ],
              })
            }
            className="cursor-pointer relative  xl:items-center mt-[0px] ml-[-8px] "
          >
            <section className="mt-[0]">
              <span className="block rounded border border-gray-600 text-xs font-medium text-gray-400 py-1 px-2 uppercase truncate">
                {t('FEATURES.DASHBOARD_FILTER.SHIFT')}
              </span>
              <h2 className="inline-block h-[30px] text-base lg:text-base font-gray-900  flex items-center relative top-[-2px] ml-3">
                {selectedShift.name === 'All' && (
                  <>
                    <i className="fa-solid fa-sun mr-1"></i> <i className="fa-solid fa-moon"></i>
                  </>
                )}
                {selectedShift.name === 'Day' && <i className="fa-solid fa-sun"></i>}
                {selectedShift.name === 'Night' && <i className="fa-solid fa-moon"></i>}
              </h2>
            </section>
          </div>

          {isSupervisor && (
            <div
              onClick={() => handleOpenModalUser()}
              className={`${
                noSelectedUser && 'error-no-selected-user'
              } overflow-hidden relative cursor-pointer col-span-2 min-w-[100px] lg:min-w-[120px] lg:ml-2 mt-[-2px] ml-[-10px] z-100 `}
            >
              <section className="">
                <span className="block rounded border border-gray-600 text-xs font-medium text-gray-400 py-1 px-2 uppercase truncate">
                  {t('FEATURES.DASHBOARD_FILTER.USER_OR_CREW')}
                </span>
                {/* {noSelectedUser && (
                <ExclamationCircleIcon className=" top-[1px] inline-block w-5 h-5 text-red-600 float-right"></ExclamationCircleIcon>
              )} */}
                <h2 className="text-xs text-white h-[30px] mt-[-2px] ml-1.5 flex items-center w-full cursor-pointer truncate">
                  {selectedUser && <span className="">{selectedUser.name}</span>}
                  {!selectedUser && <span className="">{t('FEATURES.DASHBOARD_FILTER.USER_OR_CREW')}</span>}
                </h2>
              </section>

              {selectedIsMobileScreen && (
                <IonModal
                  ref={modalUser}
                  isOpen={isModalUserOpen}
                  initialBreakpoint={0.7}
                  // breakpoints={[0, 0.35, 0.5, 0.85]}
                  onDidDismiss={() => handleCloseModaleUser()}
                >
                  <UserSearchModal modalRef={modalUser} onSelectUser={(user: any) => handleSelectUser(user)} />
                </IonModal>
              )}

              {!selectedIsMobileScreen && (
                <IonModal ref={modalUser} isOpen={isModalUserOpen} onDidDismiss={() => handleCloseModaleUser()}>
                  <UserSearchModal modalRef={modalUser} onSelectUser={(user: any) => handleSelectUser(user)} />
                </IonModal>
              )}
            </div>
          )}

          <div className="z-10 border border-gray-300 dark:border-primary-default  rounded-md  py-[5px] md:px-[20px] px-1  bg-white text-black mt-[-30px] ">
            <section className="cursor-pointer">
              <h2
                className="text-base lg:text-xl font-gray-900 flex items-center justify-center text-center mt-[-3px]"
                onClick={() => {
                  handleSearch();
                }}
              >
                <i className="inline-block fa-solid fa-magnifying-glass text-xs mt-[2px]"></i>
              </h2>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
