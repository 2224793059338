import { EmployeeLevelId, RoleId } from '../../enums';
import type { Crew, CrewMember, Employee, UserProfile, Workcard } from '../../models';

export const getEmployeeLevelOnCrew = (crew: Crew, data: UserProfile): EmployeeLevelId => {
  const myCrewMembership = crew?.crewMembers?.find((member: any) => member.employeeId === data?.id);
  return myCrewMembership?.employeeLevel as EmployeeLevelId || EmployeeLevelId.Worker;
};

export const hasEmployeeLevelSupervisorOnAnyCrew = (userProfile: UserProfile | undefined): boolean => {
  let isSupervisor = false;

  userProfile?.crews?.map((member: any) => {
    member.crewMembers.map((crew: any) => {
      if (crew.employeeId === userProfile.id && crew.employeeLevel === EmployeeLevelId.Supervisor) {
        isSupervisor = true;
      }
    });
  });

  return isSupervisor;
};

export const canChangeOwnEmployeeLevel = (userProfile: UserProfile | undefined): boolean => {
  return isUserSupervisor(userProfile) || isUserReliefSupervisor(userProfile);
};

export const isUserCrewMember = (crewMembers: CrewMember[], employeeId: any): boolean => {
  let isCrewMember = false;

  crewMembers?.map((crew: any) => {
    if (crew.employeeId === employeeId) {
      isCrewMember = true;
    }
  });

  return isCrewMember;
};

export const isUserWorkcardOwner = (crews: any, workcard: Workcard): boolean => {
  let isUserWorkcardOwner = false;

  crews?.map((crew: Crew) => {
    crew.crewMembers.map((crewMember: CrewMember) => {
      workcard.employees.map((employee: Employee) => {
        if (crewMember.employeeId === employee.id && crew.id === workcard.crewId) {
          isUserWorkcardOwner = true;
        }
      });
    });
  });

  return isUserWorkcardOwner;
};

const isUserSupervisor = (userProfile: UserProfile | undefined): boolean => {
  return userProfile?.role === RoleId.Supervisor;
};

const isUserReliefSupervisor = (userProfile: UserProfile | undefined): boolean => {
  return userProfile?.role === RoleId.ReliefSupervisor;
};
