import { IonContent, IonPage, useIonModal } from '@ionic/react';
import { motion } from 'framer-motion';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import type { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppSelector } from '../../../../hooks/hooks';
import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import type { RootState } from '../../../../store/store';
import { getDate, getTime } from '../../../../utils';
import { WorkcardApprovalModal } from '../WorkcardApprovalModal/WorkcardApprovalModal';

export const WorkcardApprovalButton: React.FC<{
  ref: any;
  handleSubmit: any;
  handleApproval?: any;
  approvalStatus?: any;
  isApprovalButton: boolean;
  isInspectionButton?: boolean;
  isDisabled?: boolean;
  userType?: string;
  colorButton?: string;
}> = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const { data: dataProfile } = useGetMyProfileQuery();

  useImperativeHandle(ref, () => ({
    openModal() {
      handleOpenModal();
    },

    closeModal() {
      handleCloseModal();
    },

    approvalConfirmed() {
      handleApprovalConfirmed();
    },
  }));

  const [isApprovalConfirmed, setIsApprovalConfirmed] = useState(false);
  const [canApprove, setCanApprove] = useState(false);

  const [present, dismiss] = useIonModal(WorkcardApprovalModal, {
    isSupervisor: props.userType,
    isApprovalConfirmed: isApprovalConfirmed,
    onDismiss: (data: any, role: string) => dismiss(data, role),
    onApprove: () => approve(),
  });

  const approve = () => {
    props.handleApproval(onSubmit);
  };

  const onSubmit = (data: any) => {
    console.log(data);
    handleOpenModal();
  };

  const handleCloseModal = () => {
    dismiss();
  };

  const handleApprovalConfirmed = () => {
    setIsApprovalConfirmed(true);

    setTimeout(() => {
      setIsApprovalConfirmed(false);
    }, 3000);
  };

  const handleOpenModal = () => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      present({
        onWillDismiss: (ev: CustomEvent) => {
          // if (ev.detail.role === 'confirm') {
          //   setMessage(`Hello, ${ev.detail.data}!`);
          // }
        },
        cssClass: 'approval-modal',
      });
    } else {
      present({
        onWillDismiss: (ev: CustomEvent) => {
          if (ev.detail.data?.isApproved) {
            //
          }
        },
        cssClass: 'approval-modal',
      });
    }
  };

  useEffect(() => {
    if (props.userType?.toLowerCase() === 'worker') {
      const isUserOwner: boolean = isUserWorkcardOwner(dataProfile?.crews, selectedWorkcard!);
      setCanApprove(isUserOwner);
    } else {
      const isSupervisor: boolean = hasEmployeeLevelSupervisorOnAnyCrew(dataProfile);

      setCanApprove(isSupervisor);
    }
  }, [dataProfile]);

  return (
    <>
      {!props.approvalStatus && canApprove && (
        <button
          onClick={() => props.handleSubmit(onSubmit)}
          className={`
            ${
              props.isApprovalButton
                ? `h-[135px] w-[135px]  text-center inline-flex justify-center items-center rounded-full flex-col text-base font-medium text-white shadow-2xl ${
                    `bg-gradient-to-r from-workcard-${props.colorButton}-primary to-workcard-${props.colorButton}-secondary border-solid border border-workcard-${props.colorButton}-title  border-[4px] drop-shadow-glow` ||
                    'bg-blue-500'
                  } `
                : 'inline-flex justify-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700'
            } ${(props.isDisabled || !isOnline) && 'pointer-events-none'}
          `}
        >
          {!props?.isInspectionButton && (
            <i className="fa-solid fa-circle-check block text-[55px] mt-0 mb-1 text-white"></i>
          )}

          <div className="uppercase font-bold text-[14px] text-white">
            {props?.isInspectionButton
              ? t('FEATURES.WORKCARD.WORKCARD_APPROVAL_BUTTON.CLICK_HERE_TO_SIGN')
              : t('FEATURES.WORKCARD.WORKCARD_APPROVAL_BUTTON.SUBMIT')}
          </div>
        </button>
      )}

      {props.approvalStatus && !props.isApprovalButton && canApprove && (
        <p className="inline-flex text-sm text-gray-900 dark:text-quartery-default mr-1 mt-2">
          <span className="inline-flex items-center rounded-md bg-green-100 border border-green-600 mr-1 -mt-1 px-2.5 py-0.5 text-sm font-medium text-green-800">
            <span className="inline">{t('FEATURES.WORKCARD.WORKCARD_APPROVAL_BUTTON.APPROVED')}</span>
            <i className="fa-solid fa-circle-check text-green-700 text-[16px] ml-2"></i>
          </span>
        </p>
      )}

      {props.approvalStatus && props.isApprovalButton && (
        <div
          className={`
           ${
             props.isApprovalButton
               ? `h-[135px] w-[147px]  text-center inline-flex items-center rounded-3xl flex-col text-base font-medium border-solid  shadow-2xl ${
                   `border-[2px] border-workcard-${props.colorButton}-primary  text-workcard-${props.colorButton}-primary mt-8` ||
                   'bg-blue-500'
                 } `
               : 'inline-flex justify-center py-2 px-4 ml-[20px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700'
           } ${(props.isDisabled || !isOnline) && 'pointer-events-none'}
           `}
        >
          <div className="bg-dark-secondary mt-[-40px] mb-2 px-2">
            <i className="fa-solid fa-circle-check block text-[68px] "></i>
          </div>

          <div className="uppercase font-semibold text-[19px]">
            {t('FEATURES.WORKCARD.WORKCARD_APPROVAL_BUTTON.APPROVED')}
          </div>

          <p className="uppercase text-[12px] mt-[-3px]">
            {props.userType?.toLowerCase() === 'worker'
              ? t('FEATURES.WORKCARD.WORKCARD_APPROVAL_BUTTON.BY_THE_WORKER')
              : t('FEATURES.WORKCARD.WORKCARD_APPROVAL_BUTTON.BY_THE_SUPERVISOR')}
          </p>

          <p className="text-[11px] mt-[1px] leading-[14px] h-[32px]">{props.approvalStatus.signedBy}</p>

          <p className="text-[13px] mt-[-4px] ">
            {getDate(props.approvalStatus.signedOn)} {getTime(props.approvalStatus.signedOn)}
          </p>
        </div>
      )}

      {!props.approvalStatus && props.isApprovalButton && !canApprove && (
        <button
          className={
            'pointer-events-none h-[135px] w-[135px] text-center inline-flex justify-center items-center rounded-full border bg-transparent text-base font-medium text-[#646464] border-dashed'
          }
        >
          <div>
            <i className="fa-solid fa-circle-check block text-[55px] mt-0 mb-1 text-[#646464]"></i>
            <div className="uppercase font-bold leading-[16px] text-[14px]">
              {t('FEATURES.WORKCARD.WORKCARD_APPROVAL_BUTTON.PENDING')}
            </div>
          </div>
        </button>
      )}

      {!props.approvalStatus && !props.isApprovalButton && !canApprove && (
        <button
          className={
            'pointer-events-none px-3 text-center inline-flex items-center rounded-md border bg-yellow-500 py-2 text-sm font-medium border-solid text-white shadow-2xl'
          }
        >
          {t('FEATURES.WORKCARD.WORKCARD_APPROVAL_BUTTON.PENDING')}
        </button>
      )}
    </>
  );
});
