import { Disclosure, Switch, Transition, Listbox } from '@headlessui/react';
import { ChevronRightIcon, CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { IonContent, IonPage, useIonModal } from '@ionic/react';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import type { FocusEvent } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { SaveIndicator } from '../../../../../../components/SaveIndicator/SaveIndicator';
import { SpeechToText } from '../../../../../../components/SpeechToText/SpeechToText';
import { InspectionQuestionsStatus } from '../../../../../../enums/status.enum';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import type { Decision, Inspection } from '../../../../../../models';
import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../../../services/common/profile.utils';
import { classNames, resizeTextarea, scrollToErrorField } from '../../../../../../services/common/workcard.utils';
import {
  useGetDecisionQuery,
  usePostDecisionSignatureMutation,
  useUpdateDecisionMutation,
} from '../../../../../../services/data/decision.service';
import { useGetMyProfileQuery } from '../../../../../../services/data/profile.service';
import {
  useGetTasksByWorkcardIdQuery,
  useGetWorkcardInspectionSectionQuery,
  useGetWorkcardStatusByIdQuery,
  useUpdateWorkcardInpsectionSectionMutation,
} from '../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../store/store';
import { setLastUpdateinfo } from '../../../../../Settings/SettingsSlice';
import { MenuContext } from '../../../../WorkcardContext';
import { WorkcardApprovalModal } from '../../../WorkcardApprovalModal/WorkcardApprovalModal';

export const WorkcardInspectionQuestions: React.FC<unknown> = () => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { selectedMenu, setselectedMenu } = useContext(MenuContext);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const [selectedStatus, setSelectedStatus] = useState(InspectionQuestionsStatus.pending);
  const [selectedIsOpenPanel, setSelectedIsOpenPanel] = useState(false);
  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);
  const [taskPerformedSaved, setTaskPerformedSaved] = useState(false);
  const [isWorkerObservedDuringTaskSaved, setIsWorkerObservedDuringTaskSaved] = useState(false);
  const [isTaskPerformedSecurilySaved, setIsTaskPerformedSecurilySaved] = useState(false);
  const [feedBackOnTaskNotPerformedSecurelySaved, setFeedBackOnTaskNotPerformedSecurelySaved] = useState(false);

  const [isSupervisor, setIsSupervisor] = useState(false);
  const [lastUpdate, setLastUpdate] = useState('');
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [isUserOwner, setIsUserOwner] = useState(false);

  const mountedRef = useRef(true);

  const [listeningValues, setListeningValues]: any = useState({
    feedBackOnTaskNotPerformedSecurely: 0,
    decisionDiscussionOnTaskToPerform: 0,
    specialConditions: 0,
    actionsTaken: 0,
    personContacted: 0,
  });

  const dispatch = useAppDispatch();

  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const { refetch: refetchWorkcardStatus } = useGetWorkcardStatusByIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const [present, dismiss] = useIonModal(WorkcardApprovalModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    clearErrors,
  } = useForm();

  const workerApprovalButton: any = React.useRef(null);
  const supervisorApprovalButton: any = React.useRef(null);
  const [isSupervisorApproval, setIsSuperviorApproval] = useState(false);

  const { data: dataTasks, error: errorTasks } = useGetTasksByWorkcardIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    lang: lang,
  });

  const { data, error, isLoading, refetch } = useGetWorkcardInspectionSectionQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const { data: myProfileData } = useGetMyProfileQuery();

  const [updateInspectionSection, response] = useUpdateWorkcardInpsectionSectionMutation();

  const handleResizeTextarea = (field: string) => {
    console.log('field', field);

    resizeTextarea(field);

    const textareaVal: any = document.querySelector(`[data-question=${field}]`);
    setValue(field, textareaVal && textareaVal.value);

    setTimeout(() => {
      trigger(field);
    }, 300);
  };

  const handleListeningStart = (e: any) => {
    const newListeningValues: any = {};
    for (const key in listeningValues) {
      if (key === e) {
        newListeningValues[key] = 0;
      } else {
        newListeningValues[key] = ++listeningValues[key];
      }
    }
    setListeningValues(newListeningValues);
  };

  const handleListeningEnd = (e: any) => {
    let payload: any = {};

    payload = {
      [e.questionId]: e.transcript,
    };

    if (data && data[e.questionId as keyof Inspection]) {
      payload[e.questionId] = data[e.questionId as keyof Inspection] + ' ' + payload[e.questionId];
    }

    try {
      updateInspectionSection({ siteId: selectedSiteId, workcardId: +id, payload })
        .unwrap()
        .then(() => {
          if (e.questionId === 'taskPerformed') {
            setTaskPerformedSaved(true);
            setTimeout(() => {
              setTaskPerformedSaved(false);
            }, 2000);
          } else if (e.questionId === 'feedBackOnTaskNotPerformedSecurely') {
            setFeedBackOnTaskNotPerformedSecurelySaved(true);
            setTimeout(() => {
              setFeedBackOnTaskNotPerformedSecurelySaved(false);
            }, 2000);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const updateTask = async (task: any) => {
    const payload: any = {
      workcardTaskId: task?.id,
    };

    await updateInspectionSection({ siteId: selectedSiteId, workcardId: +id, payload }).unwrap();
    setSelectedTask(task);
  };

  const handleTranscriptEnd = (e: any) => {
    let value = e.transcript;

    if (data && data[e.questionId as keyof Inspection]) {
      value = data[e.questionId as keyof Inspection] + ' ' + e.transcript;
    }

    setValue(e.questionId, value, { shouldValidate: false });
  };

  const handleRadioChange = (ev: any, name: string, val = true) => {
    trigger(name);

    try {
      const payload = { [name]: val };

      updateInspectionSection({ siteId: selectedSiteId, workcardId: +id, payload });

      if (name === 'isWorkerObservedDuringTask') {
        if (val === false) {
          updateInspectionSection({
            siteId: selectedSiteId,
            workcardId: +id,
            payload: { taskPerformed: '' },
          });
        }

        setIsWorkerObservedDuringTaskSaved(true);

        setTimeout(() => {
          setIsWorkerObservedDuringTaskSaved(false);
        }, 2000);
      } else if (name === 'isTaskPerformedSecurily') {
        if (val === true) {
          updateInspectionSection({
            siteId: selectedSiteId,
            workcardId: +id,
            payload: { feedBackOnTaskNotPerformedSecurely: '' },
          });
        }

        setIsTaskPerformedSecurilySaved(true);

        setTimeout(() => {
          setIsTaskPerformedSecurilySaved(false);
        }, 2000);
      }
    } catch (error) {
      //
    }
  };

  const handleInputOnBlur = async (e: FocusEvent<any>) => {
    const inputVal: string = e.target.value;
    const questionProp: any = e?.target?.getAttribute('data-question');
    const questionVal: any = data?.[questionProp as keyof Inspection] || '';
    let payload: any = {};

    if (inputVal.trim() !== questionVal.trim()) {
      payload = {
        [questionProp]: inputVal.trim(),
      };

      try {
        if (questionProp === 'taskPerformed') {
          await updateInspectionSection({ siteId: selectedSiteId, workcardId: +id, payload });
          setTaskPerformedSaved(true);

          refetch();

          setTimeout(() => {
            setTaskPerformedSaved(false);
          }, 2000);
        } else if (questionProp === 'feedBackOnTaskNotPerformedSecurely') {
          await updateInspectionSection({ siteId: selectedSiteId, workcardId: +id, payload }).unwrap();

          setFeedBackOnTaskNotPerformedSecurelySaved(true);
          setTimeout(() => {
            setFeedBackOnTaskNotPerformedSecurelySaved(false);
          }, 2000);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  useEffect(() => {
    if (myProfileData) {
      setIsSupervisor(hasEmployeeLevelSupervisorOnAnyCrew(myProfileData));
      setIsUserOwner(isUserWorkcardOwner(myProfileData?.crews, selectedWorkcard!));
    }
  }, [myProfileData]);

  useEffect(() => {
    if (data) {
      setLastUpdate(data.lastReadingDate!);
      dispatch(setLastUpdateinfo(data.lastReadingDate!));

      setValue('taskPerformed', data.taskPerformed || '', {
        shouldValidate: false,
      });
      setValue('feedBackOnTaskNotPerformedSecurely', data.feedBackOnTaskNotPerformedSecurely || '', {
        shouldValidate: false,
      });

      resizeTextarea('taskPerformed');
      resizeTextarea('feedBackOnTaskNotPerformedSecurely');
    }

    if (
      data?.isWorkerObservedDuringTask === false ||
      (data?.isWorkerObservedDuringTask === true &&
        data?.taskPerformed &&
        data?.isTaskPerformedSecurily !== null &&
        data?.feedBackOnTaskNotPerformedSecurely)
    ) {
      setSelectedIsOpenPanel(isSupervisor ? true : false);

      if (data?.isWorkerObservedDuringTask === false) {
        setSelectedStatus(InspectionQuestionsStatus.check_warning);
      } else {
        setSelectedStatus(
          data?.isTaskPerformedSecurily === true ? InspectionQuestionsStatus.check : InspectionQuestionsStatus.warning
        );
      }
    } else {
      setSelectedIsOpenPanel(isSupervisor ? false : true);
      setSelectedStatus(InspectionQuestionsStatus.pending);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  return (
    <>
      <div
        className={`-mx-2 lg:mx-[20px] mt-2 lg:mt-6 border-[2px] 
        ${selectedStatus === InspectionQuestionsStatus.check && 'border-green-600'} 
        ${selectedStatus === InspectionQuestionsStatus.warning && 'border-workcard-inspection-primary'} ${
          selectedStatus === InspectionQuestionsStatus.pending && 'border-gray-400'
        }
        ${
          selectedStatus === InspectionQuestionsStatus.check_warning && 'border-workcard-execution-primary'
        }  bg-dark-secondary mb-6 rounded-md`}
      >
        <Disclosure defaultOpen={!selectedIsOpenPanel} key={`panel-${selectedIsOpenPanel}`}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`w-full 'py-0 pb-[0px] rounded-2xl ${open ? 'rounded-t-xl' : 'rounded-xl'}`}
              >
                <dt className={`text-lg ml-2 lg:ml-0 text-black  font-semibold flex justify-between`}>
                  <div>
                    <span
                      className={`uppercase ${selectedStatus === InspectionQuestionsStatus.check && 'text-green-600'} ${
                        selectedStatus === InspectionQuestionsStatus.check_warning && 'text-workcard-execution-primary'
                      } ${selectedStatus === InspectionQuestionsStatus.warning && 'text-workcard-inspection-primary'} ${
                        selectedStatus === InspectionQuestionsStatus.pending && 'text-gray-400'
                      } font-semibold text-[12px] relative ml-[60px] z-10`}
                    >
                      <span className="absolute left-[-55px] top-[-15px]">
                        <span className=" inline-block text-center">
                          {selectedStatus === InspectionQuestionsStatus.warning && (
                            <i className="fa-solid fa-circle-exclamation text-[45px]"></i>
                          )}
                          {selectedStatus === InspectionQuestionsStatus.pending && (
                            <i className="fa-solid fa-circle-question text-[45px]"></i>
                          )}
                          {(selectedStatus === InspectionQuestionsStatus.check ||
                            selectedStatus === InspectionQuestionsStatus.check_warning) && (
                            <i className="fa-solid fa-circle-check text-[45px]"></i>
                          )}
                        </span>
                      </span>
                      {t('FEATURES.WORKCARD.WORKCARD_TASKS.SUPERVISOR_OBSERVATION')}
                    </span>
                  </div>
                  <span className="mr-2 inline-block">
                    <span className=" text-[11px] text-white">
                      {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.SHOW_ALL')}
                    </span>
                    <ChevronRightIcon
                      className={`${open ? 'rotate-90 transform' : ''}  w-[25px]  ml-auto inline-block text-white`}
                    />
                  </span>
                </dt>
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel static className={`text-gray-500  rounded-b-xl  pt-4`}>
                  <div className="items-center xl:px-4 mx-auto">
                    <div className="md:mx-4  mx-2 pb-4 mb-6 mt-0 lg:grid lg:grid-cols-2 justify-center ">
                      <div className="relative mt-1 px-2 grid grid-cols-6 lg:gap-4 lg:px-4 mb-2 ">
                        <div className="absolute w-[100px] left-3 bottom-0">
                          <SaveIndicator isSaved={isWorkerObservedDuringTaskSaved}></SaveIndicator>
                        </div>
                        <dt className="leading-[1.1] font-bold col-span-6 text-[14px]  text-gray-700 dark:text-quartery-default text-center">
                          {t('FEATURES.WORKCARD.WORKCARD_DECISION.OBSERVED_WORKER_DURING_TASK')} :
                          {isSupervisor && errors[`isWorkerObservedDuringTask`]?.type === 'required' && (
                            <div className="absolute bottom-[-20px] text-[13px] text-red-600" role="alert">
                              <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                              <span className="font-semibold ">
                                {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                              </span>
                            </div>
                          )}
                        </dt>
                        <dd className={` col-span-6 mt-2 text-lg text-gray-900 lg:mt-0`}>
                          <ul
                            data-question="isWorkerObservedDuringTask"
                            className={`${
                              !isSupervisor && 'invisible absolute overflow-hidden h-0'
                            }  w-full gap-4 flex lg:justify-end mb-2 mt-2 lg:mt-0`}
                          >
                            <li className=" w-[50%] text-center md:text-right">
                              <input
                                {...register(`isWorkerObservedDuringTask`, { required: true })}
                                type="radio"
                                id="yes_1"
                                name="checklist-1"
                                value="yes"
                                className="hidden peer"
                                onChange={(ev) => handleRadioChange(ev, 'isWorkerObservedDuringTask', true)}
                                checked={data?.isWorkerObservedDuringTask}
                              />
                              <label
                                htmlFor="yes_1"
                                className={`cursor-pointer inline-flex justify-between items-center  text-white bg-black border-gray-500 rounded-2xl border cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-inspection-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-inspection-primary w-[105px] h-[75px] peer-checked:arrow-down-inspection relative ml-8`}
                              >
                                <span className="w-full text-[23px] font-extrabold text-center mt-1.5">
                                  {t('FEATURES.WORKCARD.YES')}
                                </span>
                              </label>
                            </li>
                            <li className="  w-[50%] text-center md:text-left">
                              <input
                                {...register(`isWorkerObservedDuringTask`, { required: true })}
                                type="radio"
                                id="no_1"
                                name="checklist-1"
                                value="no"
                                className="hidden peer"
                                onChange={(ev) => handleRadioChange(ev, 'isWorkerObservedDuringTask', false)}
                                checked={data?.isWorkerObservedDuringTask === false}
                              />
                              <label
                                htmlFor="no_1"
                                className={`cursor-pointer inline-flex justify-between items-center  text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-inspection-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-inspection-primary w-[105px] h-[75px] peer-checked:arrow-down-inspection relative mr-8`}
                              >
                                <span className="w-full text-[23px] font-extrabold text-center  mt-1.5">
                                  {t('FEATURES.WORKCARD.NO')}
                                </span>
                              </label>
                            </li>
                          </ul>
                          {!isSupervisor && (
                            <div
                              className={`${
                                data?.isWorkerObservedDuringTask === null ||
                                data?.isWorkerObservedDuringTask === undefined
                                  ? 'text-red-700'
                                  : 'text-gray-400'
                              } mt-[0px]`}
                            >
                              {data &&
                              data.isWorkerObservedDuringTask !== null &&
                              data.isWorkerObservedDuringTask !== undefined ? (
                                data.isWorkerObservedDuringTask === true ? (
                                  <>
                                    <span className="text-[#646464] text-[14px]">
                                      {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                    </span>{' '}
                                    <span className="text-white text-[16px] font-bold">
                                      {t('FEATURES.WORKCARD.YES')}
                                    </span>
                                    {data.isWorkerObservedDuringTask === true && (
                                      <div>
                                        <span className="text-[#646464] text-[14px]">Task:</span>{' '}
                                        <span className="text-white text-[16px] font-bold">
                                          {selectedTask?.name || 'N/A'}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <span className="text-[#646464] text-[14px]">
                                      {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                    </span>{' '}
                                    <span className="text-white text-[16px] font-bold">
                                      {t('FEATURES.WORKCARD.NO')}
                                    </span>
                                  </>
                                )
                              ) : (
                                <div>
                                  <i
                                    className={`fa-solid fa-triangle-exclamation ${
                                      errors[`isWorkerObservedDuringTask`]?.type === 'required'
                                        ? 'text-red-600'
                                        : 'text-[#646464]'
                                    }   pr-1  pb-[6px]`}
                                  ></i>
                                  <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                    {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </dd>
                      </div>

                      {data?.isWorkerObservedDuringTask === true && (
                        <div className={` relative px-2 py-2 lg:gap-4 lg:px-4 mr-auto lg:pl-0 mt-0`}>
                          <dt className="leading-[1.1] text-[14px]  text-gray-700 dark:text-quartery-default font-bold text-center">
                            {t('FEATURES.WORKCARD.WORKCARD_DECISION.TASK_PERFORMED')}
                          </dt>
                          <dd
                            className={`${
                              !isSupervisor && 'invisible h-0'
                            } mt-1 text-sm text-gray-900 lg:col-span-2 lg:mt-5`}
                          >
                            <div className="mt-0 flex rounded-md shadow-sm justify-center">
                              <textarea
                                {...register(`taskPerformed`, { required: true })}
                                placeholder=""
                                onBlur={handleInputOnBlur}
                                onChange={() => {
                                  handleResizeTextarea('taskPerformed');
                                }}
                                data-question="taskPerformed"
                                className={`focus:outline-none text-black bg-white dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-l-xl border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker h-[50px] mt-2 max-w-[350px] lg:w-[220px]`}
                              ></textarea>
                              <SpeechToText
                                class="mt-[8px] h-[50px] border-l border-black"
                                stopListening={listeningValues.taskPerformed}
                                key="taskPerformed"
                                questionId="taskPerformed"
                                onListeningStart={handleListeningStart}
                                onListeningEnd={handleListeningEnd}
                                onTranscriptEnd={handleTranscriptEnd}
                              ></SpeechToText>
                            </div>
                            {errors[`taskPerformed`]?.type === 'required' && (
                              <div className="absolute mt-[4px] text-[13px] text-red-600" role="alert">
                                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                <span className="font-semibold ">
                                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                                </span>
                              </div>
                            )}
                            <SaveIndicator isSaved={taskPerformedSaved}></SaveIndicator>
                          </dd>
                          <dd className="mt-3 mb-[-10px]">
                            {!isSupervisor && (
                              <div className={`text-[#646464] mt-[-8px] mb-2`}>
                                {data && data.taskPerformed ? (
                                  <span>{data.taskPerformed}</span>
                                ) : (
                                  <div>
                                    <i
                                      className={`fa-solid fa-triangle-exclamation ${
                                        errors[`taskPerformed`]?.type === 'required' ? 'text-red-600' : 'text-[#646464]'
                                      }   pr-1  pb-[6px]`}
                                    ></i>
                                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </dd>
                        </div>
                      )}

                      {data?.isWorkerObservedDuringTask && (
                        <div
                          className={`relative ${
                            isSupervisor ? 'mt-10' : 'mt-8'
                          } px-2 grid grid-cols-6 lg:gap-4 lg:px-4 mb-2  `}
                        >
                          <div className="absolute w-[100px] left-3 bottom-1">
                            <SaveIndicator isSaved={isTaskPerformedSecurilySaved}></SaveIndicator>
                          </div>
                          <dt className="leading-[1.1] col-span-6 text-[14px]  text-gray-700 dark:text-quartery-default font-bold text-center">
                            {t('FEATURES.WORKCARD.WORKCARD_DECISION.TASK_PERFORM_SECURELY_EFFICIENTLY')}
                            {isSupervisor && errors[`isTaskPerformedSecurily`]?.type === 'required' && (
                              <div className="absolute mt-[2px] text-[13px] text-red-600" role="alert">
                                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                <span className="font-semibold ">
                                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                                </span>
                              </div>
                            )}
                          </dt>
                          <dd className={` col-span-6 mt-2 text-lg text-gray-900 lg:mt-0`}>
                            <ul
                              data-question="isTaskPerformedSecurily"
                              className={`${
                                !isSupervisor && 'invisible absolute overflow-hidden h-0'
                              } flex gap-4 w-full mb-2 mt-2 lg:mt-0`}
                            >
                              <li className=" w-[50%] text-center md:text-right">
                                <input
                                  {...register(`isTaskPerformedSecurily`, { required: true })}
                                  type="radio"
                                  id="yes_securely"
                                  name="isTaskPerformedSecurily"
                                  value="yes"
                                  className="hidden peer"
                                  onChange={(ev) => handleRadioChange(ev, 'isTaskPerformedSecurily', true)}
                                  checked={data?.isTaskPerformedSecurily}
                                />
                                <label
                                  htmlFor="yes_securely"
                                  className={`cursor-pointer inline-flex justify-between items-center  text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-inspection-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-inspection-primary w-[105px] h-[75px] peer-checked:arrow-down-inspection relative ml-8`}
                                >
                                  <span className="w-full text-[23px] font-extrabold text-center  mt-1.5">
                                    {t('FEATURES.WORKCARD.YES')}
                                  </span>
                                </label>
                              </li>
                              <li className="  w-[50%]  text-center md:text-left">
                                <input
                                  {...register(`isTaskPerformedSecurily`, { required: true })}
                                  type="radio"
                                  id="no_securely"
                                  name="isTaskPerformedSecurily"
                                  value="no"
                                  className="hidden peer"
                                  onChange={(ev) => handleRadioChange(ev, 'isTaskPerformedSecurily', false)}
                                  checked={data?.isTaskPerformedSecurily === false}
                                />
                                <label
                                  htmlFor="no_securely"
                                  className={`cursor-pointer inline-flex justify-between items-center text-white  rounded-2xl border bg-black border-gray-500 cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-inspection-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-inspection-primary w-[105px] h-[75px] peer-checked:arrow-down-inspection relative mr-8`}
                                >
                                  <span className="w-full text-[23px] font-extrabold text-center  mt-1.5">
                                    {t('FEATURES.WORKCARD.NO')}
                                  </span>
                                </label>
                              </li>
                            </ul>
                            {!isSupervisor && (
                              <div
                                className={`${
                                  data?.isTaskPerformedSecurily === null || data?.isTaskPerformedSecurily === undefined
                                    ? 'text-red-700'
                                    : 'text-gray-400'
                                } mt-[4px] mb-2`}
                              >
                                {data &&
                                data.isTaskPerformedSecurily !== null &&
                                data.isTaskPerformedSecurily !== undefined ? (
                                  data.isTaskPerformedSecurily === true ? (
                                    <>
                                      <span className="text-[#646464] text-[14px]">
                                        {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                      </span>{' '}
                                      <span className="text-white text-[16px] font-bold">
                                        {t('FEATURES.WORKCARD.YES')}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="text-[#646464] text-[14px]">
                                        {t('LABELS.SUPERVISOR')} {t('LABELS.SAID')}:
                                      </span>{' '}
                                      <span className="text-white text-[16px] font-bold">
                                        {t('FEATURES.WORKCARD.NO')}
                                      </span>
                                    </>
                                  )
                                ) : (
                                  <div>
                                    <i
                                      className={`fa-solid fa-triangle-exclamation ${
                                        errors[`isTaskPerformedSecurily`]?.type === 'required'
                                          ? 'text-red-600'
                                          : 'text-[#646464]'
                                      }   pr-1  pb-[6px]`}
                                    ></i>
                                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </dd>
                        </div>
                      )}

                      {data?.isWorkerObservedDuringTask && (
                        <div className={` relative px-2 py-2 lg:gap-4 lg:px-4 mr-auto lg:pl-0 lg:mt-8 mt-0`}>
                          <dt className="leading-[1.1] text-[14px]  text-gray-700 dark:text-quartery-default font-bold text-center">
                            {t('FEATURES.WORKCARD.WORKCARD_DECISION.WHY')}
                          </dt>
                          <dd
                            className={`${
                              !isSupervisor && 'invisible h-0'
                            } mt-1 text-sm text-gray-900 lg:col-span-2 lg:mt-5`}
                          >
                            <div className="mt-0 flex rounded-md shadow-sm justify-center">
                              <textarea
                                {...register(`feedBackOnTaskNotPerformedSecurely`, { required: true })}
                                placeholder=""
                                onBlur={handleInputOnBlur}
                                onChange={() => {
                                  handleResizeTextarea('feedBackOnTaskNotPerformedSecurely');
                                }}
                                data-question="feedBackOnTaskNotPerformedSecurely"
                                className={`focus:outline-none text-black bg-white dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-l-xl border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker h-[50px] mt-2 max-w-[350px] lg:w-[220px]`}
                              ></textarea>
                              <SpeechToText
                                class="mt-[8px] h-[50px] border-l border-black"
                                stopListening={listeningValues.feedBackOnTaskNotPerformedSecurely}
                                key="feedBackOnTaskNotPerformedSecurely"
                                questionId="feedBackOnTaskNotPerformedSecurely"
                                onListeningStart={handleListeningStart}
                                onListeningEnd={handleListeningEnd}
                                onTranscriptEnd={handleTranscriptEnd}
                              ></SpeechToText>
                            </div>
                            {errors[`feedBackOnTaskNotPerformedSecurely`]?.type === 'required' && (
                              <div className="absolute mt-[4px] text-[13px] text-red-600" role="alert">
                                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                <span className="font-semibold ">
                                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                                </span>
                              </div>
                            )}
                            <SaveIndicator isSaved={feedBackOnTaskNotPerformedSecurelySaved}></SaveIndicator>
                          </dd>
                          <dd className="mt-3 mb-[-10px]">
                            {!isSupervisor && (
                              <div className={`text-[#646464] mt-[-8px] mb-2`}>
                                {data && data.feedBackOnTaskNotPerformedSecurely ? (
                                  <span>{data.feedBackOnTaskNotPerformedSecurely}</span>
                                ) : (
                                  <div>
                                    <i
                                      className={`fa-solid fa-triangle-exclamation ${
                                        errors[`feedBackOnTaskNotPerformedSecurely`]?.type === 'required'
                                          ? 'text-red-600'
                                          : 'text-[#646464]'
                                      }   pr-1  pb-[6px]`}
                                    ></i>
                                    <span className="text-[13px] text-[#646464] py-1pb-[4.5px]">
                                      {t('FEATURES.WORKCARD.WAITING_FOR_SUPERVISOR_ANSWER')}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </dd>
                        </div>
                      )}
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};
