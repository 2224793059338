import { IonIcon, useIonViewWillEnter } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Loader } from '../../../../components/Loader/Loader';
import { useAppDispatch } from '../../../../hooks/hooks';
import type { WorkcardTask, WorkcardTasks as Tasks } from '../../../../models';
import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import { useGetTasksByWorkcardIdQuery } from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';
import { setLastUpdateinfo } from '../../../Settings/SettingsSlice';
import { MenuContext } from '../../WorkcardContext';
import { WorkcardHeader } from '../WorkcardHeader/WorkcardHeader';

import { TaskContext } from './WorkcardTasksContext';
import { WorkcardInspection } from './components/WorkcardInspection/WorkcardInspection';
import { WorkcardInspectionQuestions } from './components/WorkcardInspectionQuestion/WorkcardInspectionQuestion';
import { WorkcadTaskList } from './components/WorkcardTaskList/WorkcardTaskList';

import './WorkcardTasks.scss';

type SelectTask = (e: React.MouseEvent, taskId: number) => void;

export const WorkcardTasks: React.FC<{ onSelectTask: SelectTask }> = (props) => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);
  const [showTaskDetail, setTaskDetail] = useState<boolean>(false);
  const [selectedTaskId, setTaskId] = useState<number>(-1);
  const [selectedAllTasksCount, setAllTasksCount] = useState<number>(0);
  const [selectedPercentCompletedTask, setPercentCompletedTask] = useState<number>(0);
  const [selectedCompletedTasksCount, setCompletedTasksCount] = useState<number>(0);
  const [selectedTaskMenu, setSelectedTaskMenu] = useState<string>('Inspection');
  const [lastUpdate, setLastUpdate] = useState('');
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);

  const { selectedMenu, setselectedMenu } = useContext(MenuContext);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);
  const selectedWorkcard = useSelector((state: RootState) => state.workcard.selectedWorkcard);
  const selectedWorkcardTask = useSelector((state: RootState) => state.workcard.selectedWorkcardTask);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const mountedRef = useRef(true);

  const { data: dataProfile, refetch: refetchProfile } = useGetMyProfileQuery();

  const { data, error, isLoading, isFetching, refetch } = useGetTasksByWorkcardIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    lang: lang,
  });

  useIonViewWillEnter(() => {
    refetch();
  });

  const handleCloseTaskDetail = (e: React.MouseEvent) => {
    setTaskDetail(false);
    setTaskId(-1);
  };

  const handleSetMenu = (e: React.MouseEvent, value: string) => {
    setSelectedTaskMenu(value);
  };

  const isShowTaskDetail = (e: React.MouseEvent, value: boolean) => {
    setTaskDetail(value);
  };

  const handleSelectTaskId = (e: React.MouseEvent, value: number) => {
    props.onSelectTask(e, value);
    setTaskId(value);
  };

  const setCompletedTasks = (workcardTasks: WorkcardTask[]) => {
    let completedTasks: any = [];

    if (workcardTasks) {
      completedTasks = workcardTasks.filter((task) => task.activityStatus === 'Completed');
    }

    setCompletedTasksCount(completedTasks.length);
  };

  useEffect(() => {
    if (data) {
      setAllTasksCount(data.length);
      setCompletedTasks(data);
    }

    if (data && data.length > 0) {
      setLastUpdate(data[0].lastReadingDate!);
      dispatch(setLastUpdateinfo(data[0].lastReadingDate!));
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const completedTasksRatio = selectedCompletedTasksCount / selectedAllTasksCount;

    setPercentCompletedTask(+completedTasksRatio.toFixed(2) * 100);
  }, [selectedCompletedTasksCount, selectedAllTasksCount]);

  useEffect(() => {
    setIsUserOwner(isUserWorkcardOwner(dataProfile?.crews, selectedWorkcard!));
    setIsSupervisor(hasEmployeeLevelSupervisorOnAnyCrew(dataProfile));
  }, [dataProfile]);

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  return (
    <TaskContext.Provider value={selectedTaskId}>
      {isLoading && <Loader></Loader>}

      {selectedMenu === 'Inspection' && (
        <div className="grid grid-cols-5 gap-0 pt-[7px] lg:pt-0">
          <div className="ml-0 col-span-5 grid grid-cols-5 ">
            <div className="ml-0 col-span-6  relative w-full z-10">
              {isSupervisor && <WorkcardHeader></WorkcardHeader>}
              <div className="border-b border-t border-gray-400 dark:border-none bg-gray-100 dark:bg-dark-tertiary px-4 pt-3 lg:px-6 pl-8 lg:h-[62px]">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-center lg:flex-nowrap">
                  <div className=" lg:ml-4 mt-0.5 flex flex-col ">
                    <h3 className="text-[17px] font-semibold uppercase leading-6 text-gray-900 dark:text-workcard-inspection-title mt-[7px] text-center">
                      {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.INSPECTION_PLANNING')}
                    </h3>
                    <p className="text-[20px] text-gray-900 dark:text-workcard-decision-primary text-center mt-2"></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-top-inspection-plan col-span-6 relative grid grid-cols-6  bg-workcard-inspection-tertiary my-4  mt-[23px] lg:mt-[7px] pt-[24px] pb-[40px] z-20">
              <div className="icon-inspec-plan-section absolute text-center mx-auto w-[53px] h-[53px] left-0 right-0 rounded-full bg-[#c31956] top-[-27px] pt-[8px]">
                <IonIcon size="large" src="./assets/img/inspection-planif-03.svg" />
              </div>

              <div className="col-span-6 grid grid-cols-6 grid-template-rows-inspection grid-rows-[minmax(20px,auto)_minmax(100px,_1fr)] lg:rounded-xl lg:bg-dark-secondary lg:mx-4 lg:mt-3 lg:mb-5 lg:h-[calc(100vh-310px)] overflow-y-auto !gap-y-0">
                {!selectedIsMobileScreen && (
                  <>
                    <div className="col-span-6 z-[1000]">
                      <WorkcardInspectionQuestions></WorkcardInspectionQuestions>
                    </div>
                  </>
                )}

                <div className="col-span-6 lg:col-span-2 ">
                  <WorkcadTaskList
                    showTaskDetail={showTaskDetail}
                    toggleState={(e: React.MouseEvent, value: boolean) => isShowTaskDetail(e, value)}
                    selectTaskId={(e: React.MouseEvent, value: number) => handleSelectTaskId(e, value)}
                  />
                </div>
                <div className="hidden lg:block lg:col-span-4 ml-0 relative ">
                  {showTaskDetail && selectedTaskId !== 0 && (
                    <div className="hidden lg:block overflow-y-auto lg:h-auto">
                      <div className="relative w-full z-10 mt-2">
                        <h2 className="w-[80%] block m-auto left-o right-0 relative text-center rounded-lg pt-2 font-bold text-gray-100 text-[18px] mb-1.5">
                          {selectedWorkcardTask?.name}
                          {showTaskDetail && (
                            <span
                              onClick={(e) => handleCloseTaskDetail(e)}
                              className=" bg-gray-300  dark:text-black text-black border-black border-[2px] inline-block h-[28px] w-[28px] font-medium text-sm rounded-full  cursor-pointer ml-3 relative top-[-4px]"
                            >
                              <i className="fa-solid fa-xmark relative top-[3px] left-[-0.5px]"></i>
                            </span>
                          )}
                        </h2>
                        <p className="m-auto text-center text-gray-500 leading-[12px] text-[12px] mt-0">
                          <i className="fa-solid fa-location-dot mr-2"></i>
                          <span>{selectedWorkcardTask?.location}</span>
                        </p>
                      </div>

                      <WorkcardInspection />
                    </div>
                  )}
                  {!showTaskDetail && (
                    <>
                      <div className="mb-4 mt-[30px] mx-4 hidden lg:block">
                        <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg mx-4 my-3 p-12 text-center hover:border-gray-400 h-auto">
                          <i className="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
                          <p className="mt-6 block text-base font-medium text-gray-500">
                            {t('FEATURES.WORKCARD.WORKCARD_TASKS.SELECT_TASK_VIEW_DETAILS')}
                          </p>
                          <p className="mt-2 block text-sm font-medium text-gray-500"> &nbsp; </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </TaskContext.Provider>
  );
};
