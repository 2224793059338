import { useIonRouter } from '@ionic/react';
import React, { useState, useEffect, Fragment, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ConnectivityStatus } from '../../../components/ConnectivityStatus/ConnectivityStatus';
import { EnvName } from '../../../components/EnvName/EnvName';
import { LastUpdateInfo } from '../../../components/LastUpdateInfo/LastUpdateInfo';
import { OfflineBanner } from '../../../components/OfflineBanner/OfflineBanner';
import TopMenuModal from '../../../components/TopMenuModal/TopMenuModal';
import { UserProfileButton } from '../../../components/UserProfileButton/UserProfileButton';
import { useAppSelector, useAppDispatch } from '../../../hooks/hooks';
import { hasEmployeeLevelSupervisorOnAnyCrew } from '../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../services/data/profile.service';
import type { RootState } from '../../../store/store';

import './Header.scss';

export const Header: React.FC<{ title: string; backBtn?: boolean; hasProfile: boolean }> = (props) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: userProfile } = useGetMyProfileQuery();

  const isSidebarExpanded = useAppSelector((state) => state.settings.isSidebarExpanded);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const handleGoHome = () => {
    router.push('/', 'forward');
  };

  const handleGoBack = () => {
    router.back();
  };

  return (
    <>
      <header
        className={`bg-gradient-to-r lg:bg-gradient-to-b from-black to-dark-tertiary py-2 fixed w-full top-0 z-[100] shadow`}
      >
        <div className="mx-auto px-4 lg:pl-3 lg:pr-4 flex xl:flex xl:items-center xl:justify-between">
          <div className="min-w-0 flex-1">
            <div className="lg:flex justify-between hidden">
              <div className="mt-[0px] text-center hidden lg:flex">
                <UserProfileButton />
              </div>
            </div>
            <div className="flex justify-between lg:hidden">
              {!props.backBtn && (
                <div className="mt-1.5">
                  <i
                    onClick={handleGoHome}
                    className="cursor-pointer fa-solid fa-house-chimney text-[25px] text-gray-300"
                  ></i>
                </div>
              )}
              {props.backBtn && (
                <div className="mt-1.5">
                  <i
                    onClick={handleGoBack}
                    className="cursor-pointer fa-solid fa-circle-left text-[25px] text-gray-300"
                  ></i>
                </div>
              )}
              <div className="mt-[-10px] flex flex-col text-center relative">
                <span className="text-center inline-block items-center px-2  text-xs font-normal text-white">
                  <span
                    className={`text-center w-[150px] py-0.5 inline-block  rounded ${
                      !isOnline ? 'bg-red-600' : 'bg-[#343434]'
                    }`}
                  >
                    {hasEmployeeLevelSupervisorOnAnyCrew(userProfile)
                      ? t('COMPONENTS.USER_PROFILE_BUTTON.SUPERVISOR')
                      : t('COMPONENTS.USER_PROFILE_BUTTON.WORKER')}

                    {!isOnline && ' - ' + t('LABELS.OFFLINE')}
                  </span>
                  <EnvName></EnvName>
                </span>
                <span className="mt-1.5 ml-2 dark:text-quartery-default font-bold">
                  {userProfile && userProfile.name}
                </span>
              </div>
              <div className="mt-1">
                <TopMenuModal></TopMenuModal>
              </div>
            </div>
          </div>

          <span className="hidden lg:inline-block">
            <OfflineBanner></OfflineBanner>
            <LastUpdateInfo isDashboard={true}></LastUpdateInfo>
          </span>
        </div>
      </header>
    </>
  );
};
