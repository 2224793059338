import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { menuController } from '@ionic/core/components';
import {
  useIonViewWillEnter,
  IonSlides,
  IonSlide,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  useIonAlert,
  IonButtons,
  IonButton,
  useIonModal,
} from '@ionic/react';
import { motion, AnimatePresence } from 'framer-motion';
import React, { Fragment, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ControlMeasureModal } from '../../../../../../../../../components/ControlMeasureModal/ControlMeasureModal';
import { RiskModal } from '../../../../../../../../../components/RiskModal/RiskModal';
import { SaveIndicator } from '../../../../../../../../../components/SaveIndicator/SaveIndicator';
import { PlanificationType } from '../../../../../../../../../constants/planification';
import { EmployeeLevelId, InspectionChecklistAnswerType } from '../../../../../../../../../enums';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../hooks/hooks';
import type {
  Plan,
  RiskAssessmentQuestion,
  ControlMeasure,
  RiskAssessmentQuestions,
} from '../../../../../../../../../models';
import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../../../../../../services/common/profile.utils';
import { resizeTextarea } from '../../../../../../../../../services/common/workcard.utils';
import { useGetMyProfileQuery } from '../../../../../../../../../services/data/profile.service';
import {
  useDeleteWorkcardPlanMutation,
  useGetControlMeasureListQuery,
  useGetRiskListQuery,
  useGetWorkcardStatusByIdQuery,
  useGetWorkcardTaskInpsectionQuery,
  useGetWorkcardTaskPlanQuery,
  usePostWorkcardPlanMutation,
  usePostWorkcardTaskInpsectionSignatureMutation,
  useUpdateWorkcardPlanMutation,
  useUpdateWorkcardTaskInpsectionMutation,
  workcardService,
} from '../../../../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../../../../store/store';
import { WorkcardApprovalButton } from '../../../../../../WorkcardApprovalButton/WorkcardApprovalButton';
import { WorkcardHeader } from '../../../../../../WorkcardHeader/WorkcardHeader';
import { TaskContext } from '../../../../../WorkcardTasksContext';

import { WorkcardInspectionChecklistDetail } from './ChecklistDetail/ChecklistDetail';

import './WorkcardInspectionChecklistModal.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InspectionChecklistModal = ({
  workcardId,
  taskId,
  userType,
  onDismiss,
}: {
  workcardId: number;
  taskId: number;
  userType: string;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const { t } = useTranslation();
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);
  const selectedWorkcard = useSelector((state: RootState) => state.workcard.selectedWorkcard);
  const selectedWorkcardTask = useSelector((state: RootState) => state.workcard.selectedWorkcardTask);
  const dispatch = useAppDispatch();

  const [skip, setSkip] = useState(false);
  const [isAnswerSaved, setAnswerSaved] = useState(false);
  const riskAssessmentQuestion = useRef<RiskAssessmentQuestion | null>(null);
  const plan = useRef<Plan | null>(null);
  const slides = useRef<HTMLIonSlidesElement>(null);
  const [selectedPlan, setPlan] = useState<Plan | null>(null);
  const [selectedPlans, setPlans] = useState<Plan[] | null>(null);
  const [isDetailMode, setDetailMode] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedControlMeasure, setControlMeasure] = useState<ControlMeasure | null>(null);
  const [selectedTypeOfRisk, setTypeOfRisk] = useState<ControlMeasure | null>(null);

  const allControlMeasuresValues: any = useState<any>([]);
  const allTypeOfRisksValues: any = useState<any>([]);
  const deleteAllPlanification: any = React.useRef(false);
  const indexSlide: any = React.useRef(0);
  const workerApprovalButton: any = React.useRef(null);
  const supervisorApprovalButton: any = React.useRef(null);
  const [isSupervisorApproval, setIsSuperviorApproval] = useState(false);
  const [isWorker, setIsWorker] = useState<boolean>(false);
  const [isSupervisor, setIsSupervisor] = useState(false);

  const slideOpts = {
    allowTouchMove: false,
  };

  const [presentRiskModal, dismissRiskModal] = useIonModal(RiskModal, {
    workcardId: +workcardId,
    plan: plan,
    onDismiss: (data: string, role: string) => dismissRiskModal(data, role),
  });

  const [presentControlMeasureModal, dismissControlMeasureModal] = useIonModal(ControlMeasureModal, {
    workcardId: +workcardId,
    plan: plan,
    onDismiss: (data: string, role: string) => dismissControlMeasureModal(data, role),
  });

  const [presentAlert, hideAlert] = useIonAlert();

  const { data: myProfileData } = useGetMyProfileQuery();
  const [updateWorkcardPlan, resUpdatePlan] = useUpdateWorkcardPlanMutation();

  const { data, error, isLoading, refetch } = useGetWorkcardTaskInpsectionQuery({
    siteId: selectedSiteId!,
    workcardId: +workcardId,
    taskId: +taskId,
    lang: lang,
  });

  const { refetch: refetchWorkcardStatus } = useGetWorkcardStatusByIdQuery({
    siteId: selectedSiteId!,
    workcardId: +workcardId,
  });

  const [updateTaskInspection, responseUpdateTaskInspection] = useUpdateWorkcardTaskInpsectionMutation();

  const [PostSignature, { error: errorSig, data: res }] = usePostWorkcardTaskInpsectionSignatureMutation();

  const { data: dataPlan, refetch: reftechPlan } = useGetWorkcardTaskPlanQuery({
    siteId: selectedSiteId!,
    workcardId: +workcardId,
    lang: lang,
    taskId: taskId,
  });

  const {
    data: controlMeasures,
    error: errorCM,
    isLoading: isLoadingCM,
  } = useGetControlMeasureListQuery({ siteId: selectedSiteId!, language: lang });
  const {
    data: typeOfRisks,
    error: errorRisk,
    isLoading: isLoadingRisk,
  } = useGetRiskListQuery({ siteId: selectedSiteId!, language: lang });

  const [postWorkcardPlan, resDuplicate] = usePostWorkcardPlanMutation();
  const [deleteWorkcardPlan, resDelete] = useDeleteWorkcardPlanMutation();

  const {
    data: planData,
    error: planError,
    refetch: planRefetch,
  } = useGetWorkcardTaskPlanQuery({
    siteId: selectedSiteId!,
    workcardId: +workcardId,
    taskId: +taskId,
    lang: lang,
  });

  const {
    control,
    clearErrors,
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const handleClodeDetail = async () => {
    setDetailMode(false);
  };

  const handleSelecItem = async (e: any, index: number) => {
    e.preventDefault();

    setDetailMode(false);
    const swiper = await slides?.current?.getSwiper();
    swiper.slideTo(index);
  };

  const handleToggleDetailMode = async (e: any) => {
    setDetailMode(!isDetailMode);
  };

  // We create a plan if:
  // 1) requirePlanOnYes = TRUE and the user's answer is YES
  // OR requirePlanOnNo = TRUE and the user's answer is NO
  // AND a plan is not yet created for this question
  const createPlan = async (userAnswer: boolean) => {
    let isPlanExists = false;

    if (dataPlan && dataPlan.plans) {
      const planExists = dataPlan.plans.find((plan) => plan.questionId === riskAssessmentQuestion.current?.id);
      isPlanExists = planExists !== undefined;
    }

    if (
      ((riskAssessmentQuestion.current?.requirePlanOnYes === true && userAnswer === true) ||
        (riskAssessmentQuestion.current?.requirePlanOnNo === true && userAnswer === false)) &&
      isPlanExists === false
    ) {
      await handleCreateOrDuplicatePlan(null);
    }
  };

  const handleCreateOrDuplicatePlan = async (e: any) => {
    e && e.stopPropagation();

    try {
      const payload = {
        question: riskAssessmentQuestion.current?.question,
        questionId: riskAssessmentQuestion.current?.id,
        employeeId: myProfileData?.id,
        employeLevel: hasEmployeeLevelSupervisorOnAnyCrew(myProfileData) ? EmployeeLevelId.Supervisor : EmployeeLevelId.Worker,
        controlMeasureId: null,
        riskTypeId: null,
        planStatus: 0,
        uniqueStamp: performance.now().toString(),
      };

      await postWorkcardPlan({
        siteId: selectedSiteId,
        workcardId: workcardId,
        taskId: +taskId,
        payload,
      });
    } catch (error) {
      //
    }
  };

  const canDeletePlan = () => {
    let userCanDeletePlan = false;

    if (
      riskAssessmentQuestion.current?.requirePlanOnNo === true &&
      (riskAssessmentQuestion?.current?.workerAnswer?.yesOrNo === false ||
        riskAssessmentQuestion?.current?.supervisorAnswer?.yesOrNo === false ||
        (riskAssessmentQuestion?.current?.workerAnswer === null &&
          riskAssessmentQuestion?.current?.supervisorAnswer === null))
    ) {
      userCanDeletePlan = false;
    } else if (
      riskAssessmentQuestion.current?.requirePlanOnYes === true &&
      (riskAssessmentQuestion?.current?.workerAnswer?.yesOrNo === true ||
        riskAssessmentQuestion?.current?.supervisorAnswer?.yesOrNo === true ||
        (riskAssessmentQuestion?.current?.workerAnswer === null &&
          riskAssessmentQuestion?.current?.supervisorAnswer === null))
    ) {
      userCanDeletePlan = false;
    } else {
      userCanDeletePlan = true;
    }

    return (selectedPlans && selectedPlans.length >= 2) || userCanDeletePlan;
  };

  const canDuplicatePlan = (): boolean => {
    let canDuplicate = true;

    selectedPlans?.map((planification) => {
      if (planification.riskType === null || planification.controlMeasure === null) {
        canDuplicate = false;
      }
    });

    return canDuplicate;
  };

  const handleArrowClicked = async (direction: string) => {
    const swiper = await slides?.current?.getSwiper();
    if (direction === 'next') {
      swiper.slideNext();
    } else if (direction === 'prev') {
      swiper.slidePrev();
    }
  };

  const handleUpdatePlan = async (
    type: number,
    id: any,
    title: any,
    requiresAdditionalDetails?: boolean,
    otherControlMeasure?: string,
    otherRiskType?: string
  ) => {
    try {
      trigger();

      const payload: any = {
        questionId: plan.current?.questionId,
        employeeId: myProfileData?.id,
        employeLevel: hasEmployeeLevelSupervisorOnAnyCrew(myProfileData) ? EmployeeLevelId.Supervisor : EmployeeLevelId.Worker,
        planStatus: 0,
        uniqueStamp: plan.current?.uniqueStamp ? plan.current?.uniqueStamp : performance.now().toString(),
      };

      if (otherControlMeasure) {
        payload.otherControlMeasure = otherControlMeasure;
      }

      if (otherRiskType) {
        payload.otherRiskType = otherRiskType;
      }

      if (PlanificationType.TYPEOFRISK === type) {
        payload.riskTypeId = id;
        payload.riskTypeTitle = title;

        if (!requiresAdditionalDetails) {
          payload.otherRiskType = '';
        }
      } else if (PlanificationType.CONTROLMEASURE === type) {
        payload.controlMeasureId = id;
        payload.controlMeasureTitle = title;

        if (!requiresAdditionalDetails) {
          payload.otherControlMeasure = '';
        }
      }

      await updateWorkcardPlan({
        siteId: selectedSiteId,
        workcardId: workcardId,
        taskId: plan.current?.taskId || taskId,
        planId: plan.current?.id,
        payload,
      });
    } catch (error) {
      //
    }
  };

  const handleInputOnBlur = async (e: any, questionId: number, answerId: number | undefined, uniqueStamp: string) => {
    const inputVal: string = e.target.value;

    try {
      updateInspectionTask(questionId, answerId, undefined, inputVal, uniqueStamp);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleRadioChange = async (
    ev: any,
    questionId: number,
    answerId: number | undefined,
    yesOrno: boolean,
    questionIndex: number,
    isWorker: boolean,
    uniqueStamp: string
  ) => {
    let isDisplayAlert = false;

    const updateInpsectionChecklist = workcardService.util.updateQueryData(
      'getWorkcardTaskInpsection',
      { siteId: selectedSiteId!, workcardId: +workcardId, taskId: +taskId, lang: lang },
      (inspection: RiskAssessmentQuestions) => {
        if (
          inspection &&
          inspection.riskAssessmentQuestions &&
          inspection.riskAssessmentQuestions[questionIndex] &&
          inspection.riskAssessmentQuestions[questionIndex][isWorker ? 'workerAnswer' : 'supervisorAnswer']
        ) {
          inspection.riskAssessmentQuestions[questionIndex][isWorker ? 'workerAnswer' : 'supervisorAnswer'].yesOrNo =
            yesOrno;
        } else {
          inspection.riskAssessmentQuestions[questionIndex][isWorker ? 'workerAnswer' : 'supervisorAnswer'] = {
            id: 0,
            employeeId: 0,
            employeeLevel: 0,
            employeeName: '',
            answer: '',
            yesOrNo: yesOrno,
            uniqueStamp: uniqueStamp,
          };
        }
      }
    );

    dispatch(updateInpsectionChecklist);

    setIsUpdating(true);

    if (
      userType === 'worker' &&
      riskAssessmentQuestion.current?.requirePlanOnYes === true &&
      riskAssessmentQuestion.current?.requirePlanOnNo === false &&
      riskAssessmentQuestion.current?.workerAnswer?.yesOrNo === true &&
      yesOrno === false &&
      (riskAssessmentQuestion.current?.supervisorAnswer?.yesOrNo === false ||
        riskAssessmentQuestion.current?.supervisorAnswer === null)
    ) {
      if (
        (selectedPlans && selectedPlans?.length > 1) ||
        (selectedPlans && selectedPlans?.length === 1 && (selectedPlans[0].controlMeasure || selectedPlans[0].riskType))
      ) {
        isDisplayAlert = true;
      }

      deleteAllPlanification.current = true;
    } else if (
      userType === 'worker' &&
      riskAssessmentQuestion.current?.requirePlanOnYes === false &&
      riskAssessmentQuestion.current?.requirePlanOnNo === true &&
      riskAssessmentQuestion.current?.workerAnswer?.yesOrNo === false &&
      yesOrno === true &&
      (riskAssessmentQuestion.current?.supervisorAnswer?.yesOrNo === true ||
        riskAssessmentQuestion.current?.supervisorAnswer === null)
    ) {
      if (
        (selectedPlans && selectedPlans?.length > 1) ||
        (selectedPlans && selectedPlans?.length === 1 && (selectedPlans[0].controlMeasure || selectedPlans[0].riskType))
      ) {
        isDisplayAlert = true;
      }

      deleteAllPlanification.current = true;
    } else if (
      userType !== 'worker' &&
      riskAssessmentQuestion.current?.requirePlanOnNo === true &&
      riskAssessmentQuestion.current?.requirePlanOnYes === false &&
      riskAssessmentQuestion.current?.supervisorAnswer?.yesOrNo === false &&
      yesOrno === true &&
      (riskAssessmentQuestion.current?.workerAnswer?.yesOrNo === true ||
        riskAssessmentQuestion.current?.workerAnswer === null)
    ) {
      if (
        (selectedPlans && selectedPlans?.length > 1) ||
        (selectedPlans && selectedPlans?.length === 1 && (selectedPlans[0].controlMeasure || selectedPlans[0].riskType))
      ) {
        isDisplayAlert = true;
      }

      deleteAllPlanification.current = true;
    } else if (
      userType !== 'worker' &&
      riskAssessmentQuestion.current?.requirePlanOnNo === false &&
      riskAssessmentQuestion.current?.requirePlanOnYes === true &&
      riskAssessmentQuestion.current?.supervisorAnswer?.yesOrNo === true &&
      yesOrno === false &&
      (riskAssessmentQuestion.current?.workerAnswer?.yesOrNo === false ||
        riskAssessmentQuestion.current?.workerAnswer === null)
    ) {
      if (
        (selectedPlans && selectedPlans?.length > 1) ||
        (selectedPlans && selectedPlans?.length === 1 && (selectedPlans[0].controlMeasure || selectedPlans[0].riskType))
      ) {
        isDisplayAlert = true;
      }

      deleteAllPlanification.current = true;
    }

    if (isDisplayAlert) {
      presentAlert({
        header: t('FEATURES.WORKCARD.WORKCARD_RISK_ASSESSMENT_PLANNING.INSPECTION_CHECKLIST_UPDATE_ANSWER'),
        buttons: [
          {
            text: 'Cancel',
            cssClass: 'alert-button-cancel',
            handler: (e: any) => {
              //
            },
          },
          {
            text: 'Submit',
            cssClass: 'alert-button-confirm',
            handler: async (e: any) => {
              await updateInspectionTask(questionId, answerId, yesOrno, undefined, uniqueStamp);
              await createPlan(yesOrno);
            },
          },
        ],
        inputs: [],
      });
    } else {
      await updateInspectionTask(questionId, answerId, yesOrno, undefined, uniqueStamp);
      await createPlan(yesOrno);
    }
  };

  const updateInspectionTask = async (
    questionId: number,
    answerId: number | undefined,
    yesOrno: boolean | undefined,
    answer: string | undefined,
    uniqueStamp: string
  ) => {
    trigger(`q-${questionId}`);

    const payload: any = {
      responseId: answerId || null,
      employeeLevel: userType === 'worker' ? 0 : 1,
      uniqueStamp: uniqueStamp,
    };

    if (yesOrno !== undefined) {
      payload.yesOrNo = yesOrno;
    }

    if (answer !== undefined) {
      payload.answer = answer;
    }

    try {
      await updateTaskInspection({
        siteId: selectedSiteId,
        workcardId: +workcardId,
        taskId: +taskId,
        questionId: questionId,
        payload,
      });

      setIsUpdating(false);

      if (riskAssessmentQuestion.current?.requirePlanOnNo === true && yesOrno === false) {
        //
      } else if (riskAssessmentQuestion.current?.requirePlanOnYes === true && yesOrno === true) {
        //
      } else {
        const swiper = await slides?.current?.getSwiper();
        setTimeout(() => {
          if (answer === undefined) {
            swiper.slideNext();
          }
        }, 1000);
      }
    } catch (error) {
      setIsUpdating(false);
    }
  };

  const onSubmit = (data: any) => {
    console.log(data);

    if (!isSupervisorApproval && workerApprovalButton && workerApprovalButton.current) {
      workerApprovalButton.current.openModal();
    }

    if (isSupervisorApproval && supervisorApprovalButton && supervisorApprovalButton.current) {
      supervisorApprovalButton.current.openModal();
    }
  };

  const displayPlanIndicator = (item: any) => {
    const planCreated = planData?.plans?.find((plan) => {
      return plan.questionId === item.id;
    });

    return planCreated ? true : false;
  };

  const handleSubmitButton = () => {
    setIsSuperviorApproval(userType === 'worker' ? false : true);
    handleSubmit(onSubmit);
  };

  const handleDeletePlan = async (e: any, plan: Plan) => {
    e.stopPropagation();

    try {
      await deleteWorkcardPlan({
        siteId: selectedSiteId,
        workcardId: +workcardId,
        taskId: +taskId,
        planId: plan.id,
        payload: {
          uniqueStamp: plan.uniqueStamp,
        },
      });
    } catch (error) {
      //
    }
  };

  const handleApproval = () => {
    const payload = {
      employeeLevel: isSupervisorApproval ? 1 : 0,
      signatureCert: '',
    };

    PostSignature({ siteId: selectedSiteId, workcardId: +workcardId, taskId: +taskId, payload });
  };

  const getQuestionPlan = () => {
    plan.current = null;
    setPlan(null);
    setPlans(null);

    if (dataPlan && dataPlan.plans) {
      const plans = dataPlan.plans.filter((questionPlan) => {
        return questionPlan.questionId === riskAssessmentQuestion.current?.id;
      });

      setPlans(plans);
    }
  };

  const handleAlertTypeOfRisks = (ev: any, planif: Plan | null) => {
    if (planif) {
      plan.current = planif;
      setPlan(planif);
      setTypeOfRisk(planif.riskType);
    }

    presentRiskModal({
      onWillDismiss: (ev: CustomEvent) => {
        //
      },
      id: 'planTypeSelectionModal',
      cssClass: 'planTypeSelectionModal',
    });
  };

  const handleAlertControlMeasures = (ev: any, planif: Plan) => {
    if (planif) {
      plan.current = planif;
      setPlan(planif);

      setControlMeasure(planif.controlMeasure);
    }

    presentControlMeasureModal({
      onWillDismiss: (ev: CustomEvent) => {
        //
      },
      id: 'planTypeSelectionModal',
      cssClass: 'planTypeSelectionModal',
    });
  };

  useEffect(() => {
    if (myProfileData) {
      const isUserWorker: boolean = isUserWorkcardOwner(myProfileData?.crews, selectedWorkcard!);
      setIsWorker(isUserWorker);
      setIsSupervisor(hasEmployeeLevelSupervisorOnAnyCrew(myProfileData));
    }
  }, [myProfileData]);

  useEffect(() => {
    refetch();
  }, [error]);

  useEffect(() => {
    if (data && data.riskAssessmentQuestions) {
      if (!riskAssessmentQuestion.current) {
        riskAssessmentQuestion.current = data.riskAssessmentQuestions[0];
      } else {
        riskAssessmentQuestion.current = data?.riskAssessmentQuestions[indexSlide.current];
      }

      data.riskAssessmentQuestions.map((question) => {
        if (
          question?.answerType === InspectionChecklistAnswerType.input &&
          userType === 'worker' &&
          question.workerAnswer?.answer
        ) {
          setValue(`input-${question.id}`, question.workerAnswer?.answer, { shouldValidate: true });
        }

        if (
          question?.answerType === InspectionChecklistAnswerType.input &&
          userType === 'supervisor' &&
          question.supervisorAnswer?.answer
        ) {
          setValue(`input-${question.id}`, question.supervisorAnswer?.answer, { shouldValidate: true });
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (dataPlan) {
      console.log('dataPLan', dataPlan);

      getQuestionPlan();
    }
  }, [dataPlan]);

  useEffect(() => {
    if (res) {
      if (isSupervisorApproval) {
        supervisorApprovalButton.current.approvalConfirmed();
      } else {
        workerApprovalButton.current.approvalConfirmed();
      }

      setTimeout(() => {
        refetchWorkcardStatus();
        onDismiss(inputRef.current?.value, 'confirm');
      }, 4000);
    }
  }, [res]);

  useEffect(() => {
    setTimeout(() => {
      if (deleteAllPlanification.current) {
        selectedPlans?.map(async (plan) => {
          try {
            await deleteWorkcardPlan({
              siteId: selectedSiteId,
              workcardId: +workcardId,
              taskId: +taskId,
              planId: plan.id,
              payload: {
                uniqueStamp: plan.uniqueStamp,
              },
            });
          } catch (error) {
            //
          }
        });

        deleteAllPlanification.current = false;
      }
    }, 100);

    if (responseUpdateTaskInspection && responseUpdateTaskInspection.isSuccess) {
      refetch();
      planRefetch();

      setAnswerSaved(true);

      setTimeout(() => {
        setAnswerSaved(false);
      }, 2000);
    }
  }, [responseUpdateTaskInspection]);

  useEffect(() => {
    if (errorSig) {
      if (isSupervisorApproval) {
        supervisorApprovalButton.current.closeModal();
      } else {
        workerApprovalButton.current.closeModal();
      }
    }
  }, [errorSig]);

  const getAnwsersCount = () => {
    let count = 0;

    data?.riskAssessmentQuestions.map((riskAssessmentQuestion) => {
      if (userType === 'worker') {
        count =
          riskAssessmentQuestion.workerAnswer &&
            (riskAssessmentQuestion.workerAnswer.yesOrNo !== null || riskAssessmentQuestion.workerAnswer.answer)
            ? ++count
            : count;
      } else {
        count =
          riskAssessmentQuestion.supervisorAnswer &&
            (riskAssessmentQuestion.supervisorAnswer.yesOrNo !== null || riskAssessmentQuestion.supervisorAnswer.answer)
            ? ++count
            : count;
      }
    });

    return count;
  };

  const getIndex = async (event: any) => {
    let index = 0;

    await event.target.getActiveIndex().then((value: any) => (index = value));
    {
      indexSlide.current = index;
      riskAssessmentQuestion.current = data?.riskAssessmentQuestions[index] || null;
      getQuestionPlan();
    }
  };

  return (
    <IonContent>
      {isSupervisor && (
        <div className="relative w-full z-10 mt-4">
          <WorkcardHeader></WorkcardHeader>
        </div>
      )}

      <div className="relative w-full z-10 mt-2">
        <h2 className="w-[80%] block m-auto left-o right-0 relative text-center rounded-lg pt-2 font-bold text-gray-100 text-[18px] mb-1.5">
          {selectedWorkcardTask?.name}
        </h2>
        <p className="m-auto text-center text-gray-500 leading-[12px] text-[12px] mt-0">
          <i className="fa-solid fa-location-dot mr-2"></i>
          <span>{selectedWorkcardTask?.location}</span>
        </p>
      </div>

      <div className="relative ion-content-scroll-host mt-3 pt-[20px] h-[calc(82%-50px)] md:h-[calc(82%)] bg-[#e2a6b0] border-t-[8px] border-workcard-inspection-primary">
        <button
          onClick={(e) => {
            e.preventDefault();
            onDismiss(inputRef.current?.value, 'confirm');
          }}
          className="h-[33px] w-[33px] text-sm font-medium text-white absolute right-[15px] top-[-20px] bg-workcard-inspection-title rounded-full border-[2px] border-solid border-workcard-inspection-tertiary"
        >
          <i className="fa-solid fa-xmark text-[17px] mt-[2px]"></i>
        </button>
        <div className="flex flex-col justify-between">
          {userType === 'worker' && !isWorker && (
            <div className="uppercase text-black font-bold text-[21px] text-center mb-[-10px]">worker's</div>
          )}
          <div className="uppercase text-black font-bold text-[21px] text-center">
            {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.INSPECTION_CHECKLIST')}
          </div>
          <div className="m-auto min-h-[330px]  lg:w-[500px] relative mx-10 mt-[20px] mb-5 px-4 py-2 sm:gap-4 sm:px-6 h-auto bg-dark-secondary rounded-2xl">
            <div className="absolute top-[-21px] text-center rounded-full bg-dark-secondary border-[3.5px] border-[#e2a6b0] w-[42px] h-[42px] left-0 right-0 mx-auto">
              <i className="fa-solid fa-question text-[#e2a6b0] text-[28px] mt-[5px]"></i>
            </div>
            <div
              onClick={() => {
                handleArrowClicked('prev');
              }}
              className="cursor-pointer m-auto z-10 absolute top-[150px] left-[-23px] bg-dark-secondary h-[68px] w-[35px] pt-[22px] pl-[11px] half-circle-left"
            >
              <i className="fa-solid fa-chevron-left text-[24px]"></i>
            </div>
            <div
              onClick={() => {
                handleArrowClicked('next');
              }}
              className="cursor-pointer m-auto z-10 absolute top-[150px] right-[-23px]  bg-dark-secondary h-[68px]  w-[35px] pt-[22px] pl-[7px] half-circle-right"
            >
              <i className="fa-solid fa-chevron-right text-[24px]"></i>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-7 mb-5 h-full">
              <IonSlides
                options={slideOpts}
                pager={false}
                onIonSlideDidChange={(event: any) => getIndex(event)}
                ref={slides}
              >
                {!data && (
                  <div className="mb-4 mx-4 block">
                    <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-12 pt-20 text-center hover:border-gray-400 ">
                      <i className="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
                      <p className="mt-6 block text-base font-medium text-gray-500">
                        {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.NO_CHECKLIST_AVAILABLE')}
                      </p>
                      <p className="mt-2 block text-sm font-medium text-gray-500"> &nbsp; </p>
                    </div>
                  </div>
                )}
                {data &&
                  data.riskAssessmentQuestions.map((item, index) => (
                    <IonSlide
                      key={`item-checklist-${index}`}
                      className="no-scrollbar flex items-center text-center !overflow-hidden"
                    >
                      <div className="flex flex-col justify-between items-center">
                        <div className="px-0 mb-0 dark:text-quartery-default leading-4 flex justify-center items-start">
                          <div className="flex flex-col  pb-6 pt-2">
                            <span className="font-extrabold text-[25px] text-[#e2a6b0] mr-2 mb-5">
                              {index + 1}/{data.riskAssessmentQuestions.length}
                            </span>
                            <span className="text-[20px] tracking-wide font-medium leading-[22px]">
                              {item.question}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`${userType === 'worker' && !isWorker && 'pointer-events-none'
                            } flex flex-col text-center items-center relative mt-auto w-full`}
                        >
                          {item.answerType === InspectionChecklistAnswerType.radio ? (
                            <ul className="flex justify-center">
                              <li>
                                <input
                                  {...register(`q-${item.id}`, { required: true })}
                                  aria-invalid={errors?.[`q-${item.id}`] ? 'true' : 'false'}
                                  type="radio"
                                  id={`yes-${index}`}
                                  name={`checklist-${index}`}
                                  value="yes"
                                  className="hidden peer"
                                  onChange={(ev) => {
                                    handleRadioChange(
                                      ev,
                                      item.id,
                                      userType === 'worker' ? item.workerAnswer?.id : item.supervisorAnswer?.id,
                                      true,
                                      index,
                                      userType === 'worker',
                                      userType === 'worker'
                                        ? item.workerAnswer?.uniqueStamp || performance.now().toString()
                                        : item.supervisorAnswer?.uniqueStamp || performance.now().toString()
                                    );
                                    setValue(`q-${item.id}`, ev.target.checked);
                                  }}
                                  checked={
                                    userType === 'worker'
                                      ? item.workerAnswer
                                        ? item.workerAnswer?.yesOrNo
                                        : false
                                      : item.supervisorAnswer
                                        ? item.supervisorAnswer?.yesOrNo
                                        : false
                                  }
                                />
                                <label
                                  htmlFor={`yes-${index}`}
                                  className={`${isUpdating && 'pointer-events-none'
                                    } cursor-pointer inline-flex justify-between items-center text-center text-white bg-black border-gray-500 rounded-2xl border cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-inspection-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-inspection-primary w-[105px] h-[75px] peer-checked:arrow-down-inspection relative mr-3`}
                                >
                                  <div className="w-full text-[23px] font-extrabold text-center">
                                    {t('FEATURES.WORKCARD.YES')}
                                  </div>
                                </label>
                              </li>
                              <li>
                                <input
                                  {...register(`q-${item.id}`, { required: true })}
                                  aria-invalid={errors?.[`q-${item.id}`] ? 'true' : 'false'}
                                  type="radio"
                                  id={`no-${index}`}
                                  name={`checklist-${index}`}
                                  value="no"
                                  className="hidden peer"
                                  onChange={(ev) => {
                                    handleRadioChange(
                                      ev,
                                      item.id,
                                      userType === 'worker' ? item.workerAnswer?.id : item.supervisorAnswer?.id,
                                      false,
                                      index,
                                      userType === 'worker',
                                      userType === 'worker'
                                        ? item.workerAnswer?.uniqueStamp || performance.now().toString()
                                        : item.supervisorAnswer?.uniqueStamp || performance.now().toString()
                                    );
                                    setValue(`q-${item.id}`, ev.target.checked);
                                  }}
                                  checked={
                                    userType === 'worker'
                                      ? item.workerAnswer === null
                                        ? false
                                        : !item.workerAnswer.yesOrNo
                                      : item.supervisorAnswer === null
                                        ? false
                                        : !item.supervisorAnswer.yesOrNo
                                  }
                                // defaultChecked
                                />
                                <label
                                  htmlFor={`no-${index}`}
                                  className={`${isUpdating && 'pointer-events-none'
                                    } cursor-pointer inline-flex justify-between items-center px-5  text-white bg-black border-gray-500 rounded-2xl border  cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-inspection-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-inspection-primary w-[105px] h-[75px] peer-checked:arrow-down-inspection relative ml-3`}
                                >
                                  <div className="w-full text-[23px] font-extrabold text-center">
                                    {t('FEATURES.WORKCARD.NO')}
                                  </div>
                                </label>
                              </li>
                            </ul>
                          ) : (
                            <>
                              <div className="relative flex flex-grow items-stretch focus-within:z-10 w-full px-4 mt-5">
                                <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-1"></div>
                                <input
                                  {...register(`input-${item.id}`, { required: true })}
                                  type="text"
                                  placeholder={t('FEATURES.WORKCARD.PLEASE_ANSWER') + ''}
                                  onBlur={(ev) => {
                                    handleInputOnBlur(
                                      ev,
                                      item.id,
                                      userType === 'worker' ? item.workerAnswer?.id : item.supervisorAnswer?.id,
                                      userType === 'worker'
                                        ? item.workerAnswer?.uniqueStamp || performance.now().toString()
                                        : item.supervisorAnswer?.uniqueStamp || performance.now().toString()
                                    );
                                  }}
                                  className="focus:outline-none text-white bg-black rounded-xl border border-gray-500 comment block w-full text-[13px] h-[50px]"
                                />
                              </div>
                              {/* <SpeechToText
                            key="currentWorkPlaceSituation"
                            questionId="currentWorkPlaceSituation"
                            onListeningEnd={handleListeningEnd}
                            onTranscriptEnd={handleTranscriptEnd}
                          ></SpeechToText> */}
                            </>
                          )}
                          {/* {errors[`q-${item.id}`]?.type === 'required' && (
                        <p
                          className="absolute mt-[25px] sm:hidden block col-span-2 sm:mt-[5px] ml-[-5px] sm:ml-[22px] text-[16px] text-[#f05454]"
                          role="alert"
                        >
                          Field is required
                        </p>
                      )} */}

                          {userType !== 'worker' && (
                            <div className="flex mt-2 sm:w-[372px] mx-auto col-span-2">
                              <div className="pl-0  py-[3px] mt-[4px] mb-2  mx-auto ">
                                <span className="dark:text-gray-500 text-sm relative top-[-2px]">
                                  {selectedWorkcard?.employees &&
                                    selectedWorkcard.employees.map((user: any, index: number) => (
                                      <span className="truncate" key={`employee-${index}`}>
                                        {selectedWorkcard.employees.length > 1
                                          ? user.name.replace(/ .*/, '')
                                          : user.name}
                                        {selectedWorkcard.employees.length > 1 && index === 0 && ', '}
                                      </span>
                                    ))}{' '}
                                  {t('LABELS.SAID')}:
                                </span>
                                <div className="text-black dark:text-white font-bold text-base rounded relative top-[-2px] px-1 ml-2">
                                  {item.answerType === InspectionChecklistAnswerType.radio
                                    ? userType === 'worker' &&
                                    (item.supervisorAnswer ? (
                                      item.supervisorAnswer?.yesOrNo ? (
                                        t('FEATURES.WORKCARD.YES')
                                      ) : (
                                        t('FEATURES.WORKCARD.NO')
                                      )
                                    ) : (
                                      <i className="fa-solid fa-question"></i>
                                    ))
                                    : userType === 'worker' &&
                                    (item.supervisorAnswer?.answer ? (
                                      <span>{item.supervisorAnswer?.answer}</span>
                                    ) : (
                                      <i className="fa-solid fa-question"></i>
                                    ))}

                                  {item.answerType === InspectionChecklistAnswerType.radio
                                    ? userType === 'supervisor' &&
                                    (item.workerAnswer ? (
                                      item.workerAnswer?.yesOrNo ? (
                                        t('FEATURES.WORKCARD.YES')
                                      ) : (
                                        t('FEATURES.WORKCARD.NO')
                                      )
                                    ) : (
                                      <i className="fa-solid fa-question"></i>
                                    ))
                                    : userType === 'supervisor' &&
                                    (item.workerAnswer?.answer ? (
                                      <span>{item.workerAnswer?.answer}</span>
                                    ) : (
                                      <i className="fa-solid fa-question"></i>
                                    ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <div
                            onClick={handleToggleDetailMode}
                            className={`${userType === 'worker' && 'mt-4'
                              } cursor-pointer text-[12px] uppercase bg-dark-tertiary w-full py-1 mx-[-20px] rounded-md mb-4`}
                          >
                            {userType !== 'worker'
                              ? t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.ALL_WORKER_ANSWER')
                              : t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.SEE_ALL_MY_ANSWER')}

                            <i className="fa-solid fa-circle-arrow-right ml-2"></i>
                          </div>

                          {/* START PLAN QUESTION */}

                          {selectedPlans && selectedPlans.length > 0 && (
                            <div className="">
                              <div className="mt-4 flow-root relative mx-2">
                                {displayPlanIndicator(item) && (
                                  <div className="mb-2.5 text-left ml-1 flex justify-between">
                                    <span
                                      id="badge-dismiss-yellow"
                                      className="inline-flex items-center px-0 mr-2 text-[11px] uppercase font-bold text-workcard-inspection-primary"
                                    >
                                      <i className="fa-solid fa-triangle-exclamation mr-1.5 text-workcard-inspection-primary text-[22px]"></i>
                                      <span className="mt-1">
                                        {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.PLANNING_REQUIRED')}
                                      </span>
                                    </span>
                                  </div>
                                )}

                                {selectedPlans &&
                                  selectedPlans.map((plan, index) => (
                                    <dl
                                      key={`plan-${index}`}
                                      className="font-normal  dark:bg-white pb-2 pt-[1px] mt-2 rounded-md relative w-[235px]"
                                    >
                                      {canDeletePlan() && (
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            presentAlert({
                                              header: t(
                                                'FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.DELETE_PLAN'
                                              ),
                                              buttons: [
                                                {
                                                  text: 'Cancel',
                                                  role: 'cancel',
                                                  handler: () => {
                                                    // setHandlerMessage('Alert canceled');
                                                  },
                                                },
                                                {
                                                  text: 'Submit',
                                                  role: 'confirm',
                                                  handler: () => {
                                                    e.stopPropagation();
                                                    handleDeletePlan(e, plan);
                                                  },
                                                },
                                              ],
                                            });
                                          }}
                                          className="cursor-pointer dark:text-gray-900 text-base absolute right-[-8px] top-[24px] rounded-full bg-workcard-inspection-primary h-[28px] w-[28px] border-[2px] border-white text-center"
                                        >
                                          <i className="fa-solid fa-trash text-white text-[14px]"></i>
                                        </div>
                                      )}

                                      <div
                                        onClick={(e) => handleAlertTypeOfRisks(e, plan)}
                                        className="flex flex-col mt-2 pb-2 border-b border-gray-600"
                                      >
                                        <dt className="text-gray-700 dark:text-black pl-2 text-[13px] font-semibold text-left">
                                          {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.TYPE_OF_RISKS')}:{' '}
                                        </dt>
                                        <dd className="text-gray-700 text-[14px] w-[95%] dark:text-workcard-inspection-primary font-bold ml-2 text-left">
                                          {plan.riskType?.title || (
                                            <span className="bg-white border-[1.5px] text-[13px] font-bold border-workcard-inspection-primary text-black w-full py-0.5 mt-0.5 text-center rounded inline-block">
                                              {t('LABELS.SELECT')}
                                              <i className="ml-1 fa-solid fa-circle-arrow-right text-workcard-inspection-primary"></i>
                                            </span>
                                          )}
                                          {plan.riskType?.requiresAdditionalDetails && <span> - {plan.otherRiskType}</span>}
                                        </dd>
                                      </div>

                                      <div
                                        onClick={(e) => handleAlertControlMeasures(e, plan)}
                                        className="flex flex-col mt-2 pb-0 "
                                      >
                                        <dt className="text-gray-700 dark:text-black pl-2 text-[13px] font-semibold text-left">
                                          {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.CONTROL_MEASURE')}:
                                        </dt>
                                        <dd className="text-gray-700 text-[14px] w-[95%] dark:text-workcard-inspection-primary font-bold ml-2  text-left">
                                          {(plan.controlMeasure && plan.controlMeasure.title) || (
                                            <span className="bg-white border-[1.5px] text-[13px] border-workcard-inspection-primary text-black w-full py-0.5 text-center rounded inline-block mt-0.5">
                                              {t('LABELS.SELECT')}
                                              <i className="ml-1 fa-solid fa-circle-arrow-right text-workcard-inspection-primary"></i>
                                            </span>
                                          )}
                                          {plan.controlMeasure?.requiresAdditionalDetails && (
                                            <span> - {plan.otherControlMeasure}</span>
                                          )}
                                        </dd>
                                      </div>
                                    </dl>
                                  ))}

                                {displayPlanIndicator(item) && canDuplicatePlan() && (
                                  <span
                                    onClick={(e) => handleCreateOrDuplicatePlan(e)}
                                    id="badge-dismiss-yellow"
                                    className="cursor-pointer inline-flex items-end px-1.5 py-1.5 text-sm font-medium text-white bg-white rounded-md float-right mt-2"
                                  >
                                    <i className="fa-solid fa-plus text-workcard-inspection-primary "></i>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}

                          {/* END PLAN QUESTION */}
                        </div>
                      </div>
                    </IonSlide>
                  ))}

                <IonSlide className="no-scrollbar flex items-center text-center !overflow-hidden">
                  <div className="flex flex-col justify-between items-center">
                    <div className=" px-5 pt-0 pb-8 ">
                      <span className="font-extrabold text-[43px] mr-[2px]">{getAnwsersCount()}</span>
                      <span className="font-extrabold text-[43px]">
                        /{data && data.riskAssessmentQuestions && data.riskAssessmentQuestions.length}
                      </span>
                    </div>

                    <div className="px-5 mb-0 dark:text-quartery-default leading-4 flex justify-center items-start">
                      <div>
                        {userType === 'worker' && (
                          <WorkcardApprovalButton
                            colorButton="inspection"
                            userType="Worker"
                            isApprovalButton={true}
                            isInspectionButton={true}
                            isDisabled={getAnwsersCount() !== data?.riskAssessmentQuestions?.length}
                            ref={workerApprovalButton}
                            handleSubmit={() => handleSubmitButton()}
                            handleApproval={
                              getAnwsersCount() === data?.riskAssessmentQuestions?.length && handleApproval
                            }
                            approvalStatus={data && data.workerSignature}
                          />
                        )}

                        {userType !== 'worker' && (
                          <WorkcardApprovalButton
                            colorButton="inspection"
                            userType="Supervisor"
                            isApprovalButton={true}
                            isDisabled={getAnwsersCount() !== data?.riskAssessmentQuestions?.length}
                            ref={supervisorApprovalButton}
                            handleSubmit={() => handleSubmitButton()}
                            handleApproval={
                              getAnwsersCount() === data?.riskAssessmentQuestions?.length && handleApproval
                            }
                            approvalStatus={data && data.supervisorSignature}
                          />
                        )}

                        {getAnwsersCount() !== data?.riskAssessmentQuestions?.length && (
                          <div className=" w-full m-auto flex justify-center mt-6">
                            <div className="w-[250px] bg-black text-white border-[2px] rounded-md border-red-600 text-left text-[12px] font-semibold leading-[14px] py-1.5 pl-[55px] pr-4 mt-2.5 relative">
                              <i className="fa-solid fa-exclamation-triangle absolute text-[35px] left-2 top-[-10px] text-red-600"></i>
                              {t(
                                'FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.PLEASE_ANSWER_ALL_QUESTION_BEFORE_SUBMITTING'
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </IonSlide>
              </IonSlides>
            </form>
          </div>
        </div>

        <AnimatePresence>
          {isDetailMode && (
            <motion.div
              key="detail"
              initial={{ height: 0 }}
              animate={{ height: 'calc(100vh - 30px)' }}
              transition={{ duration: 0.3 }}
              exit={{ height: 0 }}
              style={{
                position: 'absolute',
                top: -35,
                width: '102%',
                zIndex: 10,
                overflow: 'scroll',
              }}
            >
              <WorkcardInspectionChecklistDetail
                userType={userType}
                data={data}
                onSelectItem={handleSelecItem}
                onCloseDetail={handleClodeDetail}
                taskId={+taskId}
                workcardId={+workcardId}
                canCopyWorkerAnswers={data?.workerSignature !== null && data?.supervisorSignature === null}
              ></WorkcardInspectionChecklistDetail>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </IonContent>
  );
};
