import { useIonAlert, useIonRouter } from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { hasEmployeeLevelSupervisorOnAnyCrew } from '../../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import {
  useAssignSupervisorToWorkcardMutation,
  useGetWorkcardGreetingsQuery,
  useGetWorkcardStatusByIdQuery,
  useUnassignSupervisorToWorkcardMutation,
} from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';
import { getDate, getTime } from '../../../../utils';

export const WorkcardHeader: React.FC<unknown> = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();

  const selectedMenuItem = useSelector((state: RootState) => state.sidebar.value);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const id = window.location.pathname.split('/')[2];

  const [assignSupervisor, { isLoading: isLoadingAssignSup, isSuccess: isSuccessAssignSup }] =
    useAssignSupervisorToWorkcardMutation();
  const [unassignSupervisor, { isLoading: isLoadingUnAssignSup, isSuccess: isSuccessUnAssignSup }] =
    useUnassignSupervisorToWorkcardMutation();

  const { data: userProfile } = useGetMyProfileQuery();

  const { data, error, isLoading, refetch } = useGetWorkcardStatusByIdQuery(
    {
      siteId: selectedSiteId!,
      workcardId: +id,
    },
    { skip: isNaN(+id) }
  );

  const isSupervisor: boolean = hasEmployeeLevelSupervisorOnAnyCrew(userProfile);

  const handleShowLogs = () => {
    router.push(`/auditlog/${+id}`, 'forward');
  };

  const handleAssignSupervisor = () => {
    assignSupervisor({
      siteId: selectedSiteId!,
      workcardId: +id,
      payload: { userObjectId: userProfile?.azureObjectId },
    });
  };

  const handleUnassignSupervisor = () => {
    unassignSupervisor({
      siteId: selectedSiteId!,
      workcardId: +id,
      userobjectId: userProfile?.azureObjectId,
    });
  };

  const handleConfirmUnassignSupervisor = () => {
    presentAlert({
      header: t('FEATURES.WORKCARD.WORKCARD_GREETING.CONFIRM_UNASSIGN_SUPERVISOR'),
      buttons: [
        {
          text: t('LABELS.CANCEL'),
          role: 'cancel',
          handler: () => {
            //
          },
        },
        {
          text: t('LABELS.SUBMIT'),
          role: 'confirm',
          handler: () => {
            handleUnassignSupervisor();
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (isSuccessAssignSup || isSuccessUnAssignSup) {
      refetch();
    }
  }, [isSuccessAssignSup, isSuccessUnAssignSup]);

  return (
    <>
      <h2 className="w-[80%] border-[2px] border-dark-secondary block m-auto left-o right-0 relative text-center rounded-lg py-2 font-bold text-[#b3b3b6] text-[18px] mb-2">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mx-2">
          <div className="hidden lg:block"></div>
          <div className="flex flex-col justify-center">{data?.employees &&
            data.employees.map((user: any, index: number) => (
              <span className="truncate" key={`employee-${index}`}>
                {data.employees.length > 1 ? user.name.replace(/ .*/, '') : user.name}
                {data.employees.length > 1 && index === 0 && ', '}
              </span>
            ))}
            <div className="text-[10px] font-medium mt-0.5">
              <span className="mr-2">{t('LABELS.LAST_UPDATE')}</span>
              {data?.employees && data?.employees.length > 0 && data?.employees[0].lastSyncDate
                ? getDate(data?.employees[0].lastSyncDate) + ' ' + getTime(data?.employees[0].lastSyncDate)
                : 'N/A'}
            </div>
          </div>
          {isSupervisor && isOnline && selectedMenuItem === 'Greeting' && (
            <div className='flex flex-row-reverse gap-1 hidden lg:flex'>
              <div className="flex flex-col justify-center hidden lg:flex hover:cursor-pointer border-[2px] border-dark-secondary px-3 py-2 rounded-md text-[#b3b3b6]" onClick={handleShowLogs}>
                <i className="fa-solid fa-file-lines text-workcard-greeting-primary"></i>
              </div>
              {!data?.assignedSupervisor && (
                <div
                  onClick={handleAssignSupervisor}
                  className="flex flex-col justify-center hover:cursor-pointer text-[13px] text-[b3b3b6] px-3 py-2 rounded-md border-dark-secondary border-[2px]"
                >
                  <div className="mt-[1px]">
                    {t('FEATURES.WORKCARD.WORKCARD_GREETING.ASSIGN_ME_AS_SUPERVISOR')}
                    <i className="fa-solid fa-link ml-4 text-workcard-greeting-primary"></i>
                  </div>
                </div>
              )}

              {data?.assignedSupervisor && (
                <div
                  onClick={handleConfirmUnassignSupervisor}
                  className="hover:cursor-pointer text-[13px] border-[2px] border-dark-secondary px-3 py-2 rounded-md text-[#b3b3b6]"
                >
                  {t('FEATURES.WORKCARD.WORKCARD_GREETING.SUPERVISOR')}: {data?.assignedSupervisor?.name}
                  <i className="fa-solid fa-link-slash ml-4 text-workcard-greeting-primary"></i>
                </div>
              )}

            </div>
          )}
        </div>
      </h2>

      {isSupervisor && isOnline && selectedMenuItem === 'Greeting' && (
        <div className="flex flex-row justify-stretch gap-2 w-[80%] mx-auto">
          {!data?.assignedSupervisor && (
            <div
              onClick={handleAssignSupervisor}
              className="grow hover:cursor-pointer lg:hidden text-base text-center font-semibold border-[2px] border-dark-secondary px-3 py-2 rounded-md text-[#b3b3b6]"
            >
              {t('FEATURES.WORKCARD.WORKCARD_GREETING.ASSIGN_ME_AS_SUPERVISOR')}
              <i className="fa-solid fa-link ml-4 text-workcard-greeting-primary"></i>
            </div>
          )}

          {data?.assignedSupervisor && selectedMenuItem === 'Greeting' && (
            <div
              onClick={handleConfirmUnassignSupervisor}
              className="grow hover:cursor-pointer lg:hidden text-base text-center font-semibold border-[2px] border-dark-secondary px-3 py-2 rounded-md text-[#b3b3b6]"
            >
              {t('FEATURES.WORKCARD.WORKCARD_GREETING.SUPERVISOR')}: {data?.assignedSupervisor?.name}
              <i className="fa-solid fa-link-slash ml-4 text-workcard-greeting-primary"></i>
            </div>
          )}
          <div className="flex flex-col justify-center lg:hidden hover:cursor-pointer border-[2px] border-dark-secondary px-3 rounded-md text-[#b3b3b6]" onClick={handleShowLogs}>
            <i className="fa-solid fa-file-lines text-workcard-greeting-primary"></i>
          </div>
        </div>
      )}
    </>
  );
};
