import { Combobox, Listbox, Switch, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { IonContent, IonPage } from "@ionic/react";
import { t } from "i18next";
import { useState, Fragment, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { useDebounce } from "use-debounce";

import { Roles } from "../../data/roles";
import { classNames } from "../../services/common/workcard.utils";
import { useGetEmployeeSearchQuery, useUpdateEmployeeMutation } from "../../services/data/profile.service";

import './ChangeUserRoleModal.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ChangeUserRoleModal = ({
    onDismiss,
}: {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
    const [hasAdminRights, setHasAdminRights] = useState(false);
    const [selectedSearchText, setSelectedSearchText] = useState('');
    const [debouncedValue] = useDebounce(selectedSearchText, 750);
    const [skip, setSkip] = useState(true);
    const { data: employees } = useGetEmployeeSearchQuery({ searchText: selectedSearchText }, { skip, refetchOnMountOrArgChange: true });
    const [changeUserRole, resultChangeUserRole] = useUpdateEmployeeMutation();

    const {
        watch,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        trigger,
        setValue,
        getValues,
        control,
        getFieldState,
        register
    } = useForm();

    const onSubmit = async (data: any) => {
        trigger();
        const employee = getValues('employee');
        const role = getValues('role');
        const adminRightsGranted = getValues('hasAdminRights')

        if (employee && role) {
            const payload = {
                role: role.id,
                isAdministrator: adminRightsGranted
            };

            await changeUserRole({ id: employee.id, payload });

            onDismiss(employee.id, 'confirm');
        }
    };

    const updateEmployee = (employee: any) => {
        setSelectedEmployee(employee);
        setValue('employee', employee);
    };

    const searchEmployee = (search: string) => {
        if (search.length > 2) {
            setSelectedSearchText(search);
        } else {
            setSkip(true);
            setSelectedEmployee(null);
            setSelectedSearchText('');
            setValue('employee', null);
        }
    };

    useEffect(() => {
        if (debouncedValue && skip) {
            setSkip(false);
        }
    }, [debouncedValue]);

    useEffect(() => {
        watch((value, { name, type }) => {
            trigger(name);
        });
    }, [watch]);

    useEffect(() => {
        if (selectedEmployee) {
            const role = Roles.find(r => r.id === selectedEmployee.role);
            setSelectedRole(role);
            setValue('role', role);
            setHasAdminRights(selectedEmployee.isAdministrator);
        } else {
            setSelectedRole(null);
            setHasAdminRights(false);
        }
    }, [selectedEmployee]);

    useEffect(() => {
        if (resultChangeUserRole?.isSuccess) {
            toast.success(t('FEATURES.SETTINGS.EMPLOYEE_PERMISSIONS_CHANGED_SUCCESSFULLY'), { duration: 2000, position: 'top-center' });
        }
    }, [resultChangeUserRole]);
    
    return (
        <IonPage>
            <IonContent className="ion-padding redbox-modal">
                <div className="flex justify-center mb-6">
                    <span className="dark:text-quartery-default"> {t('FEATURES.SETTINGS.ADJUST_USER_PERMISSION')}</span>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative pt-7 pb-2 md:mb-5">
                        {t('FEATURES.SETTINGS.PERMISSIONS.SEARCH_EMPLOYEE')}
                    </dt>
                    <dd className={` relative col-span-6 text-lg text-gray-900 sm:mt-[-18px] mb-3 mt-1.5 `}>
                        <div className="mt-0  rounded-md shadow-sm">
                            <Controller
                                control={control}
                                name="employee"
                                rules={{ required: true }}
                                render={({
                                    field: { onChange, name, ref }
                                }) => (
                                    <Combobox name={name} value={selectedEmployee} onChange={updateEmployee}>
                                        <Combobox.Input
                                            autoComplete="off"
                                            onChange={(event) => searchEmployee(event.target.value)}
                                            displayValue={(employee: any) => {
                                                return employee?.name;
                                            }}
                                            className="focus:outline-none bg-black w-full h-[50px] rounded-xl text-white border border-gray-700 text-[13px]"
                                        />
                                        {employees && employees.length > 0 && (
                                            <Combobox.Options className="bg-dark-tertiary border border-gray-500 rounded-md max-h-[200px] absolute w-full z-10 overflow-y-scroll text-white p-2 text-[18px] mt-2">
                                                {employees &&
                                                    employees?.map((employee) => (
                                                        <Combobox.Option key={employee.id} value={employee} className="hover:bg-dark-primary  py-2">
                                                            {employee.name}
                                                        </Combobox.Option>
                                                    ))}
                                            </Combobox.Options>
                                        )}
                                        {employees && employees.length === 0 && (
                                            <Combobox.Options className="bg-dark-tertiary border border-gray-500 rounded-md max-h-[200px] absolute w-full z-10 overflow-y-scroll text-white p-2 text-[18px] mt-2">
                                                <Combobox.Option key="location-empty" value="" className="hover:bg-dark-primary  py-2">
                                                    <i className="fa-solid fa-triangle-exclamation mr-2 ml-2"></i>
                                                    {t('LABELS.CANCEL')}
                                                </Combobox.Option>
                                            </Combobox.Options>
                                        )}
                                    </Combobox>
                                )}
                            />

                            <div className="h-[10px]">
                                {errors[`employee`]?.type === 'required' && (
                                    <div className="absolute bottom-[-18px] text-[13px] text-red-600" role="alert">
                                        <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                        <span className="font-semibold ">{t('FEATURES.SETTINGS.FIELD_REQUIRED')}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </dd>

                    <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative pt-7 pb-2 md:mb-5">
                        {t('FEATURES.SETTINGS.PERMISSIONS.SELECT_ROLE')}
                    </dt>

                    <dd className={` relative col-span-6 text-lg text-gray-900 sm:mt-[-18px] mb-3 mt-1.5 `}>
                        <div className="mt-0 flex rounded-md shadow-sm">
                            <Controller
                                control={control}
                                name="role"
                                rules={{ required: true }}
                                render={({
                                    field: { onChange }
                                }) => (
                                    <Listbox value={selectedRole} onChange={(e) => { onChange(e); setSelectedRole(e) }}>
                                        {({ open }) => (
                                            <>

                                                <div className="relative mt-2 w-full">
                                                    <Listbox.Button className="relative w-full rounded-md bg-black h-[48px] pl-3 pr-10 text-left  shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none sm:text-sm sm:leading-6 text-white cursor-pointer">
                                                        <div className="block truncate">{t(selectedRole?.translationKey)}</div>
                                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </Listbox.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-gray-400  focus:outline-none sm:text-sm text-white">
                                                            {Roles?.map((role: any) => (
                                                                <Listbox.Option
                                                                    key={role.id}
                                                                    className={({ active }) =>
                                                                        classNames(
                                                                            active ? 'bg-gray-600 text-white' : 'text-white',
                                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                        )
                                                                    }
                                                                    value={role}
                                                                >
                                                                    {({ selected, active }) => (
                                                                        <>
                                                                            <span
                                                                                className={classNames(
                                                                                    selected ? 'font-semibold' : 'font-normal',
                                                                                    'block truncate'
                                                                                )}
                                                                            >
                                                                                {t(role.translationKey)}
                                                                            </span>

                                                                            {selected ? (
                                                                                <span
                                                                                    className={classNames(
                                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                    )}
                                                                                >
                                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            ))}
                                                        </Listbox.Options>
                                                    </Transition>
                                                </div>
                                            </>
                                        )}
                                    </Listbox>
                                )}
                            />
                        </div>
                    </dd>

                    <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative pt-7 pb-2 md:mb-5">
                        {t('FEATURES.SETTINGS.PERMISSIONS.ADDITIONAL_PERMISSIONS')}
                    </dt>
                    <Controller
                        control={control}
                        name="hasAdminRights"
                        render={({
                            field: { onChange }
                        }) => (
                            <Switch
                                checked={hasAdminRights}
                                onChange={(e: any) => { onChange(e); setHasAdminRights(e) }}
                                className={classNames(
                                    'col-span-5 relative flex h-[30px] w-[52px] flex-shrink-0 cursor-pointer rounded-fullblock bg-black rounded-2xl pt-[2px] px-[3px] mt-2 mb-1.5 border-2 border-solid border-dark-secondary'
                                )}
                            >
                                <span
                                    className={classNames(
                                        hasAdminRights ? 'translate-x-5' : 'translate-x-0',
                                        'relative inline-block h-[23px] w-[23px] transform rounded-full shadow ring-0 transition duration-200 ease-in-out bg-gradient-to-r from-white to-border-dark-tertiary'
                                    )}
                                ></span>

                                <span className="absolute text-bold top-[6px] left-[60px] text-[11px] text-black dark:text-quartery-default leading-[11px] whitespace-nowrap">
                                    {t('FEATURES.SETTINGS.PERMISSIONS.GRANT_ADMINISTRATIVE_PERMISSIONS')}
                                </span>

                            </Switch>
                        )}
                    />
                    <footer className="absolute bottom-6 w-full left-1 px-4">
                        <div className="flex justify-between">
                            <button
                                onClick={() => onDismiss(null, 'cancel')}
                                type="button"
                                className="inline-flex items-center justify-center rounded-xl px-4 py-1 w-[150px] text-hasrd-redbox-primary shadow-sm  sm:w-auto mr-3 mt-[-6px] border-[2px] border-workcard-redbox-primary border-solid text-[15px] uppercase font-bold"
                            >
                                {t('LABELS.CANCEL')}
                            </button>
                            <button
                                type="submit"
                                disabled={!isValid || !isDirty}
                                className="inline-flex items-center justify-center rounded-xl border border-transparent px-4 py-1 w-[150px]  text-white shadow-sm  focus:outline-none  sm:w-auto mr-3 mt-[-6px] bg-gradient-to-r from-workcard-redbox-primary to-workcard-redbox-secondary text-[15px] uppercase font-bold"
                            >
                                {t('LABELS.SAVE')}
                                <i className="fa-solid fa-circle-right ml-1.5 mt-[-1px] "></i>
                            </button>
                        </div>
                    </footer>
                </form>
            </IonContent>
        </IonPage>
    )
}